import React from 'react'
import { Home } from './Components'

const Index = () => {

	return(
		<>
			<Home />
		</>
	)
	
}

export default Index
