import Footer from '../../../components/Footer'
import Nav from '../../../components/Nav'
import Pago from './Components/Pago'

const Index = () => {
	return(
		<>
			<Nav />
			<Pago />
			<Footer />
		</>
	)
	
}

export default Index
