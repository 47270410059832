import React, { useEffect, useState } from 'react';
import { axios } from '../../../infrastructure/axios';
import M from 'materialize-css';
import { useNavigate } from 'react-router-dom';

const StepTwo = () => {

	let navigate = useNavigate();
	const [img, setImg] = useState({imgs:""});
	const [loader, setLoader] = useState(false)

	useEffect(()=>{
		axios.get('/api/reseller/configwebsitesliders').then((response)=>{
		   if(response.data.length > 0) setImg(response.data[0])
		}).catch(e=>alert(e))

	},[])

	const _back = (e) => {
		e.preventDefault();
		axios.put('/api/reseller/savestatus',{config_store:{"wizard_config":{"installed":0,"status":"step_one"}}}).then((res)=>{
			navigate("/admin")
			window.location.reload()
		}).catch(e=>console.log("error",e))
	}

	const _pushImg = () =>{

		if(!img.imgs && !img.img) return alert("No olvides subir una imagen antes de continuar.")
		const formData = new FormData()
		formData.append('files', img.imgs)
		formData.append('wizard_install_mode', true)
		
		setLoader(true)
		axios.post('/api/seller/setSliderImg',formData).then((r) => {
			setImg(r.data)
			M.toast({html: 'Imagen Guardada!'})

			axios.put('/api/reseller/savestatus',{config_store:{"wizard_config":{"installed":0,"status":"step_five"}}}).then((res)=>{   
				navigate("/admin")
				window.location.reload()
				setLoader(false)
			}).catch(e=>console.log("error",e))
		   
		})
		.catch(r=>alert(r))
	} 

	return (
		<div>
			<div className='padding-20' style={{position:'relative'}}>
				<div className="row">
					<div className='col s12'>
						<h5>Sube una imagen para tu sitio la cual se utilizara como imagen principal/banner en la sección principal </h5>
						<h6>Todo sus cambios o ajustes se verán reflejados al presionar el botón de SIGUIENTE en la parte inferior</h6> 
						<p> <span>Nota: </span>
							Un banner es una imagen informativa/publicitaria que se le puede agregar texto y un botón que lleva al cliente a un link específico dentro o fuera de tu e-commerce , se localiza en la página principal de tu e-commerce hasta arriba. Se requiere configurar al menos uno.
						</p>
						<p style={{color:'red'}}>Tamaño recomendado 1200x600 / 1024x728 (o imagenes con dimensiones panorámicas)</p>
						<label style={{cursor:'pointer', fontSize:'12px', fontWeight:'900'}}>
							<div style={{width:'100%'}}>
								<img alt='img' src={img.imgs?URL.createObjectURL(img.imgs):img.img?img.img:'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/subirImg.jpg'}
								style={{margin:'0 auto', maxHeight:'100%', maxWidth:'100%', objectFit:'contain',textAlign:'left',}} />
							</div>
							<input  onChange={(e)=>setImg({...img,imgs:e.target.files[0]})}  type="file" style={{display:'none', height:'500px', width:'800px',}}/>
						</label>
					</div>
				</div>

				<div className="row" style={{padding:'20px',}}></div>

				{loader===true?
				<div className="progress">
					<div className="indeterminate"></div>
				</div>:
				<button className='btn' onClick={(e)=>_pushImg()}  style={{backgroundColor:'#4966B1',color:'white',position:'absolute',right:20,bottom:20,height:50,width:150}}>Siguiente</button>
				}
			
				<div className='' style={{position:'absolute',left:20,bottom:20,}}>
					<button className='btn'  onClick={(e)=>_back(e)}
						style={{backgroundColor:'#4966B1',color:'white',height:50,width:150}}>
						Anterior
					</button>
				</div>
			</div>
		</div>
	);
};

export default StepTwo;
