import Nav from '../../components/Nav'
import Footer2 from '../../components/Footer/Footer2'
import Aviso from './Components/Aviso'

const Index = () => {

	return(
		<>
			<Nav />
			<Aviso />
			<Footer2 />
		</>
	)
	
}

export default Index
