import React from 'react';
import './index.css';

const Proximamente = () => {
	
	return (	
		<div className='bg-full' style={{ backgroundImage:'url(https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/Proximamente.jpg)' }}>
			<div className='row'>
				<div className='col s12 m4 bg-full-fondo' style={{paddingTop:'40px'}}>
				{/*
					<div className='row center-y-x' style={{textAling:'center', margin:'0 auto'}}> 
						<div className="cont-logo-proximamente center-y-x"> 
							<img className='logo-img' style={{verticalAlign:'middle'}} alt=''/> 
						</div>
					</div>
					*/}
					<div className='row m-p-0 center-y-x' style={{textAling:'center'}}>
						<div className='m-p-0'>
							<p className="text-construccion" style={{marginBottom:0}}> 
								En <br></br> Construcción 
							</p>
							<p className="text-construccion-min m-p-0"> 
								Vuelve más tarde para disfrutar de todo lo que tenemos para ti
							</p>
						</div>
					</div>
					{
						/*<div className='row center-y-x' id="container"> 
						<div className="col m10" id="progressbar"> <span id="loading"></span></div>
						<div className='center-y-x width360'>
							<div className='width360 cont-porcent'>
								<div className="col m1 m-p-0" style={{fontZise:'14px', fontWeight:'500', zIndex:9, }}> 0% </div>
								<div className="col m1 m-p-0" style={{fontSize:'14px', fontWeight:'500', zIndex:9, float:'right'}}> 100% </div>
							</div>
						</div>
					</div>*/}

				</div>
				<div className='col s12 m4 movil-no'> </div>
				<div className='col s12 m4 movil-no'> </div>
			</div>
		</div>
	);
};

export default Proximamente;
