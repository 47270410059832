import Nav from '../../components/Nav'
import Registro from './Components/Registro'
import Footer2 from '../../components/Footer/Footer2'
const Index = () => {

	return (
		<>
			<Nav />
			<Registro />
			<Footer2 />
		</>
	)
}
export default Index
 
