import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import './Admin/styles/App.scss';
import 'materialize-css';
import 'materialize-css/sass/materialize.scss';
import { Login, Dashboard, OrderStatus, PerfilEmpresa, Mensajes, Carrusel, Cotizaciones, Cotizacion, Visitas, Catalogo, NewProduct, EditProduct, WebConfigs, AvisoPrivacidad, Faq, Glosario, PoliticaCompra, PagosEnvios, Pickups } from './Admin/components'
import { DropShipingPageIndex, CatalogoGlobalPageIndex, VentasPageIndex, EntregaPageIndex, BalanceSummaryPageIndex, PaymentHistoryPageIndex, MiBanca, Domains, SuscripcionPageIndex, PlansPageIndex, PremiumPageIndex } from './Admin/pages'
import { UserProvider } from './Admin/contexts/UserContext';
import { DataProvider } from './Shop/contexts/DataContext';
import Home from './Shop/components/Home'
import SearchPage from './Shop/pages/search'
import KeywordPage from './Shop/pages/search/keyword'
import LoginPage from './Shop/pages/login'
import MiPerfilPageLayout from './Shop/pages/miperfil'
import MisDireccionesPage from './Shop/pages/miperfil/misdirecciones'
import MisDatosPage from './Shop/pages/miperfil/datos'
import RegistroPage from './Shop/pages/registro'
import PedidosPage from './Shop/pages/pedidos'
import PedidoPage from './Shop/pages/pedidos/pedido'
import MessagesPage from './Shop/pages/messages'
import MiCarritoPage from './Shop/pages/micarrito'
import MiCarritoDireccionPage from './Shop/pages/micarrito/direccion'
import MiCarritoEnvioPage from './Shop/pages/micarrito/envio'
import MiCarritoPagoPage from './Shop/pages/micarrito/pago'
import MiCarritoFinalizaPage from './Shop/pages/micarrito/finaliza'
import QuienesSomosPage from './Shop/pages/somos'
import AvisoPage from './Shop/pages/aviso'
import BolsaPage from './Shop/pages/bolsa_trabajo'
import FaqPage from './Shop/pages/faq'
import GlosarioPage from './Shop/pages/glosario'
import PoliticasCompras from './Shop/pages/politicas_compras'
import PagosEnviosPage from './Shop/pages/pagos_envios'
import Error404Page from './Shop/pages/404'
import ProductPage from './Shop/pages/product'
import ProveedorPageLayout from './Shop/pages/proveedor/layout'
import ProveedorPageIndex from './Shop/pages/proveedor'
import CatPage from './Shop/pages/cat'
import DepsPage from './Shop/pages/deps'
import DepsDepartamentoPage from './Shop/pages/deps/departamento'
import RecuperarPage from './Shop/pages/recuperar'
import RecuperarTokenPage from './Shop/pages/recuperar/token'
import AboutusPageLayout from './Shop/pages/aboutus/layout'
import AboutusPageIndex from './Shop/pages/aboutus'
import AccountSuspendedPageIndex from './Shop/pages/account_suspended'

const ScrollToUp = () => {
	const { pathname, search } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);

	}, [pathname, search]);
	return null
}

const App=()=>{

	const Hola=()=>{
		return (
			<div className="card-panel">
				<h4>BIENVENIDO RESELLER</h4>
			</div>
		)
	}

	const Not = () => {
		return(
				<>
				Not found
				</>
		)
		
	}

	return (
		<Router scrollRestoration="auto">
			<ScrollToUp />
			{/*Shop App*/}
			<DataProvider>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/search" element={<SearchPage />} />
					<Route path="/search/keyword" element={<KeywordPage />} />
					<Route path="/login" element={<LoginPage />} />
					<Route path="/registro" element={<RegistroPage />} />
					<Route path="/miperfil" element={<MiPerfilPageLayout />} >
						<Route path="misdirecciones" element={<MisDireccionesPage />} />
						<Route path="datos" element={<MisDatosPage />} />
					</Route>
					<Route path="/product" element={<ProductPage />} />
					<Route path="/pedidos" element={<PedidosPage />} />
					<Route path="/pedidos/pedido" element={<PedidoPage />} />
					<Route path="/messages" element={<MessagesPage />} />
					<Route path="/micarrito" element={<MiCarritoPage />} />
					<Route path="/micarrito/direccion" element={<MiCarritoDireccionPage />} />
					<Route path="/micarrito/envio" element={<MiCarritoEnvioPage />} />
					<Route path="/micarrito/pago" element={<MiCarritoPagoPage />} />
					<Route path="/micarrito/finaliza" element={<MiCarritoFinalizaPage />} />
					<Route path="/somos" element={<QuienesSomosPage />} />
					<Route path="/aviso" element={<AvisoPage />} />
					<Route path="/bolsa_trabajo" element={<BolsaPage />} />
					<Route path="/faq" element={<FaqPage />} />
					<Route path="/glosario" element={<GlosarioPage />} />
					<Route path="/politicas_compras" element={<PoliticasCompras />} />
					<Route path="/pagos_envios" element={<PagosEnviosPage />} />
					<Route path="/404" element={<Error404Page />} />
					<Route path="/proveedor" element={<ProveedorPageLayout />} >
						<Route index element={<ProveedorPageIndex />} />
					</Route>
					<Route path="/cat" element={<CatPage />} />
					<Route path="/deps" element={<DepsPage />} />
					<Route path="/deps/departamento" element={<DepsDepartamentoPage />} />
					<Route path="/recuperar" element={<RecuperarPage />} />
					<Route path="/recuperar/token" element={<RecuperarTokenPage />} />
					<Route path="/aboutus" element={<AboutusPageLayout />} >
						<Route index element={<AboutusPageIndex />} />
					</Route>
					<Route path="account_suspended" element={<AccountSuspendedPageIndex />} />
				</Routes>
			</DataProvider>

			{/*Admin App*/}
			<UserProvider>
				<Routes>
					<Route path="admin/login" element={<Login />} />
					<Route path='admin' element={<Dashboard />}>
						<Route index element={<Hola />} />
						<Route path="orderStatus" element={<OrderStatus />} />
						<Route path="catalogo" element={<Catalogo />} />
						{/*pedidos*/}
						<Route path="ventas" element={<VentasPageIndex />} />
						{/*e-commerce*/}
						<Route path="altaproducto" element={<NewProduct />} />
						<Route path="catalogo/producto/:catalogo_id" element={<EditProduct />} />
						<Route path="mis-datos" element={<PerfilEmpresa />} />
						<Route path="entrega/:pedido_id" element={<EntregaPageIndex />} />
						{/*Mensajes*/}
						<Route path="mensajes" element={<Mensajes />} />
						{/*Cotizaciones*/}
						<Route path="cotizaciones" element={<Cotizaciones />} />
						<Route path="cotizacion/:cotizacion_id" element={<Cotizacion />} />
						{/*Configuracion*/}
						<Route path="mi-tienda" element={<Carrusel />} />
						<Route path="website" element={<WebConfigs />} />
						{/*Visitas*/}
						<Route path="visitas" element={<Visitas />} />
						{/*Aviso Privacidad*/}
						<Route path="aviso-privacidad" element={<AvisoPrivacidad />} />
						{/*Faq*/}
						<Route path="faq" element={<Faq />} />
						{/*Glosario*/}
						<Route path="glosario" element={<Glosario />} />
						{/*Politicas*/}
						<Route path="politica-compra" element={<PoliticaCompra />} />
						<Route path="pagos-y-envios" element={<PagosEnvios />} />
						{/*Pickups*/}
						<Route path="pickups" element={<Pickups />} />
						{/*DropShiping*/}
						<Route path="dropshiping" element={<DropShipingPageIndex />} />
						<Route path="catalogoglobal" element={<CatalogoGlobalPageIndex />} />
						{/*Saldos*/}
						<Route path="balance/summary" element={<BalanceSummaryPageIndex />} />
						<Route path="balance/payment-history" element={<PaymentHistoryPageIndex />} />
						{/*My cuenta bancario*/}
						<Route path="my-account-bank" element={<MiBanca />} />
						{/*My Domains*/}
						<Route path="domains" element={<Domains />} />
						{/*Suscripcion*/}
						<Route path="suscripcion" element={<SuscripcionPageIndex />} />
						<Route path="suscripcion/plans" element={<PlansPageIndex />} />
						<Route path="suscripcion/plans/premium" element={<PremiumPageIndex />} />
					</Route>
				</Routes>
			</UserProvider>
		</Router>
	);
}

export default App;
