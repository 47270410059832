import React, { useContext, useEffect, useState } from 'react';
import StatusBar from '../../../Components/StatusBar';
import { DataContext } from '../../../../../contexts/DataContext' 
import { Link } from 'react-router-dom'
import Nav from '../../../../../components/Nav'
import Vistos from '../../../../../components/Vistos';
import html2canvas from 'html2canvas'
import successGif from './success.gif'

const Finalizar = () => {

	const { carrito, setCarrito, servicioEnvio, user, config } = useContext(DataContext)
	const [loader, setLoader] = useState(false)
	/**
	  *
	  * ticket
	  * @const pedido object constante para guardar y mostrar el pedido
	  */
	const pedido = false

	useEffect(()=>{
		//do some
		setCarrito(carrito.filter(c=>!c.selected))
		console.log(servicioEnvio)
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const _Entrega = () => {
		if(servicioEnvio.entrega){
			const fechaE = new Date(servicioEnvio.entrega)
			const fecha1=fechaE.toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })

			fechaE.setDate(fechaE.getDate() + 5);
			const fecha2=fechaE.toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })

			return fecha1+' - '+fecha2
		}
		if(servicioEnvio.servicio==='SAME_DAY_CITY'){
			return servicioEnvio.descripcion
		}
		return 'Fecha Desconocida consulte su guía en fedex'
	}

	const _imprimirComprobante = () => {
		setLoader(true)
		if (typeof document !== 'undefined') {
			html2canvas(document.querySelector("#imprimir-comprobante"),{allowTaint:true,useCORS:true}).then(canvas => {
				var a = document.createElement('a');
				a.download = 'comprobante.png';
				a.href = canvas.toDataURL()
				a.click()
				a.remove()
				setLoader(false)
			});
		}
	}

	return(
		<>
		{user.name===undefined&&
			<Nav />
		}
		<div style={{padding:'90px 50px', }}>
			<StatusBar status='fin'/> 



			{loader&&
			<div className='progress'>
				<div className='indeterminate primarycolor'></div>
			</div>}

				<div  className=''  style={{ textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center',}}>
					<div className='' style={{width:'100%!important'}}> <img src={successGif} style={{height:'100px',}} />  </div>
				</div>
				<div className='' style={{marginBottom:50, width:'100%!improtant', textAlign:'center',}}>
					<h4>¡Gracias por tu pedido!</h4>
					<p>Tu pedido se hizo exitosamente</p>
					<div style={{color:'#DF3F0C'}}>Fecha de entrega estimada: {_Entrega()}, después de validar el pago.</div>
				</div>

			{(!loader&&pedido)&&
				<div className='row'>
				<div className='container'>
					{pedido.envio.servicio==='Recolección'&&
						<div id='imprimir-comprobante' style={{padding:50}}>
						<h4>Pedido-{pedido.id}</h4>
						<a className='btn primarycolor waves-effect waves-light right' onClick={()=>_imprimirComprobante()}>Imprimir/Guardar comprobante</a>
						<p>
							Nota: imprime o muestra este comprobante en nuestra sucursal, junto con una identificación oficial para recoger tu pedido
						</p>
						<p className='bold'>
							Dirección:<br />
							{config.configs.direccion.StreetLines[0]}<br />
							{config.configs.direccion.StreetLines[1]}, cp.{config.configs.direccion.PostalCode} {config.configs.direccion.City}
						</p>
							<h5>Articulos:</h5>
							<table>
								<thead>
									<tr>
										<th>Código</th>
										<th>Nombre</th>
										<th>Cantidad</th>
									</tr>
								</thead>
								<tbody>
									{pedido.articulos.map((a,i)=>
									<tr key={i}>
										<td>{a.codigo}</td>
										<td>{a.nombre}</td>
										<td>{a.cantidad}</td>
									</tr>
									)}
								</tbody>
							</table>
						</div>
					}
				</div>
				</div>
			}

				<div className='' style={{width:'100%!improtant', textAlign:'center',}}>
					<Link to='/' className='btn waves-effect waves-light'>Descubre más productos</Link>
				</div>
			
		</div>
	
		<div className="container-large" tabIndex="0">
			<div className="container-blanco"> 
				<div className="source-sans nombre-clasificacion"> Vistos recientemente </div>
				<div className='row center-y-x' style={{padding:'6px'}}> 
					<Vistos/>
				</div> 
			</div>
		</div>

	
		</>
	)
}

export default Finalizar
