import React, { useEffect, useState } from 'react';
import { axios } from '../../../infrastructure/axios';
import { useNavigate } from 'react-router-dom';
import M from 'materialize-css'

const StepSix = () => {

	const navigate = useNavigate();
	const [data, setData] = useState({host:'', port:'', encryption:'', user_name:'', user_email:'', password:'', sender_name:'', sender_email:''})
	const [guardado, setGuardado] = useState(false);

	useEffect(()=>{
		M.Tooltip.init(document.querySelectorAll('.tooltipped'));
		axios.get('/api/emailconfigwebsite')
		.then(r=>{
			if(r.data.id) setGuardado(true)
			setData(r.data)
			M.updateTextFields()
		})
		.catch(r=>alert(r))
	},[])

	const _return = (e) => {
		e.preventDefault();
		axios.put('/api/savestatus',{config_store:{"wizard_config":{"installed":0,"status":"step_five"}}}).then((res)=>{
			navigate("/")
			window.location.reload()
		}).catch(e=>console.log("error",e))
	}
	
	const _pushData = (e) => {
		e.preventDefault()
		if(data.id){
			axios.put('/api/emailconfigwebsite', data)
			.then(r=>{
				setData(r.data)
				M.toast({html:'Configuración actualizada!!'})
				setGuardado(true)

			})
		.catch(r=>alert(r))
		}
		else{
			axios.post('/api/emailconfigwebsite', data)
			.then(r=>{
				setData(r.data)
				M.toast({html:'Configuración actualizada!!'})
				setGuardado(true)

			})
			.catch(r=>alert(r))
		}
		
	}
	const _finalizar = (e) => {
		e.preventDefault()
		if(!guardado){
			alert('Te recomendamos gardar tus datos, para que evites perder tu información')
		}
		else{
			//finalizar
			axios.put('/api/savestatus',{config_store:{"wizard_config":{"installed":1,"status":"step_six"}}}).then((res)=>{   
				navigate("/")
				window.location.reload()
			}).catch(e=>console.log("error",e))/**/
		}
	}

    return (
        <div>
            <div className='padding-20' style={{position:'relative'}}>
                <div className="row">
					<h5 style={{marginTop:30,marginBottom:30}}>Configuración de correo</h5> 
					<form onSubmit={_finalizar} className="col s12">
						<div className="row">
							<div className="input-field col s12 m6" style={{position:'relative'}}>
								<input value={data.host} onChange={(e)=>setData({...data, host:e.target.value})} id="host" type="text" className="validate los-input" />
								<label htmlFor="host">Host</label>
								<i className="material-icons tooltipped" 
									data-position="top" 
									data-tooltip="El nombre de tu cliente de correo Ej. mail.mi-dominio.com" 
									style={{position:'absolute',top:10, right:2,cursor:'pointer'}}>help</i>
							</div>
					
							<div className="input-field col s12 m6">
								<input value={data.port} onChange={(e)=>setData({...data, port:e.target.value})} id="port" type="number" className="validate los-input" />
								<label htmlFor="port">Puerto</label>
								<i className="material-icons tooltipped" 
									data-position="top" 
									data-tooltip="Ej. 465 ó 587" 
									style={{position:'absolute',top:10, right:2,cursor:'pointer'}}>help</i>
							</div>
						</div>
						<div className="row">
							<div className="input-field col s12 m6">
								<input value={data.encryption} onChange={(e)=>setData({...data, encryption:e.target.value})} id="encryption" type="text" className="validate los-input" />
								<label htmlFor="encryption">Conexión segura en el puerto (Encryption)</label>
								<i className="material-icons tooltipped" 
									data-position="top" 
									data-tooltip="Ej. SSL(recomendada) ó TLS" 
									style={{position:'absolute',top:10, right:2,cursor:'pointer'}}>help</i>
							</div>
						
							<div className="input-field col s12 m6">
								<input value={data.user_name} onChange={(e)=>setData({...data, user_name:e.target.value})} id="user_name" type="text" className="validate los-input" />
								<label htmlFor="user_name">Nombre de usuario</label>
								<i className="material-icons tooltipped" 
									data-position="top" 
									data-tooltip="El nombre de tu usuario" 
									style={{position:'absolute',top:10, right:2,cursor:'pointer'}}>help</i>
							</div>
					
							<div className="input-field col s12 m6">
								<input value={data.password} onChange={(e)=>setData({...data, password:e.target.value})} id="password" type="text" className="validate los-input" />
								<label htmlFor="password">Contraseña</label>
								
							</div>
					
							<div className="input-field col s12 m6">
								<input value={data.sender_name} onChange={(e)=>setData({...data, sender_name:e.target.value})} id="sender_name" type="text" className="validate los-input" />
								<label htmlFor="sender_name">Sender Name</label>
								<i className="material-icons tooltipped" 
									data-position="top" 
									data-tooltip="El nombre que aparecera cada que se envié un correo" 
									style={{position:'absolute',top:10, right:2,cursor:'pointer'}}>help</i>
							</div>
						</div>
						<div className="row">
							<div className="input-field col s12 m6">
								<input value={data.sender_email} onChange={(e)=>setData({...data, sender_email:e.target.value})} id="sender_email" type="text" className="validate los-input" />
								<label htmlFor="sender_email">Sender Email</label>
								<i className="material-icons tooltipped" 
									data-position="top" 
									data-tooltip="El correo desde el cual se enviaran todos los e-mails de tu página" 
									style={{position:'absolute',top:10, right:2,cursor:'pointer'}}>help</i>
							</div>
						</div>
						<button className='btn waves-effect waves-light right' style={{backgroundColor:'#4966B1',color:'white',position:'absolute',right:20,bottom:-45,height:50,width:150}}>Finalizar</button>
					</form>
				</div>
				<div className='' style={{position:'absolute',left:20,bottom:-45,}}>
					<button className='btn'  onClick={(e)=>_return(e)}
						style={{backgroundColor:'#4966B1',color:'white',height:50,width:150}}>
						Anterior
					</button>
				</div>  
				<div className='' style={{position:'absolute', right:0, bottom:12,width:'100%',}}>
					<div className='col m9' style={{color:'red',fontSize:'12px', fontWeight:900,}}>Nota: Para que conserves tu información, guarda antes de regresar o finalizar &nbsp;&nbsp;&nbsp;</div>
					<button className='col m3 btn'  onClick={(e)=>_pushData(e)}
						style={{backgroundColor:'#4966B1',color:'white',height:50,}}>
						Guardar
					</button>
				</div> 
			</div>
		</div>
    );
};

export default StepSix;
