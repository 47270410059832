import React, { useEffect,useState } from 'react';
import M from 'materialize-css'
import { axios } from '../../infrastructure/axios';

const ListaProductos = ({list, cupon}) => {

	useEffect(()=>{
		M.Modal.init(document.querySelectorAll('#modal-list-productos'), {});
	},[])

	return (
	   <div id="containerModal">
		   {list &&
			<div id="modal-list-productos" className="modal" style={{width:'90vw!important', maxWith:'90vw!important', overflow:'visible!important'}}>
				<div className="modal-content" style={{width:'100%', margin:0, padding:'10px', borderRadius:'12px',}}>
					<div className="row">
					{list &&
						<div>
							<div className="col m4 row">
								<div style={{padding:'16px'}}>
									<b style={{fotSize:'18px',color:'#000000',fontWeight:'800'}}><br></br>Total paid (without shipping cost):</b> 
									<span style={{borderRadius:'12px', height:'60px!important', minHeight:'60px!important', padding:'10px', fontSize:'22px', color:'#ffffff', fontWeight:'900',marginTop:'-10px'}} className="badge red">$ {list.reduce((a,b)=>a+(parseFloat(b.precio)*parseInt(b.cantidad)),0)}</span> 
								</div> 
							   
								<div style={{padding:'16px'}}> 
									<b style={{fotSize:'18px',color:'#000000',fontWeight:'800'}}><br></br>Coupon applied</b>
									{cupon.cupon?
									<span style={{borderRadius:'12px', height:'60px!important', minHeight:'60px!important', padding:'10px', fontSize:'22px', color:'#ffffff', fontWeight:'900',marginTop:'-10px'}} 
									className="badge blue"> YES </span> 
									:
									<span style={{borderRadius:'12px', height:'60px!important', minHeight:'60px!important', padding:'10px', fontSize:'22px', color:'#ffffff', fontWeight:'900',marginTop:'-10px'}} 
									className="badge blue"> NO </span>
									}
								</div>
								
							</div>
						{cupon.cupon &&	
							<div className="col m8 row">
								<div className="col m6">
									<div> {cupon.visible?
										<div style={{fontSize:'16px', color:'#000000'}}>
										<b style={{fotSize:'14px',color:'#cdcdcd'}}>Coupon Active:</b> Si </div>
										:
										<div style={{fontSize:'16px', color:'#000000'}}>
										<b style={{fotSize:'14px',color:'#cdcdcd'}}>Coupon Active:</b> No </div>
									}
									</div>
									<div style={{fontSize:'16px', color:'#000000'}}>
										<b style={{fotSize:'14px',color:'#cdcdcd'}}>Coupon Name:</b>a{cupon.cupon}</div>
								</div>
								<div className="col m6">			
									<div style={{fontSize:'16px', color:'#000000'}}>
										<b style={{fotSize:'14px',color:'#cdcdcd'}}>Type:</b> {cupon.tipo}</div>
									<div style={{fontSize:'16px', color:'#000000'}}>
										<b style={{fotSize:'14px',color:'#cdcdcd'}}>Discount:</b>{cupon.descuento}</div>
								</div>
								<div className="col" style={{fontSize:'16px', color:'#000000'}}>
										<b style={{fotSize:'14px',color:'#cdcdcd'}}>Note:</b> {cupon.nota}
								</div>
							</div>
						}  
						</div>
					}
					</div>
				
					<div className="col row" style={{width:'100%',backgroundColor:'#f7f7f7', margin: 0, padding:0, fontSize:'14px', color:'#000000', fontWeight:'900'}}>
						<table id="listProd" style={{width:'100%', margin: 0, padding:0,}}>
							<thead style={{width:'100%', margin: 0, padding:0,}}>
								<tr>
									<th style={{width:'10%',}}></th>
									<th style={{textAlign:'center'}}>Departament</th>
									<th style={{textAlign:'center'}}>Category</th>
									<th style={{textAlign:'center'}}>Name</th>
									<th style={{textAlign:'center'}}>Price</th>
									<th style={{textAlign:'center'}}>Quantity</th>
								</tr>
							</thead>
							<tbody style={{width:'100%', margin: 0, padding:0,}}>
								{list.map((l,i)=> 
									<tr key={i} style={{borderBottom:'solid 1px #ECECEC', padding:'10px!important', width:'100%'}}>
										<td style={{width:'10%', margin:0,padding:'5px'}}> 
											<div  className="col"
											style={{background:'#fff', borderRadius:'12px', padding:'5px', margin:'0 auto',height: '100px',width: '100px', 
											display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',}}>
												<img src={l.urls.split(',')[0]} alt='image' style={{objectFit:'scale-down!important', borderRadius:'8px',width:'100%'}}/>
											</div>
										</td>
										<td style={{margin:0,padding:'5px'}}>
											<div style={{margin:0, padding:0, display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',}}>
												{l.departamento}</div>
										</td>
										<td style={{margin:0,padding:'5px'}}>
											<div style={{margin:0, padding:0, height:'84px', overflowY:'scroll',overflowX:'hidden',}}>
												{l.categoria.split(",").map((item,i)=> <div key={i} style={{lineHeight:1.2}}>- {item}</div> )}</div>
										</td>
										<td style={{margin:0,padding:'5px'}}>
											<div style={{margin:0, padding:0, display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontSize:'16px'}}>
												{l.nombre}</div>
										</td>
										<td style={{margin:0,padding:'5px'}}>
											<div style={{margin:0, padding:0, display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',
											color:'red', fontSize:'16px'}}>
											$ {l.precio}</div>
										</td>
										<td style={{margin:0,padding:'5px',}}>
											<div style={{margin:'0 auto', padding:0, textAlign:'center',}}>
												<span style={{borderRadius:'12px', height:'60px!important', minHeight:'60px!important', padding:'10px', fontSize:'22px', color:'#ffffff', fontWeight:'900',marginTop:'-10px'}} 
												className="badge green"> {l.cantidad} </span>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		   }
		</div>   
	);
};

export default ListaProductos;
