import React from 'react'
import Direcciones from './Components/Direcciones'

const Index = () => {

	return(
		<>
			<Direcciones />
		</>
	)
	
}

export default Index
