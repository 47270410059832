import Nav from '../../components/Nav';
import { Link } from 'react-router-dom'
import { DataProvider } from '../../contexts/DataContext'
import dogImage from './dog.png'

const Error404 = () => {

		return (
			<DataProvider>
			<div>
				<Nav />
				<center>
				<div style={{display:'inline-block'}}>
					<h3>LO SENTIMOS</h3>
					<h4>
						no pudimos encontrar la página que buscabas
					</h4>
					<div>
						Trata de usar la barra de búsqueda o visita la <Link to='/'> página principal</Link>
					</div>
					<img src={dogImage} style={{maxWidth:'100%', height:'auto'}} alt='dog'/>
				</div>
				</center>
			</div>
			</DataProvider>
		);
};

export default Error404;
