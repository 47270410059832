import Nav from '../../components/Nav'
import Producto from './Components/Producto'
import Footer from '../../components/Footer'
//import { useSearchParams } from 'next/navigation' //nextjs
import { useLocation } from 'react-router-dom'; //reactjs

const Index = () => {

	/**
	  * #ticket-005 routing actualmente se usa /product?categoria_id={id}&codigo={id}
	  * cambiar a /product/{codigo} y /product/{categoria_id}/{codigo}
	  * se desarrollo usando /product?categoria_id={id} debido a bug en nextjs
	  * consultar y resolver este issue dependiendo si ya se soluciono el bug:
	  * https://github.com/vercel/next.js/issues/48022
	  *
	  */
	//nextjs
	//const searchParams = useSearchParams()
	//const categoria_id = searchParams.get('categoria_id')
	//const codigo = searchParams.get('codigo')

	//reactjs
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const categoria_id = searchParams.get('categoria_id');
	const codigo = searchParams.get('codigo');

	return(
		<>
			<Nav />
			{categoria_id?
			<Producto categoria_id={categoria_id} codigo={codigo} />:
			<Producto codigo={codigo} />
			}
			<Footer />
		</>
	)
	
}

export default Index
