import React, { useState, useEffect } from 'react'
import { axios } from '../../../../infrastructure'
import M from 'materialize-css'
import './index.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import notfound_png from '../../../../../Utils/Assets/not-found.png'

const Index = () => {

	const [proveedor, setProveedor] = useState({company_accepted_delivery_terms:[], company_accepted_payment_type:[], export_regions:[], company_photos:[], certificates:[]})
	const [loader, setLoader] = useState(false)

	useEffect(()=>{
		axios.post('/api/reseller/aboutus')
		.then((r)=>{

			setProveedor(r.data)

			setTimeout(()=>{
				M.Materialbox.init(document.querySelectorAll('.materialboxed'), {
					onOpenStart: ()=>{
						document.querySelector('.swiper-button-next').style.zIndex = '0';
						document.querySelector('.swiper-button-prev').style.zIndex = '0';
					},
					onCloseStart: ()=>{
						document.querySelector('.swiper-button-next').style.zIndex = '10';
						document.querySelector('.swiper-button-prev').style.zIndex = '10';
					}
				});
			},1000)

		})
		.catch(e=>alert(e))
	},[])

	/**
	  * @const _send - function
	  * @param e event
	  * @return {void}
	  */
	const _send = (e) => {

		setLoader(true)
		let message = document.querySelector('#contact-supplier-input').value
		axios.post('/api/message/send_message', {message, to_user_uuid: proveedor.message_api_uuid})
		.then(r=>{
			console.log(r.data)
			document.querySelector('#contact-supplier-input').value=''
			setLoader(false)
			M.toast({html:'Message send!'})
			M.toast({html:'Check your messages in the panel!'})
		})
		.catch(r=>console.log(r))

	}

	return (
			<div style={{padding:'0px 50px', marginTop:50}}>
				<div className='row'>
					<div className='col m2'>
						<div className='card-panel'>
							<h5>About Us</h5>
							<div>
								<a href='#company-profile' style={{display:'block', padding:10, color:'black'}}>Company Profile</a>
								<a href='#trade-capacity' style={{display:'block', padding:10, color:'black'}}>Trade Capacity</a>
								<a href='#production-capacity' style={{display:'block', padding:10, color:'black'}}>Production Capacity</a>
								<a href='#company-show' style={{display:'block', padding:10, color:'black'}}>Company Show</a>
							</div>
						</div>
						<div className='card-panel'>
							<center>
								<h5>Contact Supplier</h5>
								<img alt='company_logo' src={proveedor.company_logo??notfound_png} height='100' width='100'/>
							</center>
							<p style={{marginLeft:20}}>
								{proveedor.company_name} <br />
								chat with a supplier
							</p>
							<textarea id='contact-supplier-input' />
							{!loader?
							<a href='#!' onClick={()=>_send()} className='btn waves-effect waves-light' style={{width:'100%', color:'white', background:'red'}}>Send message</a>:
							<a href='#!' className='btn waves-effect waves-light' style={{width:'100%', color:'white', background:'red'}}>Loading ...</a>
							}
								
						</div>
					</div>
					<div className='col m10'>
						<div id='company-profile' className='card-panel'>
							<div style={{fontSize:20, marginBottom:30}}>Company Profile</div>
								<div className='row'>
									<div className='col m3'>
										<Swiper className="" loop={false} modules={[Pagination, Scrollbar, A11y, Autoplay]} style={{ marginLeft:'0', marginRight:'0', position:'relative'}} spaceBetween={1} effect={'fade'} slidesPerView={'auto'} pagination={{ clickable: true }} autoplay={{delay:5000}} tabIndex="0">
											{proveedor.company_photos.length>0?proveedor.company_photos.map((p,i)=>
											<SwiperSlide key={i}>
												<div style={{display:'relative'}}>
													<img className="materialboxedi" style={{objectFit:"scale-down", width:'100%'}} data-caption={p} alt='' src={p}/>
												</div>
											</SwiperSlide>
											):
											<img alt='company_logo' src={notfound_png} style={{objectFit:"scale-down", width:'100%'}}/>
											}
										</Swiper>
									</div>
									<div className='col m9'>
										<div className='row'>
											<div className='col s2' style={{color:'grey'}}>Business Type:</div>
											<div className='col s10'> {proveedor.company_business_type?.join(', ')} </div>
										</div>
										<div className='row'>
											<div className='col s2' style={{color:'grey'}}>Main Products:</div>
											<div className='col s10'> {proveedor.product_details_main_products?.filter(f=>f!==null).join(', ')} </div>
										</div>
										<div className='row'>
											<div className='col s2' style={{color:'grey'}}>Plant Area:</div>
											<div className='col s10'> {proveedor.company_office_size} </div>
										</div>
										<div className='row'>
											<div className='col s2' style={{color:'grey'}}>Management System Certification:</div>
											<div className='col s10'>{proveedor.certificates.map((c)=>c.name).join(', ')}</div>
										</div>
									</div>
								</div>
								<div>
									{proveedor.company_introduction}
								</div>
							</div>
						<div className='card-panel'>
							<div className='' id='trade-capacity' style={{marginTop:50}}>
								<div style={{fontSize:20, marginBottom:30}}>Trade Capacity</div>
								<div className='row'>
									<div className='col s3' style={{color:'grey'}}>Delivery terms:</div>
									<div className='col s9'>
										{proveedor.company_accepted_delivery_terms.join(', ')}
									</div>
								</div>
								<div className='row'>
									<div className='col s3' style={{color:'grey'}}>Payment type:</div>
									<div className='col s9'>
										{proveedor.company_accepted_payment_type.join(', ')}
									</div>
								</div>
								<div className='row'>
									<div className='col s3' style={{color:'grey'}}>Export Percentaje:</div>
									<div className='col s9'>
										{proveedor.export_percentage}
									</div>
								</div>
								<div className='row'>
									<div className='col s3' style={{color:'grey'}}>Main Markets:</div>
									<div className='col s9'>
										{proveedor.export_regions.join(', ')}
									</div>
								</div>
								<div className='row'>
									<div className='col s3' style={{color:'grey'}}>Nearest Port:</div>
									<div className='col s9'>
										{proveedor.nearest_port}
									</div>
								</div>
								<div className='row'>
									<div className='col s3' style={{color:'grey'}}>Export license number:</div>
									<div className='col s9'>
										{proveedor.export_license_number}
									</div>
								</div>
								<div className='row'>
									<div className='col s3' style={{color:'grey'}}>Export license file:</div>
									<div className='col s9'>
									{proveedor.export_license_number_picture&&
										<img className='materialboxed' alt='export-license' src={proveedor.export_license_number_picture} width='100' />
									}
									</div>
								</div>
							</div>
						</div>
						<div className='card-panel'>
							<div className='' id='production-capacity' style={{marginTop:50}}>
								<div style={{fontSize:20, marginBottom:30}}>Production Capacity</div>
								<div className='row'>
									<div className='col s3' style={{color:'grey'}}>Company operational address:</div>
									<div className='col s9'>
										{proveedor.company_operational_address_city}
									</div>
								</div>
								<div className='row'>
									<div className='col s3' style={{color:'grey'}}>Total employees:</div>
									<div className='col s9'>
										{proveedor.company_total_employees}
									</div>
								</div>
								<div className='row'>
									<div className='col s3' style={{color:'grey'}}>Office size:</div>
									<div className='col s9'>
										{proveedor.company_office_size}
									</div>
								</div>
								<div className='row'>
									<div className='col s3' style={{color:'grey'}}>Annual Output of Main Products ($USD):</div>
									<div className='col s9'>
										Above USD ${proveedor.annual_output_main_products}
									</div>
								</div>
							</div>
						</div>
						<div className='card-panel'>
							<div className='' id='company-show' style={{marginTop:50}}>
								<div style={{fontSize:20}}>Company Show</div>
								<div style={{color:'grey', display:'flex', alignItems:'center'}}>company album   <i className="material-icons">image</i>{proveedor.company_photos.length}</div>
								<Swiper modules={[Navigation]} loop={false} slidesPerView={5} spaceBetween={30} pagination={{ clickable: true }} navigation>
									{proveedor.company_photos.map((p,i)=>
									<SwiperSlide key={i}>
										<div style={{display:'relative'}}>
											<img className="materialboxed" style={{objectFit:"scale-down"}} height='200' width='300' data-caption={p} alt='' src={p}/>
											<div style={{position:'absolute', top:0, left:10, color:'white', background:'rgba(0,0,0,.5)', padding:5, borderBottomLeftRadius:10, borderBottomRightRadius:10}}>Verified</div>
										</div>
									</SwiperSlide>
									)}
								</Swiper>
								<div style={{fontSize:18}}>Certificates <span style={{color:'grey'}}>{proveedor.certificates.length} Items</span></div>
								<div className='row'>
									<div style={{display:'flex', overflowY:'auto'}}>
										{proveedor.certificates.map((c,i)=>
										<div key={i} className='' style={{position:'relative', display:'inline-block', border:'1px solid #e6e7eb', margin:10, padding:10}}>
											<img className='materialboxed' alt={c.name} src={c.url} style={{width:150, height:200, objectFit:'center'}}/>
											<div style={{textAlign:'center'}}>{c.name}</div>
										</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
	)
	
}

export default Index
