import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import axios from '../../../../../infrastructure/axios'
import FilterNav from '../../../../../components/Nav/FilterNav'
import ProductCard from '../../../../../components/ProductCard';
import Loader from '../../../../../components/Loader';
import M from 'materialize-css'

const Departamentos = ({departamento_id}) => {

	const [productos, setProductos] = useState([])
	const [loader, setLoader] = useState(true)
	const [departamento, setDepartamento] = useState({})
	const [departamentos, setDepartamentos] = useState([])
	const [categorias, setCategorias] = useState([])
		
	useEffect(() => {

		axios.get('/api/departamentos')
		.then(response=>{
			setDepartamentos(response.data)

			var departament = response.data.find((depa)=>depa.id===parseInt(departamento_id)) 
			setDepartamento(departament)
			setCategorias([...departament.categorias, ...response.data.reduce((a,b)=>b.id!==parseInt(departamento_id)?[...a, ...b.categorias]:a, []) ])
		})
		axios.get(`/api/reseller/catalogo_departamento/${departamento_id}`)
		.then(r=>{
			setProductos(r.data)
			setLoader(false)
			M.Collapsible.init(document.querySelector('.collapsible'), {})
		})
		.catch((r)=>alert(r))
	
	},[departamento_id])

	return (
		<div style={{minHeight:'80vh'}}>
		<FilterNav categorias={categorias} departamentos={departamentos} productos={productos} setProductos={setProductos}/>
		{loader?
			<Loader />
			:
		<>
			<div className='row hide-on-640' style={{marginBottom:'0px'}}>
				<div className='col s12 m-p-0 brad' style={{padding:'20px 0px!important', width:'99vw'}}>
					<div className="row m-p-0" style={{alignItems:'center',}}>
						<div className='migaja1'>
							<Link className="" to='/deps'>{departamento.departamento} &nbsp; </Link></div>
					</div>
				</div>
			</div>

				<div className="row m-p-0" style={{width:'100%'}}>
					<div className="col s1 cont-listado m-p-0 lateral-cat hide-on-640">
						<div className="row nameCat m-p-0">
							<Link to='/deps'>
								<div className="col" style={{display:'inline-block', lineHeight:'1', margin:0, padding:'7px 0px 4px 10px', width:'80%', textAlign:'left!important',}}> 
									<div className='nombre-seccion-principal'></div>
								</div>
							</Link>
						</div>
						<div className="m-p-0" style={{padding:'10px', paddingRight:0,}}>
							<ul className="collapsible" style={{boxShadow:'none', borderTop:"1px solid transparent", borderLeft:"1px solid transparent", borderRight:"1px solid transparent", padding:'0 0 0 15px'}}>
							{departamentos.map((p,i)=>
								<li key={i} className={(p.id === parseInt(departamento_id) )? "active" : "" } > 
									
									<div className="collapsible-header">{ p.departamento} </div>
									<div className="collapsible-body" style={{padding:'5px 0 5px 15px ', paddingTop:0}}>
										{
											p.categorias.map((c,j) => 
											<Link key={j} className="" to={`/cat?categoria_id=${c.id}&departamento_id=${p.id}`}> 
												<div style={{fontSize:'12px', lineHeight:1, padding:'0 2px 8px 2px', letterSpacing:'-1x', fontWeight:'400',}}> {c.categoria} </div>
											</Link>
											)
										}
									</div>
								</li>
							)}
							</ul>
						</div>
					</div>
					<div className="col s11 cont-listado lateral-list">
					{
					productos.map((p,i)=>
						<div key={i} className='col m-p-0'>
							<ProductCard producto={p} />	
						</div>
					)}
					</div>
				</div>
		</>
		}
		</div>
	)
}

export default Departamentos
