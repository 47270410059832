import axios from 'axios'

const instance = axios.create({
	baseURL: 'https://kangoru-inventario-api.asiamart.com.mx',
})

instance.interceptors.response.use(
    function (response) { 
        return response; 
    }, 
    function (error) {
        if (401 === error.response.status) {
            //window.location.replace('login');
           //createBrowserHistory().push('/login');
           //window.location.reload();
           window.location.replace('/admin/login');
        }
	return Promise.reject(error);
    });

if (typeof window !== 'undefined') {
    instance.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
    instance.defaults.headers.common['X-Frontend-Domain'] = window.location.hostname.replace('www.','');
}


export { instance as axios };
