import React, { useEffect, useState } from 'react';
import { axios } from '../../../infrastructure/axios';
import M from 'materialize-css'
import FileUploadWithPreview from 'file-upload-with-preview';
import JoditEditor from "jodit-react";
import { useNavigate } from 'react-router-dom';

const StepFour = () => {

	const navigate = useNavigate();
   
	const [data, setData] = useState({codigo:'', nombre:'', descripcion:'',descripcion_general:'', peso_bruto:'', peso_neto:'', precio:'', minimo_compra:'', departamento_id:'', imagenes:[], stock:1})
	const [deptInput, setDepsInput] = useState({departamento:'', descripcion:''})
	const [categorias, setCategorias] = useState([])
	const [catSelect, setCatSelect] = useState([])
	const [departamentos, setDepartamentos] = useState([])
	const [catInput, setCatInput] = useState('')
	const [fotos, setFotos] = useState([])
 
	const [search, setSearch] = useState('')

	const _return = (e) => {
		e.preventDefault();
		axios.put('/api/savestatus',{config_store:{"wizard_config":{"installed":0,"status":"step_three"}}}).then((res)=>{
			navigate("/")
			window.location.reload()
		}).catch(e=>console.log("error",e))
	}

	useEffect(() => {

		axios.get(`/api/catalogo/1`)
		.then((r) => {
			console.log("si tengo", r.data)
			if(r.data.id){
				setData({...r.data, stock:r.data.stock.piezas})
				setCatSelect(r.data.categorias)
				M.FormSelect.init(document.querySelectorAll('select'), {});
				M.updateTextFields()
				M.textareaAutoResize(document.querySelector('#textarea1'));
				M.Materialbox.init(document.querySelectorAll('.materialboxed'), {});
			}
		})
		.catch((r)=>alert(r))
	

		axios.get('/api/departamento')
		.then((r) => {
			setDepartamentos(r.data)
			M.FormSelect.init(document.querySelectorAll('select'), {});
		})
		.catch(r=>alert(r))
		new FileUploadWithPreview('uploadId');
	}, [])

	const _imgDestroya = (obj) => {
		axios.delete(`/api/imagen/${obj.id}`)
		.then((r) => {
			setData({...data, imagenes:data.imagenes.filter((i) => i.id!==obj.id)})
		})
		.catch((r) =>alert(r))
	}

	const _catSelect = (obj) => {
		setCategorias(categorias.filter((c)=>c.id!==obj.id))
		setCatSelect([...catSelect, obj])
	}

	const _catSelectDelete = (obj) => {
		setCatSelect(catSelect.filter((c)=>c.id!==obj.id))
		setCategorias([...categorias, obj])
	}

	const _pushCat = () => {
		axios.post('/api/categoria',{categoria:catInput, departamento_id:data.departamento_id})
		.then((r) => {
			setCatSelect([...catSelect, r.data])
			setCatInput('')
			M.toast({html: 'Categoria cargada!'})
		})
		.catch(r=>alert(r))
	}

	const _pushData = (e) => {
		e.preventDefault()
		if(catSelect.length===0){
			alert('selecciona categorias')
		}
		else{
			const dataF = new FormData()
			
			dataF.append('data', JSON.stringify({...data, categorias: catSelect.map((c) =>c.id )}))
		   
			if(data.id){
				_updateData(dataF)
			}
			else{
				_saveData(dataF)
			}
			
		}
	}

	const _saveData = (dataF) => {
		fotos.forEach(f => dataF.append('files[]', f));
	
		axios.post('/api/catalogo',dataF)
			.then((r) => {
				M.toast({html: 'Agregado al catálogo!'})
				axios.put('/api/savestatus',{config_store:{"wizard_config":{"installed":0,"status":"step_five"}}}).then((res)=>{   
					navigate("/")
					window.location.reload()
				}).catch(e=>console.log("error",e))
			})
			.catch(r=>{
				if(r.response && r.response.status===400)
					alert(r.response.data.message)
				else alert(r)
			})
	}

	const _updateData = (dataF) =>{

		dataF.append('_method', 'PUT')
		axios.post('/api/catalogo',dataF)
			.then((r) => {
				M.toast({html: 'Agregado al catálogo!'})
				axios.put('/api/savestatus',{config_store:{"wizard_config":{"installed":0,"status":"step_five"}}}).then((res)=>{   
					navigate("/")
					window.location.reload()
				}).catch(e=>console.log("error",e))
			})
			.catch(r=>{
				if(r.response && r.response.status===400)
					alert(r.response.data.message)
				else alert(r)
			  
			})
	}

	const _fileUpload = (e) => {
		const dataFoto = new FormData()
		dataFoto.append('file', e.target.files[0])
		dataFoto.append('catalogo_id', data.id)
		dataFoto.append('codigo', data.codigo)

		axios.post('/api/imagen', dataFoto)
		.then((r) => {
			setData({...data, imagenes:[...data.imagenes, r.data]})
			M.toast({html: 'Archivo subido!'})
			M.Materialbox.init(document.querySelectorAll('.materialboxed'), {});
		})
		.catch((r) =>alert(r))
	}

	const _dosDecimales = (n) => {
		let t=n.toString();
		let regex=/(\d*.\d{0,2})/;
		if(t.length===0) return '';
		return t.match(regex)[0];
	}

	return (
		<div>
			<div className='padding-20' style={{position:'relative'}}>
				<div className="row">
					<form onSubmit={_pushData}>
							<h5>Alta  de producto</h5>
							<h6>Todo sus cambios o ajustes se verán reflejados al presionar el botón de SIGUIENTE en la parte inferior</h6> 
						   
							<div className='row' onSubmit={(e)=>e.preventDefault()}>
								<div className='row'>
									<div className='col m6'>
										<div className='input-field col s12'>
											<input className="los-input" name='codigo' value={data.codigo} onChange={(e) => setData({...data, codigo:e.target.value})} id='codigo' type='text' required/>
											<label htmlFor='codigo'>Código</label>
										</div>
										<div style={{fontSize:11, marginLeft:10, color:'red'}}>
											Un código SKU es un código único que identifica a un producto concreto en tu inventario.
										</div>
									</div>
									<div className='col m6'>
										<div className='input-field col s12'>
											<input className="los-input" name='nombre' value={data.nombre} onChange={(e) => setData({...data, nombre:e.target.value})} id='nombre' type='text' required/>
											<label htmlFor='nombre'>Nombre</label>
										</div>
										</div>
									<div className='col m12'>
										<div className='input-field col s12'>
											Descripción corta  <br></br>
											<textarea className="los-input" name='descripcion' value={data.descripcion} onChange={(e) => setData({...data, descripcion:e.target.value})}  id='textarea1' className='materialize-textarea' style={{minHeight:200, border:'solid 1px grey', backgroundColor:'#ffffff'}} required />
											
										</div>
									</div>
									<div className='col m12'>
										<div className='input-field col s12'>
											<div>
												<b>Información del producto:</b><br></br>
											</div>
											<JoditEditor value={data.descripcion_general} tabIndex={1}  config={{ readonly: false,askBeforePasteHTML:false }} onBlur={newContent => setData({...data, descripcion_general:newContent})} />
										</div>
										
										{/**
										 * <div className='input-field col s12'>
											<div>
												<b>Descripción General:</b><br></br>
												<p style={{fontSize:'11px!important', lineHeight:'.8!important'}}> <span style={{fontWeight:'700'}}>NOTA: </span>
													<span style={{fontSize:'11px!important', lineHeight:'.8!important'}}>
														Si pegaras texto, clic derecho sobre el cuadro de texto, al momento de estar en el cuadro de dialogo,
														con el titulo<br></br>
														<b>-Pegar como HTML?-</b><br></br>
														Selecciona INSERTAR SOLO TEXTO
													</span>
												</p> 
											</div>
											<JoditEditor value={data.descripcion_general} tabIndex={1} onBlur={newContent => setConfig({...data, descripcion_general:newContent})} />

											<JoditEditor value={config.quienessomos} config={{ readonly: false,askBeforePasteHTML:false }}  onBlur={newContent => setConfig({...config, quienessomos:newContent})} />
										</div>
										 */}
									</div>
								</div>
								<div className='row'>
									<div className='col m2'>
										<div className='input-field col s12'>
										   Stock <span style={{color:'#000000', fontSize:'12px', fontWeight:900}}> Nota:Cantidad </span> <br></br>
											<input className="los-input" name='stock' min="0" value={data.stock} onChange={(e) => setData({...data, stock:parseInt(e.target.value)})} id='stock' type='number' required/>
											
										</div>
									</div>
									<div className='col m5'>
										<div className='input-field col s12'>
											Peso bruto en gramos.  <span style={{color:'#000000', fontSize:'12px', fontWeight:900}}> Nota:Decimales no son permitidos </span> <br></br>
											<input className="los-input" name='peso_bruto' min="0" value={data.peso_bruto}  onChange={(e) => setData({...data, peso_bruto:e.target.value.replace(/\D/g, '')})}  id='peso-bruto' type='number' required/>
											
										</div>
									</div>
									<div className='col m5'>
										<div className='input-field col s12'>
											Peso neto en gramos.  <span style={{color:'#000000', fontSize:'12px', fontWeight:900}}> Nota:Decimales no son permitidos</span> <br></br>
											<input className="los-input" name='peso_neto' min="0" pattern="^[0-9]+" value={parseInt(data.peso_neto)} onChange={(e) => setData({...data, peso_neto:e.target.value.replace(/\D/g, '')})} id='peso-neto' type='number' pattern="[0-9]{0,13}" required/>
										</div>
									</div>
									<div className='col m6'>
										<div className='input-field col s12'>
											Precio <br></br>
											<input className="los-input" name='precio' min="0" value={data.precio} onChange={(e) => setData({...data, precio:_dosDecimales(e.target.value)})} id='precio' type='number' min="1" step="any"  required/>
											
										</div>
									</div>
									<div className='col m6'>
										<div className='input-field col s12'>
											Minimo de Piezas <br></br>
											<input className="los-input" name='minimo_compra' min="0" value={data.minimo_compra} onChange={(e) => setData({...data, minimo_compra:e.target.value.replace(/\D/g, '')})} id='minimo_compra' type='number' required/>
											
										</div>
									</div>
								</div>
									<div className='col m6'>
										<div className='input-fieldi col s12'>
											<label htmlFor='departamento' style={{position:'relative!important'}}>Departamento</label>
											<select className="los-input" name='departamento' value={data.departamento_id} onChange={(e)=>{setData({...data, departamento_id:e.target.value});setCategorias(departamentos.find(d=>String(d.id)===e.target.value).categorias)}} required>
												<option value='' disabled>Seleccionar departamento</option>
												{departamentos.map((d,i) => <option key={i} value={d.id}>{d.departamento}</option>)}
											</select>
										</div>
										<div className='col m12' style={{marginBottom:50,}}>
											<div>Categorias Seleccionadas:</div>
												{catSelect.map((c,i) =>
												<a key={i} href='#!' className='waves-effect waves-light btn' style={{borderRadius:20, textTransform:'none', fontSize:12, letterSpacing:'none',}}><i onClick={() => _catSelectDelete(c)} className='material-icons right'>clear</i>{c.categoria}</a>
												)}
										</div>
									</div>
								
									{/*Agregar departamento*/}
									<div className='col m6'>
										{/*Agregar categoria*/}
										{data.departamento_id!==''&&
										<div className='col m12'>
											Seleccionar Categorias
											<nav>
												<div className="nav-wrapper">
													<div className="input-field white"> 
														<div className="row">
															<div className='col m2 centrar'> <i className="material-icons black-text">search</i> </div>
															<div className='col m8'> 
																<label className="label-icon" htmlFor="search">
																<input value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" placeholder='Buscar'/></label> </div>
															<div className='col m2'> 
																<i className="material-icons" >close</i> </div>
														</div>
													</div>
												</div>
											</nav>
											<div className='row'>
												<div>
													<div className='col m12'>
														<div className='input-field'>
															<input className="los-input" name='categoria' value={catInput} onChange={(e) => setCatInput(e.target.value)} id='categoria' type='text' className='validate' />
															<label htmlFor='categoria'>Nueva Categoria</label>
														</div>
													</div>
													<div className='col'>
														<a href='#!' onClick={_pushCat} className='btn waves-effect waves-light'>Agregar</a>
													</div>
												</div>
												{categorias.filter((c) =>c.categoria.toLowerCase().includes(search)).map((c,i) =>
												<a href='#!' key={i} onClick={() => _catSelect(c)} className='waves-effect waves-light btn grey darken-4' style={{borderRadius:20, textTransform:'none', fontSize:12, letterSpacing:'none'}}><i className='material-icons right'>add</i>{c.categoria}</a>
												)}
											</div>
										</div>
										}
									</div>
								</div>
									
						

							{
								data.id ?
								<div style={{height:'140px', width:'100%x', margin:0, padding:0,}}>
									<div style={{height:'56px', margin:0, padding:0, width:'100%',}}>
										<span style={{border: '4px dashed rgba(0,0,0,0.35)', borderRadius:20, height:100, display:'flex', alignItems:'center', justifyContent:'center', position:'relative', fontSize:30}}>
											<input onChange={_fileUpload} type='file' style={{cursor:'pointer',opacity:0,width:'100%', height:'100%', display:'block', position:'absolute'}} accept='*'/>
											Arrastra la imagen de tu producto.
										</span>
										<div style={{color:'red'}}>Nota: Puedes agregar más de una foto</div>
									</div>
								</div>
								:
								<div className='col m12 custom-file-container' data-upload-id='uploadId' style={{marginBottom:50}}>
									<label style={{display:'none'}}>Fotos<a href='#!' className='custom-file-container__image-clear' title='Limpiar' >&times;</a ></label>
									<label className='custom-file-container__custom-file'>
										<span style={{border: '4px dashed rgba(0,0,0,0.35)', borderRadius:20, height:100, display:'flex', alignItems:'center', justifyContent:'center', position:'relative', fontSize:30}}>
											<input onChange={(e)=>setFotos([...fotos,e.target.files[0]])} type='file' className='custom-file-container__custom-file__custom-file-input' style={{cursor:'pointer',opacity:0,width:'100%', height:'100%', display:'block', position:'absolute'}} accept='*' multiple/>
											Arrastra las imagenes de tu producto
										</span>
										<label style={{color:'red'}}>Puedes agregar más de una foto</label>
										<input type='hidden' name='MAX_FILE_SIZE' value='10485760' className='los-input' />
										<span className='custom-file-container__custom-file__custom-file-control' style={{display:'none'}}></span>
									</label>
									<div className='custom-file-container__image-preview'></div>
								</div>
							}
							<div className='row'>
								{data.imagenes.map((img,i)=>
									<div className='col s12 m4' key={i} style={{position:'relative', height:300, padding:'0', margin:0,}}>
										<img alt={data.codigo} className='materialboxed' data-caption={data.descripcion} src={img.url} 
										style={{maxWidth:'100%!important', height:250, margin:10, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.5)',objectFit:'cover'}} />
										<a href='#!' onClick={() =>_imgDestroya(img)} className='black-text' 
										style={{position:'absolute', top:5, right:30,
											right:'60px',
											backgroundColor:'#ffffff',
											borderRadius:'100%',
											border:'solid 1px #ededed',
											padding:'5px 4px 0 4px',}}>
											<i className="material-icons">clear</i>
										</a>
									</div>
								)}
							</div>	
						<div className="row" style={{padding:'20px'}}></div>
						<button className='btn' style={{backgroundColor:'#4966B1',color:'white',position:'absolute',right:20,bottom:20,height:50,width:150}}>Siguiente</button>
					</form>
				</div>
				<div className='' style={{position:'absolute',left:20,bottom:20,}}>
					<button className='btn'  onClick={(e)=>_return(e)}
						style={{backgroundColor:'#4966B1',color:'white',height:50,width:150}}>
						Anterior
					</button>
				</div>
			 
			</div>
		</div>
	);
};

export default StepFour;
