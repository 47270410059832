import Nav from '../../../components/Nav' 
import Departamentos from './Components/Departamentos'
import Footer from '../../../components/Footer'  
//import { useSearchParams } from 'next/navigation' //nextjs
import { useLocation } from 'react-router-dom'; //reactjs

const Index = () => {

	/**
	  * #ticket-001 routing actualmente se usa /deps/departamento?departamento_id={id}
	  * cambiar a /deps/{departamento_id}
	  * se desarrollo usando /deps/departamento?departamento_id={id} debido a bug en nextjs
	  * consultar y resolver este issue dependiendo si ya se soluciono el bug:
	  * https://github.com/vercel/next.js/issues/48022
	  *
	  */
	//nextjs
	//const searchParams = useSearchParams()
	//const departamento_id = searchParams.get('departamento_id')

	//reactjs
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search);
	const departamento_id = searchParams.get('departamento_id');

	return (
		<>
			<Nav />
			<Departamentos departamento_id={departamento_id}/>
			<Footer/>
		</>
	)
}
export default Index
