import React, { useContext } from 'react';
import axios from '../../infrastructure/axios';
import { Link, useNavigate, Outlet } from 'react-router-dom'
import { DataContext } from '../../contexts/DataContext'
import Nav from '../../components/Nav'
import Footer from '../../components/Footer'
import './layout.css'

/**
  * images
  */
import defaultUserIcon from './default-user.jpg'
import logoutImage from './logout.png'

const Perfil = () => {

	const navigate = useNavigate();
	const { user, setUser } = useContext(DataContext)
	const url = '/miperfil'

	const logout = () =>{
		axios.delete('/api/logout')
		.then(res => {
			setUser({})
			navigate('/')
		}).catch(e=>alert(e))
	}

	/**
	  * @param {File} img - img input select
	  */
	const _imgUpload = (img) => {
		const fData = new FormData();
		fData.append("img", img);
		axios.post('/api/imgperfil',fData)
		.then(r=>{
			const picture=document.querySelector('#src-picture')
			picture.src=`${r.data.img}#${new Date().getTime()}`
		})
		.catch(r=>alert(r))
	}

	return (
		<>
			<Nav />
			<div style={{height:"54px",background:"#FCFCFC",paddingLeft:38,paddingTop:18,fontSize:"16px",fontWeight:500}}>
				Mi cuenta
			</div>
			<div className="col s12 bg-perfil">
				<div className="container-medium margin-cont white padding-20 borde">
					<div className="perfil-header">
						<div className="row margin-left-0">
							<div className="col perfil-img-cont" style={{margin:0}}>
								<div className="perfil-picture" style={{position:'relative',height:"211px"}}>
									<img width='200' height='200' id='src-picture' alt='user' className="responsive-img perfil-img" src={user.img||defaultUserIcon} />
									<label style={{cursor:'pointer', position:'absolute',right:10,top:10}}>
										<i className="material-icons black-text small edit-perfil-icon">edit</i>
										<input type="file"  style={{display:'none'}} onChange={e=>_imgUpload(e.target.files[0])} accept="image/png, image/gif, image/jpeg"/>
									</label>
								</div>
							</div>
							<div className="col user-info-container">
								<div className="black-text">
									<p>Nickname: {user.name}</p>
									<p>Email: {user.email}</p>
									{user.telefono && 
									<p>Telefono:  {user.telefono}</p>
									}
									<Link style={{width:"100%"}} to={`${url}/datos`}>
									<i className="material-icons perfil-icon">create</i>
								</Link>
								</div>
							</div>
						</div>
					</div>
				</div>		
				<div className="container-medium white">	
					<div className="row padding-10 borde" style={{minHeight:"400px",margin:0}}>
						<div className="col s12 m3">
							<div className="col s12 perfil-list">
								<Link style={{width:"100%"}}  to="/pedidos">
									<div className="col s9">
									Mis pedidos
									{/*<Link to="/"> Mis direcciones </Link> */}
									</div> 
									<div className="col s3">
										<i className="material-icons">chevron_right</i>
									</div>
								</Link>
							</div>
							<div className="col s12 perfil-list">
								<Link style={{width:"100%"}}  to={`${url}/misdirecciones`}>
									<div className="col s9">
									Mis direcciones
									{/*<Link to="/"> Mis direcciones </Link> */}
									</div> 
									<div className="col s3">
										<i className="material-icons">chevron_right</i>
									</div>
								</Link>
							</div>
							<div className="col s12 perfil-list">
								<Link style={{width:"100%"}}  to={`${url}/datos`}>
									<div className="col s9">
									Mi perfil
									{/*<Link to="/"> Mis direcciones </Link> */}
									</div> 
									<div className="col s3">
										<i className="material-icons">chevron_right</i>
									</div>
								</Link>
							</div>
						</div>
						<div className="col s12 m9" style={{height:"100%"}}>
							<Outlet />
						</div>			   
					</div> 
				</div>
				<div className="container-medium white padding-10" style={{marginBottom:"50px"}}>
					<div className="row" style={{margin:0}}>
						<div onClick={logout} className="col s12 m4 logout-container padding-10">
							<img src={logoutImage} style={{height:"19px"}} alt="exit" />
							<span style={{paddingLeft:"10px"}}>
								Cerrar Sesión
							</span>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};



export default Perfil;
