import React, {useState, useEffect} from 'react';
import './pedido.css'
import axios from '../../../../../infrastructure/axios';
import Loader from '../../../../../components/Loader';
import { Link } from 'react-router-dom'


const Pedido = ({pedido_id}) => {

	const [pedido,setPedido] = useState(false)
	const [loader, setLoader] = useState(true)

	useEffect(()=>{
		axios.get(`/api/pedido/${pedido_id}`).then(r=>{
			setPedido(r.data)
		setLoader(false)
		}).catch(r=>alert(r))
	},[pedido_id])

	if(loader) return <Loader />

	return (
		<div>
			<div className='row m-p-0'>
				<div className='col s12 m-p-0 barra'>
				<Link to='/pedidos#orders-tab'><i className="material-icons">arrow_back</i> BACK</Link>
					<div>RESUME ORDER:</div>
				</div>
			</div>

			<div className="cont-result bg-container" style={{padding:'20px 40px'}}>
				<div>
					<div className="col s12 margin-top-50">
						<div className="pedido-subtitle">FOLLOW ORDER<span> ( RESUME ORDER ) </span></div>
						<div className="hr-subtitle"></div>
					</div>
					<div className='row' style={{marginBottom:"100px"}}>
						<div className="pad-10-0 container-tabs" >
							<div className="tabs-info">
								<div className="row m-p-0 bloque1">
									<div className='col s12 m3 m-p-0'>
										<div className="text negro"> PEDIDO-{String(pedido.id).padStart(4,0)} </div>
										<div className="text">
											Order Date:
											<div className="textos">{pedido.fecha}<span className="status"> ({pedido.status}) </span></div>
										</div>
									</div>
									<div className='col s12 m2 m-p-0 text'>
										TOTAL:
										<div className="textos">${pedido.total} USD</div>
									</div>
									<div className='col s12 m2 m-p-0 text'>
										SEND TO:
										<div className="destinatario">{pedido.envio[0].nombre}</div>
									</div>
								</div>
							</div>
							<div className="row white">
								<div className="col s12 m6 dir-container">
									<div className="pedido-subtitle">Datos de envío:</div>
									<div className="bloque">
										<div className="titulo-bloque"> Send To: {pedido.envio[0].nombre} </div>
										<div className="text-bloque"> 
											{pedido.envio[0].calle} #{pedido.envio[0].numExt} {pedido.envio[0].numInt?`Interior: ${pedido.envio[0].numInt}`:''}, {pedido.envio[0].colonia}, C.P. {pedido.envio[0].cp} {pedido.envio[0].ciudad},{pedido.envio[0].estado}.
										</div>
									</div>
									<div className="bloque">
										<div className="titulo-bloque">Number Phone:</div>
										<div className="text-bloque">{pedido.envio[0].telefono}</div>
									</div>
									<div className="bloque">
										<div className="titulo-bloque">Send Type</div>
										<div className="text-bloque">{pedido.envio[0].descripcion}</div>
										<div className="text-bloque">Price: ${pedido.envio[0].precio} USD</div>
									</div>
								</div>
								<div className="col s12 m6 dir-container margin-bottom-20" style={{position:"relative"}}>
									<div className="vertical-divider"></div>
									<div className="pedido-subtitle">Resume Order:</div>
									<div className="producto-bloque-container">
										{pedido.articulos.map((item,i)=>
											<div className="producto-bloque" key={i}>
												<div className="row">
													<div className="col s3 m3 l2" style={{height:"70px"}}>
														<img src={(item.urls.length > 0) ? item.urls[0]:'https://www.charlotteathleticclub.com/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png'} alt='' 
														style={{height:"70px",}} />
													</div>
													<div className="col s9 m9 l10">
														<div className="titulo">{item.nombre}</div>
														<div className="texto">Cantidad {item.cantidad}</div>
														<div className="precio">Total ${parseFloat(item.precio * item.cantidad).toFixed(2)} USD</div>
													</div>
												</div>
											</div>
										)}
									</div>
									<div className="pedido-totals">
										<div className="row">
											<div className="col s4 texto-bloque bold">
												<div>Subtotal:</div>
												<div>FedEx Exprés:</div>
												<div className="orange-brick margin-top-5">Total:</div>
											</div>
											<div className="col s8 texto-bloque bold">
												<div>${pedido.subtotal} USD</div>
												<div>${pedido.total_envio} USD</div>
												<div className="orange-brick margin-top-5">${pedido.total} USD</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col s12">
									<div className="divider"></div>
								</div>
								<div className="col s12">
								{/* //se oculta por incompatibilidad de paquetes en 1 pedido
									<div className="orange-brick bold margin-top-20 padding-0">Fecha de entrega estimada: {pedido.envio[0].entrega}</div>
								*/}
									<div className="text row" style={{padding:"20px 0"}}>
										{pedido.envio.map((envio, i)=>
										<div key={i} style={{borderBottom:'1px solid grey'}}>
										<div className='row'>
										{/* columna 1*/}
										<div className='col m2 font-12'>
											<div className="cont-icon cont-icon-activo"><i className="tiny material-icons m-p-0">local_shipping</i></div>Package-{i+1} Tracking Number: {envio.trackingNumber??'Collect'}
										</div>
										{/* columna 2*/}
										<div className='col m2 m-p-0 font-12'>
											<div className={(['Enviado','Entregado'].includes(pedido.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'}><i className="tiny material-icons m-p-0">check</i></div>Send
										</div>
										{/* columna 3*/}
										{(envio.status_envio?.CompletedTrackDetails?.TrackDetails?.Events)&&
										<div className='col m5 m-p-0 font-12'>
											<div className={(['Enviado','Entregado'].includes(pedido.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'}> <i className="tiny material-icons m-p-0">check</i></div>Package on the way
											{(['Enviado','Entregado'].includes(pedido.status))&&
											<div style={{marginTop:20}}>
											{envio.status_envio.CompletedTrackDetails?.TrackDetails.Events.length>0?envio.status_envio.CompletedTrackDetails?.TrackDetails.Events.map((e,i)=>
												<div key={i} className='row' style={{fontSize:16, margin:5}}>
													<div className='col'><i className="tiny material-icons" style={{background:'#4D4D4D', color:'white', borderRadius:50}}>check</i></div>
													<div className='col'>{new Date(e.Timestamp).toLocaleString()}</div>
													<div className='col s3'>{e.Address.City}</div>
													<div className='col'>{e.EventDescription}</div>
												</div>
											):
												<div className='row' style={{fontSize:16, margin:5}}>
													<div className='col'><i className="tiny material-icons" style={{background:'#4D4D4D', color:'white', borderRadius:50}}>check</i></div>
													<div className='col'>{new Date(envio.status_envio.CompletedTrackDetails?.TrackDetails.Events.Timestamp).toLocaleString()}</div>
													<div className='col s3'>{envio.status_envio.CompletedTrackDetails?.TrackDetails.Events.Address.City}</div>
													<div className='col'>{envio.status_envio.CompletedTrackDetails?.TrackDetails.Events.EventDescription}</div>
												</div>
											
											}
											</div>
											}
										</div>
										}

										{/* columna 3*/}
										<div className='col m2 m-p-0 font-12'>
											<div className={(['Entregado'].includes(pedido.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'}> <i className="tiny material-icons m-p-0">check</i></div>Package delivered
											{(['Entregado'].includes(pedido.status))&&
											<div style={{marginTop:20}}><i className="tiny material-icons" style={{background:'#4D4D4D', color:'white', borderRadius:50}}>check</i> {envio.status_envio?.CompletedTrackDetails?.TrackDetails?.DeliverySignatureName}</div>
											}
										</div>
										</div>
										</div>
										)} {/*pedido.envio.map fin*/}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Pedido;
