import React from 'react'
//import { useSearchParams } from 'next/navigation' //nextjs
import { useLocation } from 'react-router-dom'; //reactjs
import { Home } from './Components'


const Index = () => {

	//nextjs
	//const searchParams = useSearchParams()
	//const company_name = searchParams.get('name')

	//reactjs
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search);
	const company_name = searchParams.get('name');

	return(
		<>
			<Home company_name={company_name} />
		</>
	)
	
}

export default Index
