import Nav from '../../components/Nav'
import { Outlet } from 'react-router-dom'; //reactjs

const Index = () => {

	return(
		<div style={{background:'#F5F7FA'}}>
			<Nav />
			<div style={{background:'#F5F7FA'}}>
				<Outlet />
			</div>
		</div>
	)
	
}

export default Index
