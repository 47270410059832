import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom'
import './producto.css'
import axios from '../../../../infrastructure/axios'
import M from 'materialize-css'
import { DataContext } from '../../../../contexts/DataContext'
import Vistos from '../../../../components/Vistos';
import Loader from '../../../../components/Loader';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { RWebShare } from "react-web-share";
import ModalCotizar from '../../../../components/Modals/ModalCotizar'
/**
  * images
  */
import assurance from './assurance.png'
import check from './check.png'
import verified from './verified.png'
import audited_img from './audited.png'
import background_proveedor from './card_background_proveedor.png'
import diamond_img from './diamond.png'
import not_found_img from '../../../../../Utils/Assets/not-found.png'



/**
  * la plantilla se comporta diferente cuando un producto tiene solo Colores o cuando tiene un atributo diferente,
  * existen 3 estados Color, Atributo, y Padre
  * cuando un producto es tipo Color en la tabla Catalogo existe un attribute_type=='Color' y cuando tiene un dato diferente es Atributo, cuando es null o No Attribute es Padre
  *
  * Nota importante: fue solicitada demasiada logica personalizada para este modulo, tener cuidado!!!
  *
  */

const Producto = ({codigo, categoria_id}) => {

	const [producto, setProducto] = useState({imagenes:[], purchase_info:{minimum_order_quantity:[]}})
	const [imgSelect, setImgSelect] = useState({})
	const {carrito, setCarrito, user, vistos, setVistos} = useContext(DataContext)
	const [cantidad, setCantidad] = useState(1)
	const [cantidadProveedor, setCantidadProveedor] = useState(1)
	const navigate = useNavigate();
	const [loader, setLoader] = useState(true);
	const [interfaceSelectorAttribute, setInterfaceSelectorAttribute] = useState({color:'', attribute_value:''})

	useEffect(()=>{

		M.Modal.init(document.querySelectorAll('.modal'), {})
		setTimeout(()=>{
			M.Materialbox.init(document.querySelectorAll('.materialboxed'),{})
		},1000)

		if(user.name !== undefined){
			/* Guarda los vistos */
			axios.post('/api/visto', {codigo})
			.then(r=>console.log('visto guardado'))
			.catch(r=>alert(r))
		}
	},[user.name])

	useEffect(() => {
		setLoader(true)
		axios.get(`/api/reseller/producto/${codigo}`)
		.then(r=>{

			var producto = r.data
			if(producto.attribute_type===null||producto.attribute_type==='')
			producto = ({...producto, minimo_compra: producto.stock<producto.minimo_compra?producto.stock:producto.minimo_compra})

			//reasignacion de precio
			//busca el moq correspondiente de 4 configurados
			//ademas busca el precio correspondiente al minimo_compra
			const moq = producto.purchase_info.minimum_order_quantity.find(moq=>producto.minimo_compra>=parseInt(moq.rango.split('-')[0]) && producto.minimo_compra <=parseInt(moq.rango.split('-')[1]))

			setProducto(producto)
			setCantidad(producto.minimo_compra)
			setImgSelect(producto.imagenes.length?r.data.imagenes[0]:{});
			setLoader(false)
			M.FormSelect.init(document.querySelectorAll('select'), {});


			/**
			  * AutoSeleccion del producto
			  * descripcion el primer producto se debe seleccionar ya sea de un atributo o un color
			  */
			if(producto.atributos?.length){
				let ProductoAtributo = producto.atributos[0]
				setProducto({...producto, precio:parseInt(moq.price), codigo:ProductoAtributo.item_no, barcode: ProductoAtributo.barcode, stock:parseInt(ProductoAtributo.stock_proveedor), stock_proveedor:parseInt(ProductoAtributo.stock_proveedor), minimo_compra:ProductoAtributo.minimo_compra, color:ProductoAtributo.color})

			if(producto.attribute_type==='Color'){
				setInterfaceSelectorAttribute({color:'', attribute_value: ProductoAtributo.attribute_value})
			}
			if(producto.attribute_type!=='Color' && producto.attribute_type!==null){
				setInterfaceSelectorAttribute({attribute_value: ProductoAtributo.attribute_value, color: ProductoAtributo.color})
			}
				setCantidad(ProductoAtributo.minimo_compra)
				console.log('producto autoseleccionado')
			}


			/**
			  * Materialboxed and swiper compatibility
			  */
			setTimeout(()=>{
				M.Materialbox.init(document.querySelectorAll('.materialboxed'), {
					onOpenStart: ()=>{
						document.querySelector('.swiper-button-next').style.zIndex = '0';
						document.querySelector('.swiper-button-prev').style.zIndex = '0';
					},
					onCloseStart: ()=>{
						document.querySelector('.swiper-button-next').style.zIndex = '10';
						document.querySelector('.swiper-button-prev').style.zIndex = '10';
					}
				});
			},1000)

			//style for select cant.:
			const selec = document.querySelector('.select-wrapper')
			selec.style.borderRadius='10px';
			selec.style.paddingLeft='10px';
			selec.style.boxShadow='0 4px 8px 0 rgba(0,0,0,0.2)';

			const selecI = document.querySelectorAll('.select-wrapper ul li span')
			for (var i = 0, len = selecI.length; i < len; i++)
				selecI[i].style.color='black'

			/**
			  * Agrega el producto a Vistos en la cache o StorageLocal
			  *
			  **/
			if(vistos.find(v=>v.codigo===codigo) === undefined){
				setVistos([producto,...vistos.slice(0,24)])
			}

			setTimeout(()=>{
				M.Tabs.init(document.querySelectorAll('.tabs'), {});
			},1000)
		})
		.catch(r=>console.log(r))


	},[codigo])

	/**
	  * @const _agregarAlCarrito - agrega un producto al carrito
	  * @return {void}
	  */
	const _agregarAlCarrito = () => {
		const obj = carrito.find((c)=>c.codigo===producto.codigo)
		const newCantidad =  typeof(obj) === 'undefined' ? cantidad:obj.cantidad+cantidad

		if(newCantidad > producto.stock) return alert("Lo sentimos no puedes agregar mas productos de este tipo, haz superado el stock")
		if(producto.stock === 0) return alert("Lo sentimos, por el momento no hay stock disponible")
		setCarrito([...carrito.filter(c=>!c.codigo.includes(producto.codigo)), {...producto, cantidad:newCantidad}])
		M.toast({html: 'Carrito Actualizado!'})
	}

	/**
	  * @const _comprar -
	  * @return {void}
	  */
	const _comprar = () => {
		setCarrito([{...producto, cantidad, selected:true}])
		navigate('/micarrito/direccion')
	}


	useEffect(()=>{
		if(producto.purchase_info.minimum_order_quantity.length>0){
			// de los 4 moqs configurados busca el correpondiente; en base a la cantitad establecida; tambien actualiza el precio dependiendo del rango y de cantidad
			const moq = producto.purchase_info.minimum_order_quantity.find(moq=>cantidad>=parseInt(moq.rango.split('-')[0]) && cantidad <=parseInt(moq.rango.split('-')[1]))
			if(moq){
				setProducto({...producto, precio:parseInt(moq.price)})
			}
		}
	},[cantidad, producto.purchase_info.minimum_order_quantity])

	useEffect(()=>{
		console.log(interfaceSelectorAttribute)
	},[interfaceSelectorAttribute])

	/**
	  * @param clickTypeAttribute [String] Color | Atributo
	  *
	  */
	const _attributeClick = (atributo, clickTypeAttribute) => {
		//si se hizo click en un color se setea el color
		let ProductoAtributo

		/**
		  *se ejecuta solo cuando dan click en un color
		  */
		if(clickTypeAttribute==='Color'){
			setInterfaceSelectorAttribute({...interfaceSelectorAttribute, color: atributo.color})
			ProductoAtributo = producto.atributos.find(p=>p.color===atributo.color&&p.attribute_value===interfaceSelectorAttribute.attribute_value)
			if(!ProductoAtributo){
				//no se encontro el attribute_value buscar el primero
				const aux = producto.atributos.find(p=>p.color===atributo.color)
				setInterfaceSelectorAttribute({color: atributo.color, attribute_value: aux.attribute_value})
			}
		}

		/**
		  * se ejecuta solo cuando dan click en un atributo
		  */
		if(clickTypeAttribute==='Atributo'){

			setInterfaceSelectorAttribute({...interfaceSelectorAttribute, attribute_value: atributo.attribute_value})

			ProductoAtributo = (producto.attribute_type==='Color')?
			(producto.atributos.find(p=>p.color===atributo.color)):
				atributo.color==null?
				producto.atributos.find(p=>p.item_no===atributo.item_no):
					(producto.atributos.find(p=>p.color===interfaceSelectorAttribute.color&&p.attribute_value===atributo.attribute_value))

			if(!ProductoAtributo||atributo.color===null){
				//no se encontro el attribute_value buscar el primero
				const aux = producto.atributos.find(p=>p.attribute_value===atributo.attribute_value)
				setInterfaceSelectorAttribute({color: aux.color, attribute_value: atributo.attribute_value})
			}
			console.log(ProductoAtributo)
			console.log(producto.atributos)
		}

		//const ProductoAtributo = producto.attribute_type==='Color'?producto.atributos.find(p=>p.color===atributo.color):producto.atributos.find(p=>p.color===atributo.color&&p.attribute_value===atributo.attribute_value)

		if(ProductoAtributo){
			/*se encontro atributo*/
			setProducto({...producto, codigo:ProductoAtributo.item_no, barcode: ProductoAtributo.barcode, stock:parseInt(ProductoAtributo.stock_proveedor), stock_proveedor:parseInt(ProductoAtributo.stock_proveedor), minimo_compra:ProductoAtributo.minimo_compra, color:ProductoAtributo.color})
			setCantidad(ProductoAtributo.minimo_compra)
			if(ProductoAtributo.imgs){
				setImgSelect({url:ProductoAtributo.imgs[0]})	
			}
		}else{
			setProducto({...producto, stock:0})
		}

		
	}
	
	useEffect(()=>{
		M.FormSelect.init(document.querySelectorAll('select'), {});
	},[producto.stock, producto.minimo_compra])

	return (
		<div style={{minHeight:'80vh'}}>
		<ModalCotizar producto={{...producto, cantidadProveedor}} />
			
		{loader?
		<Loader/>:
		<>
		<div className='row hide-on-640' style={{marginBottom:'0px',}}>
			<div className='col s12 brad' style={{width:'100vw'}}>
				<div className="row m-p-0" style={{alignItems:'center', display:'flex'}}>
					<div>
						{/* #ticket-001 */}
						<Link className="" to={`/deps/departamento?departamento_id=${producto.departamento_id}`}> {producto.departamento} &nbsp;&gt;&nbsp; </Link>
					</div>
					<div style={{cursor:'default'}}>
						{categoria_id?producto.categorias.filter(c=>c.id===categoria_id).map((c,i)=>
							<Link key={i} className="" to={`/cat?categoria_id=${categoria_id}&departamento_id=${producto.departamento_id}`} style={{cursor:'pointer'}}> {c.categoria}hola &nbsp;&gt;&nbsp; </Link>)
						:
						<>{producto.categorias.map((c,i)=>
							<Link key={i} className="" to={`/cat?categoria_id=${c.id}&departamento_id=${producto.departamento_id}`} style={{cursor:'pointer'}}> {c.categoria} &nbsp;&gt;&nbsp; </Link>
						)}
						</>
						}
					</div>
					<div>{producto.nombre}</div>
				</div>
			</div>
		</div>
		<div>
			<div className='row container-detalle'>
				<div className='row m-0 contain-detail'>
					<div className='col s12 m6 row m-p-0 hide-on-640'>

					<div className='row'>
						{/*imagenes laterales*/}
						<div className='col s12 m1 m-p-0 es-pc'>
							<div className='col m-p-0 es-pc'>
							{producto.imagenes.map((img, i)=>
								<div key={i} onClick={()=>setImgSelect(img)} className="min-img-lateral">
									<img alt='' style={{objectFit:"scale-down", border:'1px solid #e6e7eb'}} src={img.url}/>
								</div>
							)}
							</div>
						</div>
						<div className='col s12 m10'>
							{/*imagen central seleccionada*/}
							<div className='m-p-0 center-y-x cont-img-prod' style={{}}>
								<img className='materialboxed' style={{objectFit:"contain", height:"100%", width:"100%", border:'1px solid #e6e7eb'}} data-caption={producto.nombre} alt='' src={imgSelect.url?imgSelect.url:'https://www.charlotteathleticclub.com/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png'}/>
							</div>
						</div>

					</div>
					</div>
					<div className='col s12 m6 m-p-0'>
						<div className='row'>
							<div className='col' style={{width:'100%', borderBottom:'1px solid #e6e7eb'}}>
								<h4 className='nombre-prod'>{producto.nombre}</h4>
								<i className='material-icons orange-text'>star</i>
								<i className='material-icons orange-text'>star</i>
								<i className='material-icons orange-text'>star</i>
								<i className='material-icons orange-text'>star</i>
								<i className='material-icons orange-text'>star</i>
							</div>
						</div>
						<div className='row'>
							<div className='col s12 m6 row m-p-0 show-on-640' style={{display:'none'}}>
								<div className='col s12 m10 product-margin-responsive'>
									<div style={{position:'absolute',right:10, zIndex:2}}>
										<RWebShare data={{ text: "Check this out!", url: window.location.href, title: "Compartir"}} onClick={() => console.log("shared successfully!")} >
											<div style={{background:'white', width:50,height:50, borderRadius:50, display:'flex',alignItems:'center', justifyContent:'center', border:'1px solid grey'}}>
												<i className="material-icons">share</i>
											</div>
										</RWebShare>
									</div>
									<div className='m-p-0 center-y-x'>
										<Swiper modules={[Pagination]} loop={false} slidesPerView={1} spaceBetween={30} pagination={{ clickable: true }}>
											{producto.imagenes.map((p,i)=>
											<SwiperSlide id="carusel-elementos" key={i}>
												<img style={{objectFit:"scale-down", height:"100%", width:"100%"}} data-caption={p.nombre} alt='' src={p.url}/>
											</SwiperSlide>
											)}
										</Swiper>
									</div>
									<div style={{position:'relative'}}>
										<h3>${producto.precio}<label style={{position:'absolute', top:-10, color:'black', fontSize:18}}>00</label></h3>
									</div>
								</div>
							</div>
						</div>

						{/**
						   *
						   * BUY MEXICO
						   *
						   **/}

						<div>
							<div style={{fontSize:16, fontWeight:'bold'}}>Buy from México</div>
							{(producto.atributos.length===0||producto.atributos.some(a=>a.item_no===producto.codigo))&&
							<div>
								<p> <b>Stock:</b> {producto.stock} </p>
								{producto.stock===0&&<div style={{color:'red'}}>Producto no disponible</div>}
							</div>
						}
						</div>

						<div className='col hide-on-640' style={{width:'100%', borderBottom:'1px solid rgb(230, 231, 235)', paddingBottom:10}}>
							<div className="row m-p-0" style={{background:'rgb(245, 245, 245)', borderRadius:'12px'}}>
								<div className="row" style={{margin:0, padding:'10px 15px 4px 15px'}}>
									<div className='col m3' style={{padding:'4px'}}></div>
									<div className='col m6' style={{padding:'4px'}}>
										<div className="row m-p-0">
											<div className='col m3' style={{padding:'10px 5px 5px 5px', textAlign:'center', fontWeight:'500', lineHeight:'1'}}>Minimum number of pieces</div>
											<div className='col m4' style={{padding:'10px 5px 5px 5px', textAlign:'center', fontWeight:'500', lineHeight:'1'}}>Quantity to buy</div>
											<div className='col m5' style={{padding:'10px 5px 5px 5px', textAlign:'center', fontWeight:'500', lineHeight:'1'}}>Price</div>
										</div>
									</div>
									<div className='col m3' style={{padding:4}}>
										<a href='#!' onClick={_agregarAlCarrito} style={{width:'90%!important', margin:'3px'}} className={'btn waves-effect waves-light btn-add black'+(producto.stock>0?'':' disabled')}>Add to cart</a>
									</div>
						
								</div>
								<div className="row" style={{borderTop:'solid 1px #EBEBEB', margin:0, padding:'10px 15px'}}>
									<div className='col m3' style={{padding:'5px', textAlign:'center', fontWeight:'500', lineHeight:'1'}}>
										Cantidad
										<p className="m-p-0" style={{color:'#999999',fontSize:'12px', fontWeight:'500'}}>(Paquetería normal)</p>
									</div>
									<div className='col m6' style={{padding:'4px'}}>
										<div className="row m-p-0">
											<div className='col m3' style={{textAlign:'center', padding:'8px 5px 5px 5px'}}>{producto.minimo_compra}</div>
											<div className='col m4' style={{textAlign:'center', padding:'5px'}}>
												<div className="row m-p-0" style={{background:'#ffffff', borderRadius:'6px', height:'30px',}}>
													<div className='col m4 m-p-0' style={{textAlign:'center', padding:'5px'}}>
														<div onClick={(e)=>setCantidad((cantidad > producto.minimo_compra)?cantidad-1 : cantidad)} style={{fontWeight:'800', fontSize:'20px', cursor:'pointer'}}> - </div>
													</div>
													<div className='col m4' style={{textAlign:'center', padding:'5px 5px 5px 5px'}}>
														{cantidad}
													</div>
													<div className='col m4 m-p-0' style={{textAlign:'center', padding:'5px'}}>
														<div onClick={(e)=>setCantidad((cantidad < producto.stock)?cantidad+1 : cantidad)} style={{fontWeight:'800', fontSize:'20px', cursor:'pointer'}}> + </div>
													</div>
												</div>
											</div>
											<div className='col m5 m-p-0'>
												<div className='nfocolor-text prod-price' style={{fontSize:'14px', fontWeight:'700', marginTop:'10px',width:'100%', textAlign:'center'}}>${producto.precio * cantidad} <span>USD</span></div>
											</div>
										</div>
									</div>
									<div className='col m3' style={{padding:'4px'}}>
										<a href='#!' onClick={_comprar} style={{width:'90%', margin:'3px'}} className={'btn black waves-effect waves-light btn-comprar '+(producto.stock>0?'':'disabled')} >Buy</a>
									</div>
								</div>
							</div>
						</div>
						{/**
						   *
						   * BUY ORIGIN PRODUCT
						   *
						   **/}


						<div className='col hide-on-640' style={{width:'100%', borderBottom:'1px solid rgb(230, 231, 235)', paddingBottom:10, marginTop:50}}>
						<div style={{position:'relative', left:-12}}>
							<div style={{fontSize:16, fontWeight:'bold'}}>REQUEST SPECIAL QUOTE</div>
						</div>
							<div className="row m-p-0" style={{background:'rgb(245, 245, 245)', borderRadius:'12px'}}>
								<div className="row" style={{margin:0, padding:'10px 15px 4px 15px'}}>
									<div className='col m3' style={{padding:'4px'}}></div>
									<div className='col m6' style={{padding:'4px'}}>
										<div className="row m-p-0">
											<div className='col m3' style={{padding:'10px 5px 5px 5px', textAlign:'center', fontWeight:'500', lineHeight:'1'}}>Minimum number of pieces</div>
											<div className='col m4' style={{padding:'10px 5px 5px 5px', textAlign:'center', fontWeight:'500', lineHeight:'1'}}>Amount to quote</div>
											<div className='col m5' style={{padding:'10px 5px 5px 5px', textAlign:'center', fontWeight:'500', lineHeight:'1'}}>Price</div>
										</div>
									</div>
						
								</div>
								<div className="row" style={{borderTop:'solid 1px #EBEBEB', margin:0, padding:'10px 15px'}}>
									<div className='col m3' style={{padding:'5px', textAlign:'center', fontWeight:'500', lineHeight:'1'}}>
										Cantidad
										<p className="m-p-0" style={{color:'#999999',fontSize:'12px', fontWeight:'500'}}>(Paquetería normal)</p>
									</div>
									<div className='col m6' style={{padding:'4px'}}>
										<div className="row m-p-0">
											<div className='col m3' style={{textAlign:'center', padding:'8px 5px 5px 5px'}}>{producto.minimo_compra}</div>
											<div className='col m4' style={{textAlign:'center', padding:'5px'}}>
												<div className="row m-p-0" style={{background:'#ffffff', borderRadius:'6px', height:'30px',}}>
													<div className='col m4 m-p-0' style={{textAlign:'center', padding:'5px'}}>
														<div onClick={(e)=>setCantidadProveedor((cantidadProveedor > 0)?cantidadProveedor-1 : cantidadProveedor)} style={{fontWeight:'800', fontSize:'20px', cursor:'pointer'}}> - </div>
													</div>
													<div className='col m4' style={{textAlign:'center', padding:'5px 5px 5px 5px'}}>
														{cantidadProveedor}
													</div>
													<div className='col m4 m-p-0' style={{textAlign:'center', padding:'5px'}}>
														<div onClick={(e)=>setCantidadProveedor(cantidadProveedor+1)} style={{fontWeight:'800', fontSize:'20px', cursor:'pointer'}}> + </div>
													</div>
												</div>
											</div>
											<div className='col m5 m-p-0'>
												<div className='nfocolor-text prod-price' style={{fontSize:'14px', fontWeight:'700', marginTop:'10px',width:'100%', textAlign:'center'}}>${producto.precio * cantidadProveedor} <span>USD</span></div>
											</div>
										</div>
									</div>
									<div className='col m3' style={{padding:'4px'}}>
										<a href='#modal-quote' style={{width:'90%', margin:'3px'}} className={'btn black waves-effect waves-light btn-comprar modal-trigger'} >Quote</a>
									</div>
								</div>
							</div>
						</div>


						<div className='col s12 show-on-640' style={{display:'none'}}>
							{producto.stock>0&&
							<div style={{color:'green'}}>Disponible</div>
							}
							<div style={{width:100}}>
								<select onChange={(e)=>setCantidad(e.target.value)} value={cantidad}>
									{Array(producto.stock>producto.minimo_compra?producto.stock-producto.minimo_compra+1:producto.stock).fill(null).map((e,i)=>
									<option key={i} value={producto.stock>producto.minimo_compra?i+producto.minimo_compra:i+1}>Cant.: {producto.stock>producto.minimo_compra?i+producto.minimo_compra:i+1}</option>
									)}
								</select>
							</div>
							<a href='#!' onClick={_comprar} style={{margin:'3px', background:'#FFA617', display:'block', borderRadius:15}} className={'btn waves-effect waves-light black-text'+(producto.stock>0?'':' disabled')} >Comprar ya</a>
							<a href='#!' onClick={_agregarAlCarrito} style={{margin:'3px', background:'#FED90B',display:'block', borderRadius:15}} className={'btn waves-effect waves-light btn-addi black-text'+(producto.stock>0?'':' disabled')}>Agregar al Carrito</a>
							<a href='#!' style={{display:'flex', alignItems:'center', margin:'20px 0px 20px 0px'}}><i className="material-icons grey-text">lock</i>Transacción segura</a>
							<div style={{marginTop:10}}>
								<div className='row' style={{marginBottom:0}}>
									<div className='col s6 grey-text'>Envío desde</div>
									<div className='col s6'>México</div>
								</div>
								<div className='row'>
									<div className='col s6 grey-text'>Vendido por</div>
									<div className='col s6 store-name'></div>
								</div>
							</div>
						</div>
						<div className='col s12' style={{borderBottom:'1px solid rgb(230, 231, 235)', paddingBottom:10}}>
							<p style={{lineHeight:'1.2', textAlign:'justify'}}>
								<b>About this product: </b><br />
								{producto.descripcion?producto.descripcion:'Sin descripción'}
							</p>
						</div>
						{(producto.attribute_type!==null&&producto.attribute_type!=='')&&
						<>
						{/*Colores*/}
						{(producto.attribute_type!=='Color'&&producto.atributos.filter((obj, index, self)=>index === self.findIndex((o)=>o.color===obj.color)&&obj.color!==null).length>0)&&
						<div className='col s12' style={{borderBottom:'1px solid rgb(230, 231, 235)', paddingBottom:10}}>
							<div className='row' style={{display:'flex', alignItems:'center'}}>
								<div className='col s3' style={{color:'grey'}}>
									Colors:
								</div>
								<div className='col s9'>
									<div style={{display:'flex'}}>
									{/*el primer filtro elimina duplicados de color*/}
									{producto.atributos.filter((obj, index, self)=>index===self.findIndex((o)=>o.color===obj.color)&&obj.color).map(atributo=>
										<div key={atributo.item_no} onClick={()=>_attributeClick(atributo, 'Color')} style={{width:interfaceSelectorAttribute.color===atributo.color?70:60, height:interfaceSelectorAttribute.color===atributo.color?70:60, margin:10, boxShadow:interfaceSelectorAttribute.color===atributo.color?'0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)':'',border: interfaceSelectorAttribute.color===atributo.color?'2px solid black':'1px solid #e6e7eb', cursor:'pointer', background:producto.atributos.filter(a=>interfaceSelectorAttribute.attribute_value===a.attribute_value).map(a=>a.color).includes(atributo.color)||interfaceSelectorAttribute.attribute_value===''?atributo.color:'#DDDDDD', color:producto.atributos.filter(a=>interfaceSelectorAttribute.attribute_value===a.attribute_value).map(a=>a.color).includes(atributo.color)||interfaceSelectorAttribute.attribute_value===''?atributo.color:'#DDDDDD'}}>
										<div style={{background:atributo.imgs?.length>0?'white':'rgba(255,255,255,0.4)', padding:5, height:'100%'}}>
											{atributo.imgs&&
											<img alt='attribute_image' src={atributo.imgs[0]} style={{width:'100%', objectFit:'cover'}}/>
											}
											<div style={atributo.imgs?{textAlign:'center'}:{display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>
												{atributo.color}
											</div>
										</div>
										</div>
									)}
									</div>
								</div>
							</div>
						</div>
						}
						{/*Atributos*/}
						<div className='col s12' style={{borderBottom:'1px solid rgb(230, 231, 235)', paddingBottom:10, marginBottom:10}}>
							<div className='row' style={{display:'flex', alignItems:'center'}}>
								<div className='col s3' style={{color:'grey'}}>
									{producto.attribute_type}:
								</div>
								<div className='col s9'>
									<div style={{display:'flex'}}>
									{/*el primer filtro elimina duplicados de attribute_value*/}
									{producto.atributos.filter((obj, index, self)=>index === self.findIndex((o)=>o.attribute_value===obj.attribute_value)).map(atributo=>
										<div className='atributo-inactive' key={atributo.item_no} onClick={()=>_attributeClick(atributo, 'Atributo')} style={{width:interfaceSelectorAttribute.attribute_value===atributo.attribute_value?70:60, height:interfaceSelectorAttribute.attribute_value===atributo.attribute_value?70:60, margin:10, boxShadow:interfaceSelectorAttribute.attribute_value===atributo.attribute_value?'0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)':'',border: interfaceSelectorAttribute.attribute_value===atributo.attribute_value?'2px solid black':'1px solid #e6e7eb', cursor:'pointer', background:producto.atributos.filter(a=>interfaceSelectorAttribute.color===a.color).map(a=>a.attribute_value).includes(atributo.attribute_value)||interfaceSelectorAttribute.color===''?(producto.attribute_type==='Color'?atributo.color:'white'):'#DDDDDD', color:producto.atributos.filter(a=>interfaceSelectorAttribute.color===a.color).map(a=>a.attribute_value).includes(atributo.attribute_value)||interfaceSelectorAttribute.color===''?'black':'grey'}}>
										<div style={{padding:5, height:'100%', background:'rgba(255,255,255,0.4)'}}>
											{atributo.imgs&&
											<img alt='attribute_image' src={atributo.imgs[0]} style={{width:'100%', objectFit:'cover'}}/>
											}
											<div style={atributo.imgs?{textAlign:'center'}:{display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>
												{atributo.attribute_value}
											</div>
										</div>
										</div>
									)}
									</div>
								</div>
							</div>
						</div>
						</>
						}
						<div className='col s12' style={{display:'flex', alignItems:'center', borderBottom:'1px solid rgb(230, 231, 235)'}}>
							<div className='row'>
								<div className='col' style={{color:'grey'}}>
									Lead time:
								</div>
								<div className='col s9'>
									<table>
										<tbody>
											<tr>
												<th></th>
												{producto.purchase_info.minimum_order_quantity.filter(moq=>moq.rango.replace(/[\s-]/g, "")).map((moq,i)=>
												<td key={i} style={{border: '1px solid #e6e7eb', fontWeight:'bold'}}>MOQ{i+1}</td>
												)}
											</tr>
											<tr>
												<th style={{border: '1px solid #e6e7eb'}}>Quantity</th>
												{producto.purchase_info.minimum_order_quantity.filter(moq=>moq.rango.replace(/[\s-]/g, "")).map((moq,i)=>
													<td key={i} style={{border: '1px solid #e6e7eb'}}>{moq.rango}</td>
												)}
											</tr>
											<tr>
												<th style={{border: '1px solid #e6e7eb'}}>Time(days)</th>
												{producto.purchase_info.minimum_order_quantity.filter(moq=>moq.rango.replace(/[\s-]/g, "")).map((moq,i)=>
													<td key={i} style={{border: '1px solid #e6e7eb'}}>{moq.leadTimeDays}</td>
												)}
											</tr>
											<tr>
												<th style={{border: '1px solid #e6e7eb'}}>Price</th>
												{producto.purchase_info.minimum_order_quantity.filter(moq=>moq.rango.replace(/[\s-]/g, "")).map((moq,i)=>
													<td key={i} style={{border: '1px solid #e6e7eb'}}>${moq.price}</td>
												)}
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						{producto.purchase_info.customize.length>0&&
						<div className='col s12' style={{borderBottom:'1px solid rgb(230, 231, 235)', margin:10}}>
							<div className='row' style={{display:'flex', alignItems:'center'}}>
								<div className='col' style={{color:'grey'}}>
									Customization:
								</div>
								<div className='col s9'>
									{producto.purchase_info.customize.map((custom, i)=>
										<div key={i}>Customized {custom.value} <span style={{color:'grey'}}>(Min. order {producto.purchase_info.minimum_order_quantity[1]?.rango.split('-')[0]} pieces)</span></div>
									)}
								</div>
							</div>
						</div>
						}
						{(producto.materials!==null&&producto.materials!=='')&&
						<div className='col s12' style={{borderBottom:'1px solid rgb(230, 231, 235)', margin:10}}>
							<div className='row' style={{display:'flex', alignItems:'center'}}>
								<div className='col' style={{color:'grey'}}>
									Materials:
								</div>
								<div className='col s9'>
									{producto.materials}
								</div>
							</div>
						</div>
						}
						{producto.purchase_info.protections.length>0&&
						<div className='col s12' style={{borderBottom:'1px solid rgb(230, 231, 235)'}}>
							<div className='row' style={{display:'flex', alignItems:'center'}}>
								<div className='col' style={{color:'grey'}}>
									Protections:
								</div>
								<div className='col s9'>
									<div style={{display:'flex', alignItems:'center', color:'grey'}}>
										<img alt='assurance' src={assurance} width='100' height='20'/>
										<div style={{margin:7}}>
											<span className='store-name'>Protects your </span>
										</div>
										orders
									</div>
									{producto.purchase_info.protections.map((custom,i)=>
										<div key={i} style={{display:'flex'}}><img alt='check' width='20' height='20' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/check1.png' />{custom.value}</div>
									)}
								</div>
							</div>
						</div>
						}
					</div>
				</div>
				<div className='col 12' style={{width:'100%'}}>
					<div className="row card-panel">
						<div className="col s12">
							<ul className="tabs">
								<li className="tab col s3"><a className='active' href="#product-details">Product details</a></li>
							</ul>
						</div>
						<div id="product-details" className="col s12">
							<div style={{minHeight:531, padding:30, marginTop:'10px'}}>
								<div style={{fontSize:28, fontWeight:'bold', marginTop:34}}>
									General Description
								</div>

								<div className='row' style={{marginTop:13, borderTop:'1px solid #e6e7eb', borderBottom:'1px solid #e6e7eb'}}>
									<div className='col s12'>
										<div className='row'>
											<div className='col s3'>Model Númber:</div>
											<div className='col s9'>{producto.model}</div>
										</div>
										<div className='row'>
											<div className='col s3'>Sample:</div>
											<div className='col s9'>{producto.purchase_info.sample}</div>
										</div>
										<div className='row'>
											<div className='col s3'>Payment terms:</div>
											<div className='col s9'>{producto.purchase_info.payment_options.map(a=>a.value).join(', ')}</div>
										</div>
										<div className='row'>
											<div className='col s3'>Delivery time:</div>
											<div className='col s9'>{producto.purchase_info.minimum_order_quantity[0].leadTimeDays.split('-')[0]}-{producto.purchase_info.minimum_order_quantity[3].leadTimeDays.split('-')[1]} days</div>
										</div>

										<div className='row'>
											<div className='col s3'>Specifications:</div>
											<div className='col s9'>
												<div style={{fontSize:'14px'}} dangerouslySetInnerHTML={{__html: producto.technical_data}} />
											</div>
										</div>
										<div className='row'>
											<div className='col s3'>Origin:</div>
											<div className='col s9'>{producto.collection_info_origin}</div>
										</div>
										<div className='row'>
											<div className='col s3'>MOQ:</div>
											<div className='col s9'>{producto.purchase_info.minimum_order_quantity[0].rango.split('-')[0]}-{producto.purchase_info.minimum_order_quantity[3].rango.split('-')[1]}</div>
										</div>
										<div className='row'>
											<div className='col s3'>Material:</div>
											<div className='col s9'>{producto.materials}</div>
										</div>
										<div className='row'>
											<div className='col s3'>Commercial brand:</div>
											<div className='col s9'>{producto.features}</div>
										</div>
										<div className='row'>
											<div className='col s3'>Production Capacity:</div>
											<div className='col s9'>{producto.purchase_info.month_capacity}</div>
										</div>
										<div className='row'>
											<div className='col s3'>Special Use:</div>
											<div className='col s9' style={{wordWrap: 'break-word'}}>
												<p> {producto.use} </p>
											</div>
										</div>
										<div className='row'>
											<div className='col s3'>Packaging details:</div>
											<div className='col s9'>{producto.piece_size.split('X').join('mm X')} mm</div>
										</div>
										<div className='row'>
											<div className='col s3'>Near port:</div>
											<div className='col s9'>{producto.collection_info_near_port}</div>
										</div>
										<div className='row'>
											<div className='col s3'>HS code:</div>
											<div className='col s9'>{producto.collection_info_hs_code}</div>
										</div>
									</div>

								</div>

								<div className='row' style={{marginTop:13, borderBottom:'1px solid #e6e7eb'}}>
									<div style={{fontSize:28, fontWeight:'bold', marginTop:34}}>Custom Description</div>
										<div style={{fontSize:'16px', marginTop:13}}>
										<div style={{fontSize:'14px'}} dangerouslySetInnerHTML={{__html: producto.descripcion_general}} />
									</div>
								</div>
							</div>
							{/* esta informacion ya esta cargada arriba queda oculto por si se necesita una seccion solo para dicha informacion Specifications(Technical data)
							<div style={{minHeight:531, padding:30, marginTop:'10px'}}>
								<div style={{borderBottom:'2px solid black', display:'inline-block', fontSize:18, fontWeight:'bold'}}>
									Technical Data
								</div>
								<div style={{fontSize:'16px', marginTop:13}}>
									<div style={{fontSize:'14px'}} dangerouslySetInnerHTML={{__html: producto.technical_data}} />
								</div>
							</div>
							*/}
						</div>
					</div>
				</div>
			</div>
			{/* VIstos recientemente */}
			<div className='row' style={{padding:'0!importat', margingBottom:'40px'}}>
				<div className="col s12 m12 container-prod">
					<div className="container-blanco"> 
						<div className="source-sans nombre-clasificacion">Recently Viewed</div>
						<div className='row center-y-x' style={{padding:'6px'}}> 
							{/* VIstos recientemente */}
							<Vistos />
						</div> 
					</div>
				</div>
				<div style={{padding:'30px'}}></div>
			</div>
		
		</div>
		</>
		}
		</div>
		
	)
}

export default Producto
