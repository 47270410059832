import React, { useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom'; //reactjs
import BackgroundImg from '../../compras-moda-linea-computadora.jpg'
import { axios } from '../../../../infrastructure/axios'

const Index = () => {

	const location = useLocation()
	const searchParams = new URLSearchParams(location.search);
	const suscripcion_id = searchParams.get('suscripcion_id');
	const navigate = useNavigate()

	const getCheckout = () => {

		const config = {
			targetIFrame: "#conektaIframeContainer",
			publicKey: "key_CqyUsd7TCY73O6vhgi18Qqg", // desarrollo
			locale: 'es', // 'es' Español | 'en' Ingles
		};

		const callbacks = {
			// Evento que permitirá saber que el token se creado de forma satisfactoria, es importante que se consuman los datos que de él derivan.
			onCreateTokenSucceeded: (token)=> {
				console.log(token)
				axios.post('/api/reseller/create_subscription', {token, suscripcion_id})
				.then((r)=>{
					console.log(r.data);
					navigate('/admin/suscripcion')
				})
				.catch(r=>alert(r))
			},
			// Evento que permitirá saber que el token se creado de manera incorrecta, es importante que se consuman los datos que de él derivan y se hagan las correciones pertinentes.
			onCreateTokenError: (error) =>console.log(error),
			// Evento que notifica cuando finalizó la carga del tokenizer
			onGetInfoSuccess: (loadingTime) => console.log("loadingTime")
		};

		setTimeout(()=>{
			window.ConektaCheckoutComponents.Card({ config, callbacks, allowTokenization: true})
		}, 1000)

	}

	useEffect(() => {
		console.log('getCheckout useEffect')
		/**
		  * creacion del elemento en el dom
		  */
		const script = document.createElement('script');
		script.type = 'module';
		script.src = 'https://pay.conekta.com/v1.0/js/conekta-checkout.min.js';
		script.async = true;

		/**
		  * ejecucion despues de iniciar el script
		  */
		script.onload = () => getCheckout();

		/**
		  * agrega el elemento al dom
		  */
		document.head.appendChild(script);

		/**
		  * elimina el elemento al desmontar el componente
		  */
		return () => {
			document.head.removeChild(script);
		};
	}, []);



	return(
		<div style={{backgroundImage:`url(${BackgroundImg})`, backgroundSize:'cover', minHeight:'100vh', minWidth:'100vw', position:'absolute', left:0}}>
			
		<center>
			<h4 className='white-text bold'>PLAN PREMIUM {suscripcion_id.toUpperCase()}</h4>
		</center>
			<div id="conektaIframeContainer" style={{height: 700}}></div>
		</div>
	)
	
}

export default Index
