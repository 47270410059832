import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import axios from '../../../../infrastructure/axios'
import FilterNav from '../../../../components/Nav/FilterNav'
import ProductCard from '../../../../components/ProductCard';
import Loader from '../../../../components/Loader';
import M from 'materialize-css'

const Categoria = ({categoria_id, departamento_id}) => {

	const [categoria, setCategoria] = useState({productos:[], departamento_id:''})
	const [loader, setLoader] = useState(true)
	const [departamento, setDepartamento] = useState({categorias:[]})
	const [departamentos, setDepartamentos] = useState([])
	const [categorias, setCategorias] = useState([])
	const [productos, setProductos] = useState([])
	const [search, setSearch] = useState('')

	useEffect(() => {
	
		setLoader(true)
		axios.post(`/api/reseller/post_categoria_departamento`,{categoria_id, departamento_id})
		.then(r=>{
			setLoader(false)
			setCategoria(r.data)
			setProductos(r.data.productos)

		})
		.catch((r)=>alert(r))

		/**
		  * Side-Menu
		  */
		axios.get('/api/reseller/get_all_industry_departaments')
		.then(r=>{
			//buscar la industria que tiene el departamento departamento_id
			const industria = r.data.find(industria=>industria.departamentos.some(d=>d.id===parseInt(departamento_id)))
			//buscar el departamento dentro de la industria
			const departamento = industria.departamentos.find(departamento=>departamento.id===parseInt(departamento_id))
			//actions
			setDepartamentos([departamento])
			setDepartamento(departamento)
			setCategorias(departamento.categorias)


		}).catch(e=>alert(e))

	},[categoria_id])

	return (
		<div style={{padding: '0px 80px', background:'#F5F7FA', minHeight:'80vh'}}>
		<FilterNav categorias={categorias} departamentos={departamentos} productos={productos} setProductos={setProductos}/>
		{loader?
			<Loader />
			:
		<>
			{/**
			   *
			   * Breadcrumb
			   *
			   */}
			<div className='row hide-on-640' style={{marginBottom:'0px',}}>
				<div className='col s12 m-p-0 brad' style={{padding:'20px 0px!important', width:'99vw'}}>
					<div className="row m-p-0" style={{display:'flex', alignItems:'center'}}>
						<div className='migaja1'><Link className="" to='/deps'> {departamento.departamento} &nbsp;&gt;&nbsp;</Link></div>
						<div className='migaja2'><Link className="" to={`/cat?categoria_id=${categoria_id}&departamento_id=${departamento.id}`}>{categoria.categoria}</Link></div>
					</div>
				</div>
			</div>

				<div className="row m-p-0" style={{width:'100%'}}>
					{/**
					   *
					   * Left Menu
					   *
					   **/}
					<div className="col s2 cont-listado lateral-cat hide-on-640" style={{background:'white', padding:15}}>
						<div style={{display:'flex'}}>
							<input value={search} onChange={(e)=>setSearch(e.target.value)} style={{border:'1px solid #D1D6DC', height:30, borderTopLeftRadius:4, borderBottomLeftRadius:4, paddingLeft:10}} placeholder='Search'/>
							<div style={{border:'1px solid #D1D6DC', height:31, background:'#F5F7FA', borderTopRightRadius:4, borderBottomRightRadius:4}}><i className="small material-icons">search</i></div>
						</div>
						<div style={{fontSize:18, fontWeight:'bold'}}>Product Groups</div>
						{departamento.categorias.filter((c)=>c.categoria.toLowerCase().includes(search.toLowerCase())).map((c,i)=>
						<Link to={`/cat?categoria_id=${c.id}&departamento_id=${departamento.id}`} key={i} style={{color:'black', display:'block', margin:'10px 0px'}}> {c.categoria} </Link>
						)}
					</div>



					{/**
					   *
					   * Content
					   *
					   **/}
					<div className="col s9 cont-listado lateral-list">
					{productos.map((p,i)=>
						<div key={i} className='col m-p-0'>
							<ProductCard producto={p} />	
						</div>
					)}
					</div>
				</div>
		</>
		}
		</div>
	)
}

export default Categoria
