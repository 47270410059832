import React, { useEffect, useState } from 'react';
import { axios } from '../../infrastructure/axios'
import M from 'materialize-css'

/**
  * Importante
  * product se hidrata de localStorage al abrir cada modal
  * importante mantener actualizado localStorage al abrir
  * ejemplo de un trigger:
  * <a href='#modal-message' onClick={()=>localStorage.setItem('modal_contactnow_data', JSON.stringify({message:`Product: ${producto.nombre}\nCode: ${producto.codigo}\n\n\n`, to_user_uuid:producto.proveedor.message_api_uuid, to_user_name:producto.proveedor?.company_name}))} className='btn waves-effect waves-light modal-trigger' style={{background:'#CD554B', width:'100%'}}><i className="material-icons left">mail</i>Contact Now</a>
  * tambien definir <ModalContactNow /> en la pagina que se usara
  */

const ModalContactNow = () => {

	const [modal_contactnow_data, set_modal_contactnow_data] = useState({})

	const [data, setData] = useState({message:''})
	const [loader, setLoader] = useState(false)

	useEffect(()=>{

		setTimeout(()=>{
		M.Modal.init(document.querySelectorAll('#modal-message'), {onOpenEnd:() => {
			document.getElementById("message-id").focus()
			let modal_contactnow_data = localStorage.getItem('modal_contactnow_data')===null?{}:JSON.parse(localStorage.getItem('modal_contactnow_data'));
			set_modal_contactnow_data(modal_contactnow_data)
			console.log(modal_contactnow_data)
		}})
		}, 2000)

	},[])

	/**
	  * @const _send - function
	  * @param e event
	  * @return {void}
	  */
	const _send = (e) => {

		e.preventDefault();
		e.stopPropagation();

		setLoader(true)
		let message = modal_contactnow_data.message+' Message:'+data.message
		axios.post('/api/message/send_message', {message, to_user_uuid: modal_contactnow_data.to_user_uuid})
		.then(r=>{
			console.log(r.data)
			setLoader(false)
			let instance = M.Modal.getInstance(document.querySelector(`#modal-message`));
			instance.close();
			localStorage.setItem('modal_contactnow_data', null)
			M.toast({html:'Message send'})
		})
		.catch(r=>console.log(r))

	}

	return(
			<div id={`modal-message`} className="modal" style={{zIndex:99999, position:'absolute'}}>
				<div className="modal-content">
					<h4>Message to: {modal_contactnow_data.to_user_name}</h4>
					<h4>{modal_contactnow_data.message}</h4>
					<form onSubmit={_send}>
						<div className="row">
							<textarea id='message-id' value={data.message} onChange={(e)=>setData({...data, message:e.target.value})} style={{height:400}}/>
							{loader?
							<div className='right'>Loading ...</div>:
							<button className='btn waves-effect waves-light black right modal-close'>Send Message</button>
							}
						</div>
					</form>
				</div>
			</div>
	)
	
}

export default ModalContactNow
