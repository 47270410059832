import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './index.css'
import { axios } from '../../../../infrastructure'
import ModalContactNow from '../../../../components/Modals/ModalContactNow'

/**
  * images
  */
import diamond from './diamond.png'
import audited from './audited.png'
import fondo from './fondo1.png'

const Nav = ({company_name}) => {

	const [proveedor, setProveedor] = useState({categorias:[]})

	useEffect(()=>{
		axios.post('/api/proveedor/webconfigs', {company_name})
		.then(r=>setProveedor(r.data))
		.catch(e=>console.log(e))
	},[])

	return(
		<>
		<ModalContactNow />
		<nav style={{background:'#fff', borderTop:'.5px solid #EEEEEC', lineHeight:'normal'}}>
			<div style={{display:'flex', alignItems:'center', width:'70%', margin:'auto'}}>
				<div className='black-text'> {company_name} </div>
				<div className='grey-text' style={{display:'flex', alignItems:'center', margin:'20px'}}>
					<img alt='diamond' height='20' width='20' src={diamond} />
					Diamond Member <span className='red-text'>Since {new Date(proveedor.created_at).getFullYear()}</span>
				</div>
				<div className='grey-text' style={{display:'flex', alignItems:'center', margin:'20px'}}>
					<img alt='audited' height='20' width='20' src={audited} />
					Audited Supplier
				</div>
				<a href={`#modal-message`} onClick={()=>localStorage.setItem('modal_contactnow_data', JSON.stringify({message:`Hello ${proveedor.company_name}`, to_user_uuid:proveedor.user?.message_api_uuid, to_user_name:proveedor.company_name}))} className='btn waves-effect waves-light red-text white modal-trigger' style={{border:'1px red solid', marginLeft:'auto'}}>Contact Now</a>
			</div>
		</nav>
		<div className='' style={{backgroundImage:`url(${fondo})`, height:110}}/>
		</>
	)
	
}

export default Nav
