import React, { useEffect, useState } from 'react'
import { axios } from '../../../infrastructure/axios'

const PaymentHistoryPageIndex = () => {

	const [pagos, setPagos] = useState([])

	useEffect(()=>{
		axios.get('/api/reseller/pagos')
		.then((r)=>{
			setPagos(r.data)
		})
		.catch((e)=>alert(e))
	},[])

	return(
		<div className='card-panel'>
			<h4>Payment History</h4>
			<table>
				<thead>
					<tr>
						<th>Date</th>
						<th>Amount</th>
						<th>Account</th>
						<th>Reference</th>
						<th>Month</th>
					</tr>
				</thead>

				<tbody>
				{pagos.map((p,i)=>
					<tr key={i}>
						<td>{p.fecha}</td>
						<td>{p.monto}</td>
						<td>{p.cuenta_destino}</td>
						<td>{p.referencia}</td>
						<td>{p.mes}</td>
					</tr>
				)}
				</tbody>
			</table>
		</div>
	)
}

export default PaymentHistoryPageIndex
