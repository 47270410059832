import React, { useEffect, useState } from 'react';
import { axios } from '../../infrastructure/axios'
import Loader from '../../components/loading'
import M from 'materialize-css';

const Domains = () => {

	const [data, setData] = useState({domain:''})
	const [domains, setDomains] = useState([])
	const [view, setView] = useState('mis_dominios') // mis_dominios || nuevo_dominio
	const [loader, setLoader] = useState(false)
	const [domainSelected, setDomainSelected] = useState({domain_validation_options:[]})

	useEffect(()=>{
		axios.get('/api/reseller/midomains')
		.then((r)=>{
			setDomains(r.data)
		})
		.catch((e)=>alert(e))
		M.Modal.init(document.querySelectorAll('.modal'), {});
	},[])

	const _newDomain = (e) => {
		e.preventDefault()
		setLoader(true)
		axios.post('/api/reseller/domain', data)
		.then((r)=>{
			setDomains([...domains, r.data])
			setData({domain:''})
			setView('mis_dominios')
			setLoader(false)
		})
		.catch((e)=>alert(e))
		
	}

	const _deleteDomain = (domain) => {
		console.log(domain)

		axios.post('/api/reseller/delete_domain', domain)
		.then((r)=>{
			setDomains(domains.filter(d=>d.id!==r.data.id))
		})
		.catch((e)=>{
			alert(e.response.data.message??e)
		})
		
	}

	const _instructionsDomain = (domain) => {
		setDomainSelected(domain)
	}

	const _install_ssl = (domain) =>{
		axios.post('/api/reseller/install_ssl', domain)
		.then((r)=>{
			setDomains(domains.map(d=>d.id==r.data.id?r.data:d))
		})
		.catch((e)=>{
			alert(e)
		})
	}

	return(
		<div className='card-panel'>
			<div id="modal-tutorial" className="modal">
				<div className="modal-content">
					<h4 style={{textAlign:'center'}}>Custom domain video tutorial</h4>
					<video style={{width:'100%', height:'auto'}} controls>
						<source src="https://storage-apis.s3.us-east-2.amazonaws.com/VideoTutorialDominioPersonalizado.mp4" type="video/mp4" />
						Tu navegador no soporta la etiqueta de video.
					</video>
				</div>
			</div>
			<div id="modal1" className="modal">
				<div className="modal-content">
					<h4>Instructions for activate your domain</h4>
					<p>add the following records to your dns domain</p>
					<p>Wait for DNS propagation for approximately 15 minutes</p>
					<table>
						<thead>
							<tr>
								<th>Type</th>
								<th>Name</th>
								<th>Value</th>
								<th>TTL</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>A</td>
								<td>@</td>
								<td>18.223.251.148</td>
								<td>600 seconds</td>
							</tr>
							<tr>
								<td>CNAME</td>
								<td>www</td>
								<td>{domainSelected.domain}</td>
								<td>600 seconds</td>
							</tr>
							{domainSelected.domain_validation_options.map(d=>
							<tr>
								<td>{d.ResourceRecord.Type}</td>
								<td>{d.ResourceRecord.Name.replace(/\.$/, '')}</td>
								<td>{d.ResourceRecord.Value.replace(/\.$/, '')}</td>
								<td>600 seconds</td>
							</tr>
							)}
						</tbody>
					</table>
				</div>
				<div className="modal-footer">
					<a href="#!" className="modal-close waves-effect waves-green btn-flat">OK</a>
				</div>
			</div>

			<h4>My Domains</h4>
			<p>
				Here you can manage your domains linked to your store
			</p>
			<a className="waves-effect waves-light btn modal-trigger" href="#modal-tutorial">Tutorial Video</a>
			<a href='#!' className="waves-effect waves-light btn right blue" onClick={()=>setView('nuevo_dominio')}><i className="material-icons left">cloud</i>new domain</a>
			{view==='nuevo_dominio'&&
			<div>
				<h4>Add new domain</h4>
				<div className='card-panel' style={{padding:0}}>
					<div style={{background:'#F3F3F3', width:'100%', padding:10}} className='black-text'>What domain do you want add to your account?
						<i className="material-icons right" style={{cursor:'pointer'}} onClick={()=>setView('mis_dominios')}>close</i>
					</div>
						<div className="row" style={{padding:20}}>
							<form onSubmit={_newDomain} className="col s12">
								<div className="row">
									<div className="input-field col s12" style={{display:'inline-flex'}}>
										<span style={{background:'#BABDB6', color:'white', padding:10, borderRadius:'10px 0px 0px 10px'}}>https://</span>
										<input value={data.domain} onChange={(e)=>setData({...data, domain: e.target.value.replace('www.','')})} id="icon_prefix" type="text" style={{border:'1px solid grey', margin:0, borderRadius:'0px 10px 10px 0px'}} required/>
									</div>
								</div>
								{loader?
								<Loader />:
								<button className="waves-effect waves-light btn right blue" onClick={()=>setView('nuevo_dominio')}><i className="material-icons left">send</i>save domain</button>
								}
							</form>
					</div>
				</div>
			</div>
			}
			<table>
				<thead>
					<tr>
						<th>My Domains</th>
						<th>Status</th>
						<th>Certificate SSL</th>
						<th>Activate</th>
						<th>Options</th>
					</tr>
				</thead>
				<tbody>
				{domains.map((domain,i)=>
					<tr key={i}>
						<td>{domain.domain_validation_options==null?<>{domain.domain}</>:<> www.{domain.domain} / {domain.domain}</>}</td>
						<td>
						{domain.domain_validation_options==null?
							<span className="badge green black-text bold" style={{display:'inline-flex'}}><i className="material-icons">check</i>Active</span>:
							(domain.validated?<span className="badge green black-text bold" style={{display:'inline-flex'}}><i className="material-icons">check</i>Active</span>:
							<span className="badge yellow black-text bold" style={{display:'inline-flex'}}><i className="material-icons">check</i>Pending validation ...</span>)
						}
						</td>
						<td>
						{domain.validated?
							(domain.certificate_ssl?<span className="badge green black-text bold" style={{display:'inline-flex'}}><i className="material-icons">check</i>Active</span>:<a onClick={()=>{_install_ssl(domain)}} href='#!' className='btn waves-effect waves-light blue'>Generate and install SSL/Certificate</a>):
							(domain.domain_validation_options==null?<span className="badge green black-text bold" style={{display:'inline-flex'}}><i className="material-icons">check</i>Active</span>:<span className="badge yellow black-text bold" style={{display:'inline-flex'}}><i className="material-icons">check</i>Pending validation ...</span>)
						}
						</td>
						<td>
						{domain.domain_validation_options&&
							<a onClick={()=>_instructionsDomain(domain)} className="waves-effect waves-light btn modal-trigger" href="#modal1">Instructions</a>
						}
						</td>
						<td><a href='#!' onClick={()=>_deleteDomain(domain)}><i className="material-icons">delete</i></a></td>
					</tr>
				)}

				</tbody>
			</table>
		</div>
		)
		
}

export default Domains
