import React from 'react';
import CarruselConfigs from './Carrusel'


const Carrusel = () => {

	return (
		<div className='card-panel'>
			<h4 style={{marginBottom:100, textAlign:'center'}}>Configurations of your online store</h4>
			<h6>All your changes or adjustments will be reflected when you press the <b>save</b> button at the bottom</h6>
			<CarruselConfigs />
		</div>
	)
}

export default Carrusel
