import React from 'react'

import MiPerfil from './Components/MiPerfil'

const Index = () => {

	return(
		<>
			<MiPerfil />
		</>
	)
	
}

export default Index
