import Nav from '../../components/Nav'
import { NavProveedor } from './Components'
//import { useSearchParams } from 'next/navigation' //nextjs
import { useLocation, Outlet } from 'react-router-dom'; //reactjs

const Index = () => {

	//nextjs
	//const searchParams = useSearchParams()
	//const company_name = searchParams.get('name')

	//reactjs
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const company_name = searchParams.get('name');

	return(
		<div style={{background:'#F5F7FA'}}>
			<Nav />
			<NavProveedor company_name={company_name}/>
			<div style={{background:'#F5F7FA'}}>
				<Outlet />
			</div>
		</div>
	)
	
}

export default Index
