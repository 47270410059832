import React, { useEffect, useState } from 'react';
import axios from '../../../../../../infrastructure/axios'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../../../../components/Loader'

/**
  * metodo: Crear orden cargo único para Checkout web tokenizer
  * documentacion:
  * https://developers.conekta.com/docs/web-tokenizer
  * https://developers.conekta.com/recipes/crear-orden-cargo-%C3%BAnico-para-checkout-web-tokenizer
  * publicKey: "key_CqyUsd7TCY73O6vhgi18Qqg", // desarrollo
  * publicKey: "key_YBxs9Tcrq3Zn8aSRsBjz4DQ", // produccion
  */
const ConektaPayments = ({setMetodoPago, pedido}) => {

	const [loader, setLoader] = useState(true)
	const navigate = useNavigate();

	const getCheckout = () => {

		setLoader(false)
		const config = {
			targetIFrame: "#conektaIframeContainer",
			publicKey: "key_CqyUsd7TCY73O6vhgi18Qqg",
			locale: 'es', // 'es' Español | 'en' Ingles
		};

		const callbacks = {
			// Evento que permitirá saber que el token se creado de forma satisfactoria, es importante que se consuman los datos que de él derivan.
			onCreateTokenSucceeded: (token)=> {
				console.log(token)
				axios.post('/api/cobro_por_token_conekta', {...pedido, token})
				.then((r)=>{
					navigate('/micarrito/finaliza')
				})
				.catch(r=>alert(r))
			},
			// Evento que permitirá saber que el token se creado de manera incorrecta, es importante que se consuman los datos que de él derivan y se hagan las correciones pertinentes.
			onCreateTokenError: (error) =>console.log(error),
			// Evento que notifica cuando finalizó la carga del tokenizer
			onGetInfoSuccess: (loadingTime) => console.log("loadingTime")
		};

		window.ConektaCheckoutComponents.Card({ config, callbacks, allowTokenization: true })

	}

	useEffect(() => {
		/**
		  * creacion del elemento en el dom
		  */
		const script = document.createElement('script');
		script.type = 'module';
		script.src = 'https://pay.conekta.com/v1.0/js/conekta-checkout.min.js';
		script.async = true;

		/**
		  * ejecucion despues de iniciar el script
		  */
		script.onload = () => getCheckout();

		/**
		  * agrega el elemento al dom
		  */
		document.head.appendChild(script);

		/**
		  * elimina el elemento al desmontar el componente
		  */
		return () => {
			document.head.removeChild(script);
		};
	}, []);

	if(loader) return <Loader />

	return(
		<div id='capture' style={{padding:15}}>
			<a href='#!' onClick={()=>setMetodoPago('')}>volver</a>
			<div id="conektaIframeContainer" style={{height: 700}}></div>
		</div>
	)
}

export default ConektaPayments
