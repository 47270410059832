import Nav from '../../components/Nav'
import Footer2 from '../../components/Footer/Footer2'
import PoliticasCompras from './Components/PoliticasCompras'

const Index = () => {

	return(
		<>
			<Nav />
			<PoliticasCompras />
			<Footer2 />
			
		</>
	)
	
}

export default Index
