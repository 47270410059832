import React, { useContext } from 'react';
import Nav from '../Nav';
import Banner from './Banner';
import Productos from './Productos';
import Informacion1 from './Informacion1';
import Proximamente from '../Proximamente';
import { DataContext } from '../../contexts/DataContext';
import Footer from '../Footer';
import Loader from '../Loader';

const Home = () => {

	const { config } = useContext(DataContext)

	console.log(config)


	return (
		<div>
		{!config.hasOwnProperty('production_mode')?
			<Loader />:
			(config.production_mode?<>
			<Nav />
			<div style={{background:'#F0F1F2'}}>
				<Banner />
				<Productos />
				<div style={{paddingBottom:15}}>
				<Informacion1 img_promo={config.img_promo2} visible={config.visible_promo2}/>
				</div>
			</div>
			<Footer />
			</>:<Proximamente />)
			}
		</div>
	);
};

export default Home;
