import Nav from '../../components/Nav'
import Footer from '../../components/Footer'
import Pedidos from './Components/Pedidos'

const Index = () => {

	return(
		<>
			<Nav />
			<Pedidos />
			<Footer />
		</>
	)
	
}

export default Index
