import React, { useEffect, useState, useContext } from 'react';
import { DataContext } from '../../../../contexts/DataContext'
import axios from '../../../../infrastructure/axios'
import { minDiff } from './time'
import { Link } from 'react-router-dom'

/**
  * imagenes
  */
import background_proveedor from './card_background_proveedor.png'
import diamond_img from './diamond.png'
import audited_img from './audited.png'
import user_icon from './user.svg'
import user_icon2 from './user2.png'
import notfound_img from '../../../../../Utils/Assets/not-found.png'

const Messages = () => {

	const { user } = useContext(DataContext)
	const [data, setData] = useState({mensaje:'', files:[]})
	const [conversations, setConversations] = useState([])
	const [conversation, setConversation] = useState({messages:[], proveedor:{company_business_type:[]}})
	const [search, setSearch] = useState('')


	useEffect(()=>{
		const fetchData = () => {
			axios.get('/api/message/my_messages')
			.then((r)=>{
				setConversations(r.data.conversations)
			})
			.catch(r=>console.log(r))
		}
		fetchData()

		const pollingInterval = 5000;
		const polling = setInterval(fetchData, pollingInterval);

		return () => {
			clearInterval(polling);
		};

	},[])

	useEffect(() => {
		if (conversation.uuid) {
			setConversation(conversations.find(c=>c.uuid===conversation.uuid))
		}
	}, [conversations]);

	useEffect(()=>{
		const containers = document.querySelectorAll("#chat");
		containers.forEach(container => {
			container.scrollTop = container.scrollHeight;
		});

	},[conversation])

	const _sendMessage = (e) => {
		e.preventDefault()

		const conversation_uuid = conversation.uuid
		const mensaje = data.mensaje

		/**
		  * FormData
		  */
		const formData = new FormData();
		formData.append('conversation_uuid', conversation_uuid)
		formData.append('mensaje', mensaje)

		// Agrega los archivos al FormData
		if(data.files.length>0)
			for (let i = 0; i < data.files.length; i++) {
				formData.append('files[]', data.files[i]);
			}

		/**
		  *
		  * send Data
		  */

		axios.post('/api/message/conversation_mensaje', formData)
		.then(r=>{

			setConversations([...conversations.map(c=>c.uuid===conversation_uuid?{...c, messages:[...c.messages, r.data]}:c)])
			setConversation({...conversation, messages:[...conversation.messages, r.data]})

			//ajustar scroll
			const containers = document.querySelectorAll("#chat");
			containers.forEach(container => {
				container.scrollTop = container.scrollHeight;
			});

			//clear inputs
			setData({mensaje:'', files:[]})
		})
		.catch(r=>alert(r))



	}

	/**
	  *
	  * Conversacion Seleccionada
	  */
	const conversationSelect = (conversation) => {

		/**
		  *
		  * set conversation
		  */
		setConversation(conversation)

		/**
		  *
		  * send request
		  */
		axios.post('/api/message/view', {conversation_uuid: conversation.uuid})
		.then(r=>{
			console.log(r.data)
		})
		.catch(r=>alert(r))
	}

	return(
		<div className='row' style={{margin:0}}>
			{/**
			   *
			   * Nav blue
			   */}
			<div className='col s1' style={{paddingLeft:0, maxWidth:80, background:'#578AF1', height:'100vh'}}>
				<div style={{position:'relative'}}>
					<img alt='user' src={user_icon} width='50'style={{margin:15}}/>
					<div style={{width:10, height:10, backgroundColor:'green', position: 'absolute', right:5, bottom:20, borderRadius:5}}></div>
				</div>
			</div>
			{/**
			   *
			   * Conversations Column
			   */}
			<div className='col s3' style={{paddingLeft:0, maxWidth:350}}>
				<div className='card-panel scroll-style' style={{margin:0, height:'100vh', overflow:'auto'}}>
					{/**
					   *
					   * Search Input
					   */}
					<div style={{marginBottom:20}}>
						<nav className='white' style={{borderRadius:50, height:40}}>
							<div className="nav-wrapper">
								<form>
									<div className="input-field">
										<input value={search} onChange={(e)=>setSearch(e.target.value)} id="search" type="search" required style={{borderRadius:20}}/>
										<label className="label-icon" htmlFor="search">
											<i className="material-icons black-text">search</i>
										</label>
										<i className="material-icons" style={{lineHeight:'40px'}}>close</i>
									</div>
								</form>
							</div>
						</nav>
					</div>

					{/**
					   *
					   * Conversation
					   */}
					{conversations.filter(c=>c.users[0].name.toLowerCase().includes(search.toLowerCase())).map((c,i)=>
					<div key={i} onClick={()=>conversationSelect(c)} style={{background:c.uuid===conversation.uuid?'#EBECF0':'white', padding:10, display:'flex', alignItems:'center', cursor:'pointer'}}>
						<div style={{position:'relative', display:'flex'}}>
							<img alt='user' src={user_icon2} style={{objectFit:'cover', width:80, height:80, marginRight:10}}/>
							{minDiff(c.users[0].last_activity)<2?
							<div style={{width:10, height:10, backgroundColor:'green', position: 'absolute', right:20, bottom:10, borderRadius:5}}></div>:
							<>{minDiff(c.users[0].last_activity)<60&&
							<div style={{color:'green', position:'absolute', right:0, bottom:-10}}>
								{minDiff(c.users[0].last_activity)}min
							</div>
							}
							</>
							}
						</div>
						<div style={{fontSize:18}}>{c.users[0].name}</div>
						{c.messages.filter((m)=>!m.visto).reduce((a,b)=>a+=b.sender_name===user.name?0:1, 0)>0&&
						<label style={{background:'red', margin:10, borderRadius:5, padding:5, color:'white', fontWeight:'bold'}}>{c.messages.filter((m)=>!m.visto).reduce((a,b)=>a+=b.sender_name===user.name?0:1, 0)}</label>
						}
					</div>
					)}
				</div>
			</div>
			{/**
			   *
			   * Chat
			   */}
			<div className='col s5'>
				{/**
				   *
				   * history conversation
				   */}
				{conversation.uuid&&
				<>
				<div id='chat' className='scroll-style' style={{border:'solid 1px #D6D6D6', height:'75vh', padding:20, overflow:'auto', marginTop:20}}>
					{conversation.messages.map((m,i)=>
					<div key={i} className='row' style={{background:user.name===m.sender_name?'white':'#D3D7CF'}}>
						<div className='col s3'>
							<div>
								<i className="material-icons circle medium">account_circle</i>
							</div>
							{m.sender_name}
						</div>
						<div className='col s9'>
							<label>{new Date(m.created_at).toLocaleString('es-MX', { timeZone: "America/Mexico_City" })}</label>
							<p style={{marginBottom:'0px'}}>{m.content}</p>
							{m.files?.map((f,i)=>
							<div key={i}>
								<a href={f} target='_blank' rel='noreferrer'>{f.split('/')[f.split('/').length-1]}</a>
							</div>
							)}
							<div style={{color:'grey', fontSize:10}}>{m.visto?'seen':(m.sender_name===user.name?'sent':'received')}</div>
						</div>
					</div>
					)}

				</div>
				{/**
				   *
				   * send text form
				   */}
				<div className="row">
					<form onSubmit={_sendMessage} className="col s12">
						<div className="row">
							<div className="file-field input-field col s1">
								<div className="btn">
									<i className="material-icons">attach_file</i>
									<input type="file" onChange={(e)=>setData({...data, files:[...data.files, e.target.files[0]]})}/>
								</div>
								<div className="file-path-wrapper" style={{display:'none'}}>
									<input className="file-path validate" type="text" />
								</div>
							</div>
							<div className="input-field col s8">
								<i className="material-icons prefix">mode_edit</i>
								<textarea value={data.mensaje} onChange={(e)=>setData({...data, mensaje:e.target.value})} id="icon_prefix2" className="materialize-textarea" name='mensaje' required></textarea>
								<label htmlFor="icon_prefix2">Message</label>
							</div>
							<div className="col s2">
								<button className="btn waves-effect waves-light" type="submit" name="action" style={{marginTop:15}} >Send
									<i className="material-icons right">send</i>
								</button>
							</div>
						</div>
						{data.files.map((f,i)=>
						<div key={i}>
							<a href='#!'>file {f.name}</a>
						</div>
						)}
					</form>
				</div>
				</>
				}
			</div>
			{/**
			   *
			   * Chat
			   */}
			{conversation.proveedor.company_name&&
			<div className='col s3'>
				<div className='card-panel' style={{margin:'10px 0px', padding:0}}>
					<Link to={`/proveedor?name=${conversation.proveedor.company_name}`} target='_blank'>
						<div style={{backgroundImage:`url(${background_proveedor})`,backgroundSize:'contain', padding:20, color:'black'}}>
							<img alt='logo' height='40' width='40' src={conversation.proveedor.company_logo??notfound_img} />
							{conversation.proveedor.company_name}
						</div>
					</Link>
					<div style={{padding:20}}>
						<div style={{display:'flex'}}>
							<img height='20' width='20' alt='diamond' src={diamond_img} />
							<div style={{margin:3}}>Diamond Member</div>
							<div style={{margin:3, color:'red'}}>Since {new Date(conversation.proveedor.created_at).getFullYear()}</div>
						</div>
						<div style={{display:'flex', borderBottom:'1px solid grey'}}>
							<img height='20' width='20' alt='diamond' src={audited_img} />
							<div>Audited Supplier</div>
						</div>
					</div>
					<div style={{padding:20}}>
						{conversation.proveedor.company_business_type.join('/')}
					</div>
				</div>
			</div>
			}
		</div>
	)
	
}

export default Messages
