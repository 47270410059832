import React, { useEffect, useContext } from 'react';
import M from 'materialize-css'
import { axios } from '../../../infrastructure/axios';
import { UserContext } from '../../../contexts/UserContext';

const Recoleccion = () => {

	const { configs, setConfigs } = useContext(UserContext);

	useEffect(()=>{
		M.FormSelect.init(document.querySelectorAll('select'), {});

		const onCloseEnd = () => {
			const hora = document.querySelector('#hora_recoleccion')
			hora.value=hora.value.slice(0,-2)+'00'
			if(hora.value>'15:00'||hora.value<'09:00') alert('Schedule out of range for Fedex')
		}
		M.Timepicker.init(document.querySelectorAll('.timepicker_collect'), {twelveHour:false, onCloseEnd});
	},[])

	const _save = (e) => {

		e.preventDefault()

		//const hora = document.querySelector('#hora_recoleccion').value
		axios.post('/api/reseller/horario_recoleccion_fedex',{recolecciones:{...configs.recolecciones, dias:configs.recolecciones.tipo_recoleccion==='diaria'?['Lunes','Martes','Miércoles','Jueves','Viernes','Sábado']:(configs.recolecciones.tipo_recoleccion==='tres_veces'?['Lunes','Miércoles','Viernes']:configs.recolecciones.dias) }})
		.then(r=>{
			M.toast({html:'Updated!'})
		})
		.catch(r=>alert(r))
	}

	const _returnOptions = ()=> {
		switch(configs.recolecciones.tipo_recoleccion){
			case 'diaria':
				return (<div className='input-field col s12'>
						<div>Selected days: </div>
						<a href='#!' className='btn waves-effect waves-light black' style={{margin:5}}>Monday</a>
						<a href='#!' className='btn waves-effect waves-light black' style={{margin:5}}>Tuesday</a>
						<a href='#!' className='btn waves-effect waves-light black' style={{margin:5}}>Wednesday</a>
						<a href='#!' className='btn waves-effect waves-light black' style={{margin:5}}>Thursday</a>
						<a href='#!' className='btn waves-effect waves-light black' style={{margin:5}}>Friday</a>
						<a href='#!' className='btn waves-effect waves-light black' style={{margin:5}}>Saturday</a>
					</div>)
			case 'semanal':
				return (<div className='input-field col s12'>
						<div>Select a day of the week: </div>
						<a href='#!' className={`btn waves-effect waves-light ${configs.recolecciones.dias&&configs.recolecciones.dias.length===1&&configs.recolecciones.dias[0]==='Lunes'?'black':'grey'}`} onClick={()=>setConfigs({...configs,recolecciones:{...configs.recolecciones, dias:['Lunes']}})} style={{margin:5}}>Monday</a>
						<a href='#!' className={`btn waves-effect waves-light ${configs.recolecciones.dias&&configs.recolecciones.dias.length===1&&configs.recolecciones.dias[0]==='Martes'?'black':'grey'}`}  onClick={()=>setConfigs({...configs,recolecciones:{...configs.recolecciones, dias:['Martes']}})} style={{margin:5}}>Tuesday</a>
						<a href='#!' className={`btn waves-effect waves-light ${configs.recolecciones.dias&&configs.recolecciones.dias.length===1&&configs.recolecciones.dias[0]==='Miércoles'?'black':'grey'}`}  onClick={()=>setConfigs({...configs,recolecciones:{...configs.recolecciones, dias:['Miércoles']}})} style={{margin:5}}>Wednesday</a>
						<a href='#!' className={`btn waves-effect waves-light ${configs.recolecciones.dias&&configs.recolecciones.dias.length===1&&configs.recolecciones.dias[0]==='Jueves'?'black':'grey'}`}  onClick={()=>setConfigs({...configs,recolecciones:{...configs.recolecciones, dias:['Jueves']}})} style={{margin:5}}>Thursday</a>
						<a href='#!' className={`btn waves-effect waves-light ${configs.recolecciones.dias&&configs.recolecciones.dias.length===1&&configs.recolecciones.dias[0]==='Viernes'?'black':'grey'}`}  onClick={()=>setConfigs({...configs,recolecciones:{...configs.recolecciones, dias:['Viernes']}})} style={{margin:5}}>Friday</a>
						<a href='#!' className={`btn waves-effect waves-light ${configs.recolecciones.dias&&configs.recolecciones.dias.length===1&&configs.recolecciones.dias[0]==='Sábado'?'black':'grey'}`}  onClick={()=>setConfigs({...configs,recolecciones:{...configs.recolecciones, dias:['Sábado']}})} style={{margin:5}}>Saturday</a>
					</div>)
			case 'tres_veces':
				return (<div className='input-field col s12'>
						<div>Días seleccionados: </div>
						<a href='#!' className={`btn waves-effect waves-light black`} style={{margin:5}}>Monday</a>
						<a href='#!' className={`btn waves-effect waves-light grey`} style={{margin:5}}>Tuesday</a>
						<a href='#!' className={`btn waves-effect waves-light black`} style={{margin:5}}>Wednesday</a>
						<a href='#!' className={`btn waves-effect waves-light grey`} style={{margin:5}}>Thursday</a>
						<a href='#!' className={`btn waves-effect waves-light black`} style={{margin:5}}>Friday</a>
						<a href='#!' className={`btn waves-effect waves-light grey`} style={{margin:5}}>Saturday</a>
					</div>)
			default: break
			}
	}

	return(
		<div className='card-panel'>
			<form onSubmit={_save}>
			{/*
			<h4>Schedule your pickups</h4>
			<div className='row'>
				<div className='input-field col s12'>
					<select value={configs.recolecciones.tipo_recoleccion||''} onChange={(e)=>setConfigs({...configs, recolecciones: {...configs.recolecciones,tipo_recoleccion:e.target.value}})} required>
						<option value="" disabled>Chose a type</option>
						<option value="diaria">Daily</option>
						<option value="semanal">A week</option>
						<option value="tres_veces">Three times per week</option>
					</select>
					<label>Pickup Type</label>
				</div>
				{_returnOptions()}
				<div className='input-field col s2'>
					<div>Pick up time</div>
					<input id='hora_recoleccion' defaultValue={configs.recolecciones.hora} type='text' className='timepicker_collect' placeholder='seleccionar' />
				</div>
			</div>
			*/}
			<h4>Pickup Address</h4>
			<div className="row">
				<div className="input-field col s4">
					<input value={configs.recolecciones.PickupLocation?.Contact.PersonName||''} onChange={(e)=>setConfigs({...configs, recolecciones:{...configs.recolecciones,PickupLocation:{...configs.recolecciones.PickupLocation,Contact:{...configs.recolecciones.PickupLocation.Contact, PersonName:e.target.value}}}})} id="nombre_contacto" type="text" required/>
					<label htmlFor="nombre_contacto">Contact Name</label>
				</div>
				<div className="input-field col s4">
					<input value={configs.recolecciones.PickupLocation?.Contact.PhoneNumber||''} onChange={(e)=>setConfigs({...configs, recolecciones:{...configs.recolecciones,PickupLocation:{...configs.recolecciones.PickupLocation,Contact:{...configs.recolecciones.PickupLocation.Contact, PhoneNumber:e.target.value}}}})} id="telefono_contacto" type="text" required/>
					<label htmlFor="telefono_contacto">Contact Phone</label>
				</div>
				<div className="input-field col s4">
					<input value={configs.recolecciones.PickupLocation?.Contact.CompanyName||''} onChange={(e)=>setConfigs({...configs, recolecciones:{...configs.recolecciones,PickupLocation:{...configs.recolecciones.PickupLocation,Contact:{...configs.recolecciones.PickupLocation.Contact, CompanyName:e.target.value}}}})} id="nombre_empresa" type="text" required/>
					<label htmlFor="nombre_empresa">Company/Place name</label>
				</div>
			</div>
			<div className="row">
				<div className="input-field col s12">
					<input value={configs.recolecciones.PickupLocation?.Address.StreetLines[0]||''} onChange={(e)=>setConfigs({...configs, recolecciones:{...configs.recolecciones,PickupLocation:{...configs.recolecciones.PickupLocation,Address:{...configs.recolecciones.PickupLocation.Address, StreetLines:[e.target.value, configs.recolecciones.PickupLocation.Address.StreetLines[1]]}}}})} id="calle_numero" type="text" required />
					<label htmlFor="calle_numero">Street and number</label>
				</div>
				<div className="input-field col s12">
					<input value={configs.recolecciones.PickupLocation?.Address.StreetLines[1]||''} onChange={(e)=>setConfigs({...configs, recolecciones:{...configs.recolecciones,PickupLocation:{...configs.recolecciones.PickupLocation,Address:{...configs.recolecciones.PickupLocation.Address, StreetLines:[configs.recolecciones.PickupLocation.Address.StreetLines[0], e.target.value]}}}})} id="colonia" type="text" required />
					<label htmlFor="colonia">District</label>
				</div>
				<div className="input-field col s12">
					<input value={configs.recolecciones.PickupLocation?.Address.City||''} onChange={(e)=>setConfigs({...configs, recolecciones:{...configs.recolecciones,PickupLocation:{...configs.recolecciones.PickupLocation,Address:{...configs.recolecciones.PickupLocation.Address, City:e.target.value}}}})} id="ciudad" type="text" required />
					<label htmlFor="ciudad">City</label>
				</div>
				<div className="input-field col s12">
					<input value={configs.recolecciones.PickupLocation?.Address.PostalCode||''} onChange={(e)=>setConfigs({...configs, recolecciones:{...configs.recolecciones,PickupLocation:{...configs.recolecciones.PickupLocation,Address:{...configs.recolecciones.PickupLocation.Address, PostalCode:e.target.value}}}})} id="cp" type="text" required />
					<label htmlFor="cp">Postal Code</label>
				</div>
			</div>




			<button href='#!' className='btn black'>Save</button>
			</form>
		</div>
	)
}

export default Recoleccion
