import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import StatusBar from '../../../Components/StatusBar'
import NewDirection from './new'
import axios from '../../../../../infrastructure/axios'
import { DataContext } from '../../../../../contexts/DataContext'
import M from 'materialize-css'
import Nav from '../../../../../components/Nav'
import Loader from '../../../../../components/Loader';
import './index.css'

const Direccion = () => {

	const [agregarDireccion, setAgregarDireccion] = useState(false)
	const [misDirecciones, setMisDirecciones] = useState([])
	const { carrito, direccion, setDireccion, user, config, setServicioEnvio } = useContext(DataContext)
	const [loader, setLoader] = useState(true)
	const [data, setData] = useState({nombre:'',correo:'',calle:'',numExt:'',numInt:'',cp:'', instrucciones_entrega:'', telefono:'', estado:'', ciudad:'', colonia:''})
	const navigate = useNavigate();
	const [productDropshippingIsInCart, setProductDropshippingIsInCart] = useState(false)

	useEffect(() => {
		setProductDropshippingIsInCart(carrito.some(product=>product.sale_type==='dropshipping'))

		M.Modal.init(document.querySelectorAll('#modal-registrate'), {endingTop:'30%'});
		M.Modal.init(document.querySelectorAll('#modal-recoger-en-sucursal'), {endingTop:'5%'});

		setTimeout(()=>{
			axios.get('/api/misdirecciones')
			.then(async r=>{
				setMisDirecciones(r.data)
				setLoader(false)
				if(direccion&&r.data.some(d=>d.nombre===direccion.nombre&&d.calle===direccion.calle)){
					setDireccion(r.data.find(d=>d.nombre===direccion.nombre&&d.calle===direccion.calle))
				}
			}).catch(r=>setLoader(false))
		},1000)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	useEffect(() => {
		if(direccion&&direccion.nombre&&direccion.calle!=='')
		setMisDirecciones(misDirecciones.some(d=>d.nombre===direccion.nombre&&d.calle===direccion.calle)?misDirecciones:[direccion, ...misDirecciones])
	},[loader, direccion, misDirecciones])

	useEffect(() => {
		if(loader===false && user.name === undefined){
			var instance = M.Modal.getInstance(document.querySelector('#modal-registrate'));
			instance.open()
		}
	},[loader])

	/**
	  * @param {Object} obj - new direccion object
	  */
	const _newDireccion = (obj) => {
		setMisDirecciones([...misDirecciones, obj])
		setDireccion(obj)
	};

	/**
	  * @param {Object} obj - direccion object to delete
	  */
	const _deleteMiDirection = (obj) => {
		setMisDirecciones(misDirecciones.filter(dir=>dir!==obj))
		if(obj.id)
			axios.delete(`/api/midireccion/${obj.id}`)
			.then(r=>{
				M.toast({html: 'Dirección eliminada!'})
			})
			.catch(r=>alert(r))

		if(obj.nombre===direccion.nombre&&obj.calle===direccion.calle){
			setDireccion({})
		}
		
	}

	/**
	  * @param {Object} e - event
	  */
	const _pushData = (e) => {
		e.preventDefault()
		if(data.telefono.length === 10){
			_newDireccion(data)
		} else{
			alert('telefono incorrecto. Ej.3345709318')
			return 0
		}

		const instance = M.Modal.getInstance(document.querySelector('#modal-recoger-en-sucursal'))
		instance.close()

		setServicioEnvio({proveedor:'Ecommerce', servicio: 'Recolección', precio:0, descripcion:'Recolección en sucursal'})
		navigate("/micarrito/pago")
		window.scrollTo(0, 0)
		
	}


	return (
		<div className="cart-container-short">
			<div id="modal-registrate" className="modal" style={{borderRadius:20, background:'#FFFFFF',}}>
				<div className="modal-content" style={{border:"solid 2px #000000",borderRadius:'20px'}}>
					<center>
						<h4 className='bold black-text' style={{margin:40}}>No dejes pasar este momento</h4>
						<Link className='btn primarycolor pulse waves-effect waves-light btn-large modal-close' to='/registro' style={{textTransform:"none"}}>Registrate</Link>
					</center>
				</div>
			</div>
			
			<div>
				<div className="center-y-x" style={{backgroundColor:'#ffffff', paddingTop:'10px',}}>
					<div className="" style={{ width:'50px', marginRight:'50px', display:'inline-block',}}>
						<Link to='/' className=''>
							<img className='logo-img' style={{verticalAlign:'middle', height:'40px', width:'40px'}} alt=''/>
						</Link>
					</div>
					<div className="center-y-x">
						<StatusBar status='direccion'/>
					</div>
					<div className="center-y-x" style={{paddingLeft:'50px', display:'inline-block'}}>
						{user.name&&
						<div>
							<Link  className='link-a black-text' to='/miperfil'>
								<div style={{display:'inline-block', width:'35px', textAlign:'left', verticalAlign:'middle',}}>
									<i className='material-icons' style={{color:'#000000',}}>account_circle</i>
								</div>
								<div className="m-p-0" style={{display:'inline-block', width:'100px', verticalAlign:'middle', textAlign:'left',}}>
									<div style={{color:'#000000', fontSize:'10px', fontWeight:'500', width:'100%', lineHeight:'1'}}>HOLA</div> 
									<div style={{color:'#000000', fontSize:'14px', width:'100%', lineHeight:'1'}}>
										{(user.name.indexOf(' ') >= 0) ? user.name.substr(0,user.name.indexOf(' ')) : user.name }
									</div>
								</div>
							</Link> 
						</div>
						}
					</div>
				</div>
				<div className="dirPadding">
						<div id="modal-recoger-en-sucursal" className="modal">
							<div className="modal-content">
								<h4>Pickup in own adreess</h4>
								<h5>Address Data</h5>
								{config.configs?.direccion&&
								<div className='card-panel'>
									<iframe src={`https://maps.google.com/maps?q=${config.configs.direccion.StreetLines[0].replace('#','')}, ${config.configs.direccion.City}&t=&z=13&ie=UTF8&iwloc=&output=embed`} width="400" height="300" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
									<p>
									Street adress:<br />
									{config.configs.direccion.StreetLines[0]}<br />
									{config.configs.direccion.StreetLines[1]}, cp.{config.configs.direccion.PostalCode} {config.configs.direccion.City}
									</p>
								</div>
								}
								<div className='card-panel'>
									<h5 style={{textAlign:'center'}}>Details of who collects the package</h5>
									<form onSubmit={_pushData}>
										<div className='input-field'>
											Full Name
											<input className="card-panel-input" value={data.nombre} onChange={(e)=>setData({...data, nombre:e.target.value.replace(/[^A-z ]/g, '')})}  type='text' required/>
										</div>
										{!user.name&&
										<div className='input-field'>
											Email
											<input className="card-panel-input" value={data.correo} onChange={(e)=>setData({...data, correo:e.target.value})}  type='text' required/>
										</div>
										}
										<div className='input-field'>
											Phone Number
											<input className="card-panel-input" value={data.telefono} onChange={(e)=>setData({...data, telefono:e.target.value.replace(/[^0-9]/g, '') })} type="text" maxLength="10" pattern="[0-9]+" required/>
										</div>
										<div className='row'>
											<div className='col s12 m6 m-p-0'>
												<a href='#!' className='btn black btn-movil modal-close'>Cancel</a>
											</div>
											<div className='col s12 m6 m-p-0'>
												<button className='btn primarycolor btn-movil' style={{marginLeft:'auto', display:'block'}}>Submit</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>{/*modal*/}
					<div>
					<div className='tituloDireccion' style={{marginBottom:10}}>Choose your shipping address</div>
					<div className='row'>
						{(config.configs?.direccion&&!productDropshippingIsInCart)&&
						<a className='btn primarycolor waves-effect waves-light modal-trigger' href="#modal-recoger-en-sucursal">pick up at branch?</a>
						}
					</div>
					<div className='row'>
					{!agregarDireccion&&
					<div>
						<a href='#!' onClick={()=>setAgregarDireccion(!agregarDireccion)} className='btn primarycolor waves-effect waves-light' style={{width:'100%', maxWidth:200}}>Add Address</a>
					</div>
					}
					</div>
					</div>
					{loader&&
						<Loader />
					}
					<div className='row'>
						{agregarDireccion&&
						<div className='col s12 m4 m-p-0 datosForm'>
							<NewDirection setAgregarDireccion={setAgregarDireccion} _newDireccion={_newDireccion}/>
						</div>
						}
						{misDirecciones.map((d,i)=>
						<div key={i} className='col s12 m4 m-p-0'>
							<div className={(direccion&&direccion.nombre===d.nombre&&direccion.calle===d.calle)?'direction-active card-panel':'card-panel'} onClick={()=>{setDireccion(d); console.log(d)}} style={{maxWidth:419,height:400, minHeight:200, cursor:'pointer'}}>
								<i className="material-icons grey-text right" onClick={(e)=>{e.stopPropagation();_deleteMiDirection(d)}}>delete</i>
								<div style={{width:'100%'}}>
									<h6 className='bold'>Send package to:  {d.nombre}</h6>
									<p className="margin-y-5">
										{d.calle} #{d.numExt} {d.numInt?`Interior: ${d.numInt}`:''}, {d.colonia}, C.P. {d.cp} {d.ciudad}, {d.estado}, {d.pais}.
									</p>
									<h6 className='bold'>Phone number:</h6>
										<div>{d.telefono}</div>
									</div>
								</div>
							</div>
						)}
					</div>

					{(direccion&&direccion.nombre&&direccion.calle)&&
					<Link to='/micarrito/envio' className='btn black waves-effect waves-light btn-continuar btn-movil'>Continue</Link>
					}
				</div>
			</div>
		</div>
	)
}

export default Direccion
