import React, { useEffect,useState } from 'react';
import axios from '../../../../infrastructure/axios';
import Loader from '../../../../components/Loader';

const PagosEnvios = () => {

	const [pagosenvios , setPagosEnvios] = useState({})
	const [loader ,setLoader] = useState(true)

	useEffect(()=>{ 
		setLoader(true)
		axios.get('/api/reseller/pagosenvios').then(r=>{
			setPagosEnvios(r.data.pagos_y_envios)
			setLoader(false)
		})
	},[])

	return (
		<div style={{minHeight: '80vh'}}>
			<div className='row m-p-0'>
				<div className='col s12 m-p-0 barra'>
					<div>Pagos y Envíos:</div>
				</div>
			</div>
			{loader ? 
			<Loader />
			:
			<div className="cont-result">
				<div className='row center-y-x'>
					<div className='col s10 m10 l8 m-p-0'>
						<div dangerouslySetInnerHTML={{__html: pagosenvios}} />   
					</div>
				</div>
			</div>
			}
		</div>
	);
};

export default PagosEnvios;
