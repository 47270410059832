import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom'
import './search.css';
import M from 'materialize-css'
import axios from '../../../../infrastructure/axios'
import Vistos from '../../../../components/Vistos';
import { DataContext } from '../../../../contexts/DataContext'
import Loader from '../../../../components/Loader';

const Search = () => {

    const { user, buscados, setBuscados} = useContext(DataContext)
    const [loader, setLoader] = useState(false)
    //const [buscados, setBuscados] = useState([]);
    
    const _borrarHistorial = ()=>{
        if (window.confirm("¿Deseas eliminar tu historial de busquedas?")) {
           // setBuscados([]);
            //Borra desde BD 
            if(user.name){
                axios.delete('/api/buscado')
                .then(r=>{
                    setBuscados([])
                })
                .catch(r=>alert(r))
            }else{
                setBuscados([])
            }
        }
    }
    useEffect(()=>{
        setLoader(true)
		M.Carousel.init(document.querySelectorAll('.carousel'), {
            fullWidth: true,
            indicators: true,
            numVisible:5,
        });
        
		if(user.name){
            //Buscados
            axios.get('/api/buscado')
            .then(r=>{
                setBuscados(_mixBuscados(r.data.keys.concat(buscados)))
            
            })
            .catch(r=>alert(r))
        }
        setLoader(false)

         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.name]);

    const _mixBuscados = (array) =>{
        let a =array.concat()
        for(let i =0;i<a.length;i++){
            for(var j=i+1; j<a.length;j++){
                if(a[i] === a[j])
                a.splice(j--,1)
            }
        }
        return a
    }
    return (
        <div className="bodyHeight">
            {loader?
            <Loader />
            :
            <div className="bodyHeight">
                <div className='row' style={{marginBottom:'0px', marginLeft:50}}>
                    <div className='col s12 m-p-0 brad'>
                        <div className="row m-p-0" style={{alignItems:'center'}}>
                            <div className='migaja3'> Buscar </div>
                        </div>
                    </div>
                </div>

                <div className="section bg-grey" style={{minHeight:'80vh'}}>
                    <div className="container-large">
                    {buscados.length > 0 ?
                        <div className="row">
                            <div className="col s12 busquedas">
                                <div className="migaja3">Búsqueda reciente </div>
                                <div style={{float:'right'}}> 
                                    <button onClick={_borrarHistorial} className="btn_elimina">   
                                        <i className="material-icons">delete</i>
                                    </button> 
                                </div>
                            </div>
                            <div className="col s12 section-container" style={{paddingLeft:"0!important",}}> 
                            {
                                buscados.reverse().map((item,i)=>
                                <Link key={i} to={`/search/keyword?key=${item}`}>
                                    <div className="search-tag z-depth-1">{item}</div>
                                </Link>
                                )
                            }
                            </div>
                        </div>
                    :
                        <div className="col s11 m-p-0 busquedas">
                            <div className="col s12 m-p-0 ">
                                <div className="migaja3"> Búsqueda reciente </div>
                            </div>
                            <div style={{fontSize:'12px'}}>No se ha realizado busquedas recientes </div>
                        </div>
                    }
                    </div>
                    <div style={{padding:'30px'}}></div>
                    {/* VIstos recientemente */}
                    <div className="container-large" tabIndex="0">
                        <div className="container-blanco"> 
                            <div className="source-sans nombre-clasificacion"> Vistos recientemente </div>
                            <div className='row center-y-x' style={{padding:'6px'}}> 
                                {/* VIstos recientemente */}
                                <Vistos></Vistos>
                            </div> 
                        </div>
                    </div>
                    <div style={{padding:'30px'}}></div>
                </div>
            </div>
        }
        </div>
    );
};

export default Search;
