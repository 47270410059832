"use client"

import React, {useEffect, useContext} from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Grid } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/grid";
import axios from '../../infrastructure/axios'
import { DataContext } from '../../contexts/DataContext'
import ProductCard from '../ProductCard';

const Vistos = () => {

	const {user, vistos, setVistos} = useContext(DataContext)

	useEffect(() => {
		if(user.name){
			/* Regresa los vistos */
			axios.get('/api/visto')
			.then(r=>{
				setVistos(r.data);
			})
			.catch(r=>alert(r))
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[user])

	return (
		<div className='col s12 m-p-0' tabIndex="0" style={{margin:'0!important', padding:'0!important', marginLeft:'100px!important'}}>
		{(typeof document !== 'undefined') &&
			<Swiper className="" style={{borderRadius:'12px', margin:'0!important', padding:'0!important'}}
				modules={[Navigation, Pagination, Scrollbar, A11y, Grid]}
				loop={false}
				effect={'fade'}
				slidesPerView={document.documentElement.clientWidth<640?2:'auto'}
				data-swiper-autoplay={'2000'}
				navigation={document.documentElement.clientWidth<1024?false:true}
				scrollbar={document.documentElement.clientWidth<1024?{draggable:true}:false}
				grid={{ rows: document.documentElement.clientWidth<640?2:1, fill:'row' }}
				>
				{vistos.map((p,i)=>
					<SwiperSlide id="carusel-elementos" key={i} tabIndex="0" style={{borderRadius:'12px', margin:'0!important', padding:'0!important',width:'320px'}}>
						<ProductCard producto={p}/>
					</SwiperSlide>
				)}
				</Swiper>
		}
		</div>
	);
};

export default Vistos;
