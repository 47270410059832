"use client"

import React from 'react';

const Informacion1 = ({img_promo , visible}) => {
	
	return (
		<>
		{img_promo && visible ===1&&
			<div className="img-info" style={{paddingBottom:20}}> 
				<img src={img_promo} style={{objectFit:'cover', height:'100%', width:'100%'}} alt="" /> 
			</div>
			
		}
		</>
	);
};

export default Informacion1;
