import Nav from '../../components/Nav'
import Recuperar from './Components/Recuperar'
import Footer2 from '../../components/Footer/Footer2'

const Index = () => {
	return (
		<>
			<Nav />
			<Recuperar />
			<Footer2 />
		</>
	)
	
}

export default Index
