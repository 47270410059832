import Nav from '../../components/Nav'
import Departament from './Components/Departaments'
import Footer from '../../components/Footer'

const Index = () => {

	return (
		<>
			<Nav />
			<Departament />
			<Footer/>
		</>
	)
}

export default Index
