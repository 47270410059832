import React, { useEffect, useState, useContext} from 'react';
import './resultSearch.css'
import { Link } from 'react-router-dom'
import ProductCard from '../../../../../components/ProductCard';
import axios from '../../../../../infrastructure/axios'
import Vistos from '../../../../../components/Vistos';
import { DataContext } from '../../../../../contexts/DataContext'
import Loader from '../../../../../components/Loader';

const ResultSearch = ({keyword}) => {

 	const {buscados, setBuscados, searchState} = useContext(DataContext)
	const [productos, setProductos] = useState([])
	const [proveedores, setProveedores] = useState([])
	const [loader, setLoader] = useState(true)
	const [departamento, setDepartamento] = useState({categorias:[]})
	const [search, setSearch] = useState('')
	
	useEffect(()=>{
		setLoader(true)
		axios.post('/api/reseller/producto/search', {search: keyword, search_state:searchState})
		.then(r=>{

			if(r.data.departamento)
				setDepartamento(r.data.departamento)

			if(searchState==='Products'){
				setProductos(r.data.productos.filter((item, i) => i === r.data.productos.findIndex( elem => elem.id === item.id)))//elimina duplicados, verifica si se necesita en el backend
			}
			if(searchState==='Suppliers'){
				setProveedores(r.data.productos)
			}
			setLoader(false)
		})
		.catch(r=>alert(r))
		/**Para busqueda con cache */
			if(!(buscados.find(b=>b===keyword)))
				setBuscados([...buscados.slice(-24), keyword]);
			//setBuscados([...buscados.filter(b=>b!==key).slice(-24), key]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[keyword])

	const NoResult = () => {
		return(
			<div className="container">
				<div className="row center">
					<div className="center">
						<i className="small material-icons">info</i>
						<h6>Lo sentimos no se han encontrado resultados, prueba con otra busqueda.</h6>	
					</div>
				</div>
			</div>
		)
	}

	return (
		<div>
			{loader?
			<Loader />
			:
			<div className="bodyHeight" style={{minHeight:'80vh'}}>
				<div className='row' style={{marginBottom:'0px',marginLeft:50}}>
					<div className='col s12 m-p-0 brad'>
						<div className="row m-p-0" style={{alignItems:'center',}}>
							<div className='migaja3'>Buscar &nbsp; : &nbsp; {keyword} </div>
						</div>
					</div>
				</div>
		
				<div className="cont-listado">
					<div className='row'>
					{/**
					   *
					   * Left Menu
					   *
					   **/}
					<div className="col s2 cont-listado lateral-cat hide-on-640" style={{background:'white', padding:15}}>
						<div style={{display:'flex'}}>
							<input value={search} onChange={(e)=>setSearch(e.target.value)} style={{border:'1px solid #D1D6DC', height:30, borderTopLeftRadius:4, borderBottomLeftRadius:4, paddingLeft:10}} placeholder='Search'/>
							<div style={{border:'1px solid #D1D6DC', height:31, background:'#F5F7FA', borderTopRightRadius:4, borderBottomRightRadius:4}}><i className="small material-icons">search</i></div>
						</div>
						<div style={{fontSize:18, fontWeight:'bold'}}>Product Groups</div>
						{departamento.categorias.filter((c)=>c.categoria.toLowerCase().includes(search.toLowerCase())).map((c,i)=>
						<Link to={`/cat?categoria_id=${c.id}`} key={i} style={{color:'black', display:'block', margin:'10px 0px'}}> {c.categoria} </Link>
						)}
					</div>

					{/**
					   *
					   * Content
					   *
					   **/}
					<div className="col s9 cont-listado lateral-list">
						{/**
						   *
						   * Suppliers
						   *
						   **/}
						{proveedores.map((p,i)=>
						<div key={i} className='row' style={{borderBottom:'1px solid grey', paddingBottom:20}}>
							<div className='col s5'>
								<div style={{color:'#246bb3'}}>{p.company_name}</div>
								<span className="" style={{padding:5}}>
									<img border="0" alt="" src="https://www.micstatic.com/common/img/icon/diamond_member_16.png?_v=1682304967451" srcSet="https://www.micstatic.com/common/img/icon/diamond_member_32.png?_v=1682304967451 2x, https://www.micstatic.com/common/img/icon/diamond_member_16.png?_v=1682304967451 1x" style={{marginRight:5}}/>
									Diamond Member
								</span>
								<div className='row' style={{fontSize:12}}>
									<div className='col s6'>Business Type:</div>
									<div className='col s6'>{p.business_type}</div>
								</div>
								<div className='row' style={{fontSize:12}}>
									<div className='col s6'>Main Products:</div>
									<div className='col s6'>{p.main_products}</div>
								</div>
								<div className='row' style={{fontSize:12}}>
									<div className='col s6'>City/Province:</div>
									<div className='col s6'>{p.city}</div>
								</div>
								<a href='#modal-message' onClick={()=>localStorage.setItem('modal_contactnow_data', JSON.stringify({message:'', to_user_uuid:p.message_api_uuid, to_user_name:p.company_name}))} className='btn waves-effect waves-light modal-trigger' style={{background:'#FFAA00'}}><i className="material-icons left">mail</i>Contact now</a>
							</div>
							<div className='col s7'>
								<div className='row'>
									{p.productos.map(producto=>
									<div key={producto.codigo} className='col card-product-proveedor'>
										<Link to={`/product?codigo=${producto.codigo}`}>
											<img alt={producto.item_name} src={producto.imagenes[0]?.url??''} style={{width:150, height:150, objectFit:'cover'}} onError={(e)=>{e.target.src="https://www.charlotteathleticclub.com/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png"}}/>
											<div style={{color:'#246bb3'}}>{producto.item_name}</div>
											<span className="" style={{padding:5}}>
												<img border="0" alt="" src="https://www.micstatic.com/mic-search/img/featured-icon_ee6b6124.png" style={{marginRight:5}}/>
												Featured Product
											</span>
										</Link>
										<div>
											<a href='#modal-message' onClick={()=>localStorage.setItem('modal_contactnow_data', JSON.stringify({message:`Product: ${producto.nombre}\nCode: ${producto.codigo}\n\n\n`, to_user_uuid:p.message_api_uuid, to_user_name:p.company_name}))} className='btn btn-card-product-proveedor-contact modal-trigger'>Contact Now</a>
										</div>
									</div>
									)}
								</div>
							</div>
						</div>
						)}
						
						{productos.map((p,i)=>
						<div key={i} className='col m-p-0' style={{height:430}}>
							<ProductCard producto={p} />	
						</div>
						)}
						{proveedores.length+productos.length===0&&
						<NoResult />
						}
					</div>
				</div>
			</div>

			{/* VIstos recientemente */}
			<div className="container-large" tabIndex="0">
				<div className="container-blanco"> 
					<div className="source-sans nombre-clasificacion"> Vistos recientemente </div>
					<div className='row center-y-x' style={{padding:'6px'}}> 
						{/* VIstos recientemente */}
						<Vistos />
					</div> 
				</div>
			</div>
		</div>
		}
	</div>
	);
};

export default ResultSearch;
