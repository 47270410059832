import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import StatusBar from '../../../Components/StatusBar'
import axios from '../../../../../infrastructure/axios'
import { DataContext } from '../../../../../contexts/DataContext'
import Nav from '../../../../../components/Nav'
import Loader from '../../../../../components/Loader';
import './index.css'
import FedexImage from './fedex.png'

const Envio = () => {

	const { carrito, direccion, servicioEnvio, setServicioEnvio, user, config } = useContext(DataContext)
	const [loader, setLoader] = useState(true)
	const [productDropshippingIsInCart, setProductDropshippingIsInCart] = useState(false)
	const [paquetes, setPaquetes] = useState([]);
	const [direccionRecoleccion, setDireccionRecoleccion] = useState(null)

	useEffect(() => {
		//resetear ServicioEnvio cada que carga el componente, para evitar datos guardados y posibles bugs
		setServicioEnvio({proveedor:'', servicio:'', precio:'',descripcion:'', entrega:''})
		setProductDropshippingIsInCart(carrito.some(product=>product.sale_type==='dropshipping'))

		if(direccion.cp)
			axios.post('/api/reseller/packaging/rate-service',{carrito, cp:direccion.cp})
			.then(r=>{
				setPaquetes(r.data.paquetes)
				setDireccionRecoleccion(r.data.direccion_recoleccion)
				console.log('paquetes:')
				console.log(r.data)
				setLoader(false)
			})
			.catch(r=>alert(r))
			 // eslint-disable-next-line react-hooks/exhaustive-deps
	},[direccion.cp])

	const totalAmount = (paquetes) => {

		const total = paquetes.reduce((accumulator, currentPackage) => {
			const currentAmount = parseFloat(currentPackage.fedex_response[0].RatedShipmentDetails[1].ShipmentRateDetail.TotalNetCharge.Amount);
			return accumulator + currentAmount;
		}, 0);

		return total
	}


	return (
		<>
		{user.name===undefined&&
			<Nav />
		}
		<div className="cart-container-short" style={{minHeight:'80vh'}}>
			<div className="center-y-x" style={{backgroundColor:'#ffffff', paddingBottom:'40px'}}>
				<div className="" style={{ width:'50px', marginRight:'50px', display:'inline-block',}}>
					<Link to='/' className=''>
						<img className='logo-img' style={{verticalAlign:'middle', height:'40px', width:'40px'}} alt=''/>
					</Link>
				</div>
				<div className="center-y-x">
					<StatusBar status='envio'/>
				</div>
				<div className="center-y-x" style={{paddingLeft:'50px', display:'inline-block'}}>
					{user.name&&
					<div>
						<Link  className='link-a black-text' to='/miperfil'>
							<div style={{display:'inline-block', width:'35px', textAlign:'left', verticalAlign:'middle',}}>
								<i className='material-icons' style={{color:'#000000',}}>account_circle</i>
							</div>
							<div className="m-p-0" style={{display:'inline-block', width:'100px', verticalAlign:'middle', textAlign:'left',}}>
								<div style={{color:'#000000', fontSize:'10px', fontWeight:'500', width:'100%', lineHeight:'1'}}>HOLA</div> 
								<div style={{color:'#000000', fontSize:'14px', width:'100%', lineHeight:'1'}}>
									{(user.name.indexOf(' ') >= 0) ? user.name.substr(0,user.name.indexOf(' ')) : user.name }
								</div>
							</div>
						</Link> 
					</div>
					}
				</div>
			</div>

			{loader?
				<Loader/>
			:
			<div className='container-large row'>
			<div>
			<h4>Chose a option:</h4>
			{(paquetes.length===1 && paquetes[0].productos[0].proveedor_dueno_id===paquetes[0].productos[0].proveedor_vendedor_id)&&
				<div className='col s12 m3 l3 paqueteria'>
					<div onClick={()=>setServicioEnvio({proveedor:'Ecommerce', servicio: 'Recolección', precio:0, descripcion: `Address: ${direccionRecoleccion.PickupLocation.Address.StreetLines.join(', ')}, ${direccionRecoleccion.PickupLocation.Address.PostalCode} ${direccionRecoleccion.PickupLocation.Address.City}, ${direccionRecoleccion.PickupLocation.Address.CountryCode}. Business hours: ${direccionRecoleccion.hora}, Days: ${direccionRecoleccion.dias.join(', ')}`})} className='card-panel center' style={'Recolección'===servicioEnvio.servicio?{cursor:'pointer',boxShadow: '2px 2px 12px 3px rgba(255, 228, 219, 0.97)', border: '2px solid #FBB19A'}:{cursor:'pointer'}}>
						<img className="logo-img" alt="" style={{verticalAlign: 'middle', objectFit: 'fill', width:'100%'}} />
						<p style={{width:'100%'}}>
							Pick it up yourself<br />
							$0.00 USD
						</p>
						<div style={{fontWeight:'bold'}}>Address: {direccionRecoleccion.PickupLocation.Address.StreetLines.join(', ')}, {direccionRecoleccion.PickupLocation.Address.PostalCode} {direccionRecoleccion.PickupLocation.Address.City}, {direccionRecoleccion.PickupLocation.Address.CountryCode}</div>
						<a href={`https://www.google.com/maps?q=${direccionRecoleccion.PickupLocation.Address.StreetLines.join(', ')}, ${direccionRecoleccion.PickupLocation.Address.PostalCode} ${direccionRecoleccion.PickupLocation.Address.City}`} target='_blank' rel='noreferrer'>View map</a>
						<p>
							Business hours:<br />
							Hour: {direccionRecoleccion.hora} <br />
							Days: {direccionRecoleccion.dias.join(', ')}
						</p>
					</div>
				</div>
			}
				<div className='col s12 m4 l4 paqueteria'>
					<div onClick={()=>setServicioEnvio({proveedor:'Fedex', servicio: 'Economico', precio:totalAmount(paquetes), descripcion: 'Economic Service', entrega: new Date().setDate(new Date().getDate() + 5)})} className='card-panel center' style={servicioEnvio.servicio==='Economico'?{cursor:'pointer',boxShadow: '2px 2px 12px 3px rgba(255, 228, 219, 0.97)', border: '2px solid #FBB19A'}:{cursor:'pointer'}}>
						<img src={FedexImage} alt="envio" />
						<div>Economic</div>
						{paquetes.map((p,i)=>
						<p key={i} style={{width:'100%'}}>
							{p.company_name_owner}: ${p.fedex_response[0].RatedShipmentDetails[1].ShipmentRateDetail.TotalNetCharge.Amount} {p.fedex_response[0].RatedShipmentDetails[1].ShipmentRateDetail.TotalNetCharge.Currency} Package-{i+1}
						</p>
						)}
						<div style={{fontWeight:'bold', fontSize:18}}>Total: {totalAmount(paquetes)} USD</div>

					</div>
				</div>
			</div>

			{servicioEnvio.servicio&&
				<Link to='/micarrito/pago' className='btn black waves-effect waves-light right'>Continue</Link>
			}


			</div>
			}
			
		</div>
		</>
	)
}
//RatedShipmentDetails[1].ShipmentRateDetail.TotalNetCharge.Amount

export default Envio
