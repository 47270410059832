import React from 'react';
import { Link } from 'react-router-dom'

const Index = () => {

	return(
		<div className='card-panel'>
			<h4>Plans</h4>
		<div>
			<center>
				<h4 style={{color:'white'}}>Chose your Package</h4>
			</center>
			<div className='container'>
			<div className='row'>
				<div className='col s6'>
					<div className='card-panel' style={{borderRadius:'10px', height:700}}>
						<div style={{textAlign:'center', fontWeight:'bold', color:'grey', fontSize:22}}>PREMIUM</div>
						<ul style={{height:400}}>
							<li><i className="material-icons">check_box</i>Catalogo ilimitado de productos.</li>
							<li><i className="material-icons">check_box</i>Acceso completo ilimitado de productos o categorías.</li>
							<li><i className="material-icons">check_box</i>Envío Fedex.</li>
							<li><i className="material-icons">check_box</i>Soporte básico por correo electrónico o chat.</li>
							<li><i className="material-icons">check_box</i>Dominio personalizado.</li>
							<li><i className="material-icons">check_box</i>SSL/Certificate gratis.</li>
							<li><i className="material-icons">check_box</i>Dropshiping.</li>
							<li><i className="material-icons">check_box</i>Soporte personalizado.</li>
						</ul>
						<div style={{textAlign:'center', fontWeight:'bold', fontSize:40, color: 'grey'}}>$1,500/mensual</div>
						<center>
							<Link to='/admin/suscripcion/plans/premium?suscripcion_id=asiamart_premium_mensual' className='btn waves-effect waves-light black'>Comprar</Link>
						</center>
					</div>
				</div>
				<div className='col s6'>
					<div className='card-panel' style={{borderRadius:'10px', height:700}}>
						<div style={{textAlign:'center', fontWeight:'bold', color:'grey', fontSize:22}}>PREMIUM</div>
						<ul style={{height:400}}>
							<li><i className="material-icons">check_box</i>Catalogo ilimitado de productos.</li>
							<li><i className="material-icons">check_box</i>Acceso completo ilimitado de productos o categorías.</li>
							<li><i className="material-icons">check_box</i>Envío Fedex.</li>
							<li><i className="material-icons">check_box</i>Soporte básico por correo electrónico o chat.</li>
							<li><i className="material-icons">check_box</i>Dominio personalizado.</li>
							<li><i className="material-icons">check_box</i>SSL/Certificate gratis.</li>
							<li><i className="material-icons">check_box</i>Dropshiping.</li>
							<li><i className="material-icons">check_box</i>Soporte personalizado.</li>
						</ul>
						<div style={{textAlign:'center', fontWeight:'bold', fontSize:40, color: 'grey'}}>$6,000/anual</div>
						<center>
							<Link to='/admin/suscripcion/plans/premium?suscripcion_id=asiamart_premium_anual' className='btn waves-effect waves-light black'>Comprar</Link>
						</center>
					</div>
				</div>
			</div>
			</div>
		</div>
		</div>
	)
			

	
}

export default Index
