import React, { useEffect, useState } from 'react'
import { axios } from '../../infrastructure/axios'
import { Link } from 'react-router-dom'

const Index = () => {

	const [suscripcion, setSuscripcion] = useState({})
	const [pagos, setPagos] = useState([])

	useEffect(()=>{
		axios.get('/api/reseller/my_suscripcion')
		.then((r)=>{
			if(r.data.suscripcion){
				setSuscripcion(r.data.suscripcion)
			}
			setPagos(r.data.pagos)
		})
		.catch(r=>alert(r))
	},[])

	return (
		<div className='card-panel'>
			<h4>My Suscription </h4>

			{(suscripcion.status==='canceled' || suscripcion.status===undefined) &&
			<div>
				<p>you not have a active suscription</p>
				<div>Reactivate or get a suscription:</div>
				<Link to="/admin/suscripcion/plans" className="btn waves-effect waves-light btn-large blue accent-3">plans</Link>
			</div>
			}
			{suscripcion.status!==undefined &&
			 <div className="row">
				<div className="col s12 m6">
					<div className={`card ${suscripcion.status==='canceled'?'red lighten-1':'amber lighten-3'}`}>
						<div className="card-content black-text">
							<span className="card-title">Premium {suscripcion.status?.toUpperCase()}</span>
							<div>
							<div>{`${suscripcion.create_suscripcion_conekta_response?.payment_sources?.data[suscripcion.create_suscripcion_conekta_response?.payment_sources?.data.length-1]?.card_type.toUpperCase()} 
								${suscripcion.create_suscripcion_conekta_response?.payment_sources?.data[suscripcion.create_suscripcion_conekta_response?.payment_sources?.data.length-1]?.brand.toUpperCase()} 
								${suscripcion.create_suscripcion_conekta_response?.payment_sources?.data[suscripcion.create_suscripcion_conekta_response?.payment_sources?.data.length-1]?.type.toUpperCase()}`}
							</div>
							<div style={{display:'flex'}}>
								<i className="material-icons">credit_card</i>
								<div>**********{suscripcion.create_suscripcion_conekta_response?.payment_sources?.data[suscripcion.create_suscripcion_conekta_response?.payment_sources?.data.length-1]?.last4}</div>
							</div>
							<div>exp: {`
							${suscripcion.create_suscripcion_conekta_response?.payment_sources?.data[suscripcion.create_suscripcion_conekta_response?.payment_sources?.data.length-1]?.exp_month}/
							${suscripcion.create_suscripcion_conekta_response?.payment_sources?.data[suscripcion.create_suscripcion_conekta_response?.payment_sources?.data.length-1]?.exp_year}`}</div>
							</div>
							<div style={{marginTop:50}}>Next payment: 
							{new Date(suscripcion.create_suscripcion_conekta_response?.subscription?.billing_cycle_end*1000).toLocaleString()}</div>
						</div>
						{/*
						<div className="card-action">
							<a href="#" className='black-text'>Cancel</a>
						</div>
						*/}
					</div>
				</div>
			</div>
			}
			 <div className="row">
				<div className="col s12 m6">
					<h4>Payments History</h4>
					<table>
						<thead>
							<tr>
								<th>Plan</th>
								<th>Amount</th>
								<th>Method</th>
								<th>Card</th>
								<th>Card EXP</th>
								<th>Date</th>
							</tr>
						</thead>

						<tbody>
						{pagos.map((pago, i)=>
							<tr key={i}>
								<td>{pago.descripcion}</td>
								<td>{pago.total}</td>
								<td>{pago.conekta_response?.data?.object?.payment_method?.brand}</td>
								<td>*************{pago.conekta_response?.data?.object?.payment_method?.last4}</td>
								<td>{pago.conekta_response?.data?.object?.payment_method?.exp_month}/{pago.conekta_response?.data?.object?.payment_method?.exp_year}</td>
								<td>{pago.created_at}</td>
							</tr>
						)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
	
}

export default Index
