import Footer from '../../../components/Footer'
import Nav from '../../../components/Nav'
import Direccion from './Components/Direccion'

const Index = () => {

	return(
		<>
			<Nav />
			<Direccion />
			<Footer />
		</>
	)
	
}

export default Index
