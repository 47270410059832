import React, { useState, useEffect } from 'react'
import { axios } from '../../infrastructure/axios'
import ImageGallery from 'react-image-gallery';
import { Link } from "react-router-dom"
import M from 'materialize-css'

const Catalogo = () => {
	const [view, setSwich] = useState(false)
	const [catalogo, setCatalogo] = useState([])
	const [papelera, setPapelera] = useState([])
	const [search, setSearch] = useState('')
	const [searchArchivados,setSearchArchivados] = useState('')
	const [searchSinCat, setSearchSinCat] = useState('')
	const [productoStockSelect, setProductoStockSelect] = useState({atributos:[]})
	const [formDataStockAdd, setFormDataStockAdd] = useState({folio:'', item_no:'', quantity:'', note:''})
	const [orderHistory, setOrdersHistory] = useState([])

	useEffect(()=>{
		axios.get('/api/seller/catalogo')
		.then(r=>{
			setCatalogo(r.data)
			M.Tabs.init(document.querySelectorAll('.tabs'),{})
			
		})
		.catch(r=>alert(r))

		axios.get('/api/seller/getPapelera')
		.then(r=>{
			//console.log("papelera",r.data)
			setPapelera(r.data)
			M.Tooltip.init(document.querySelectorAll('.tooltipped'),{})
		})
		.catch(r=>alert(r))

		M.Modal.init(document.querySelectorAll('.modal'), {});

	},[])

	useEffect(()=>{

		setFormDataStockAdd({folio:'', item_no:'', quantity:'', note:''})

		console.log(productoStockSelect)
		setTimeout(()=>{
			M.FormSelect.init(document.querySelectorAll('select'), {});
			M.Datepicker.init(document.querySelectorAll('.datepicker'), {format: 'yyyy-mm-dd'})
		},0)

	},[productoStockSelect])

	const _changeView = () =>{
		setSwich(!view)
	}

	const _buildImageToGallery = (images) => {
		return images.length>0?images.map((i)=>({original:i.url})):[{original:'https://bitsofco.de/content/images/2018/12/broken-1.png'}]
	}

	const _restaurar = (id) =>{
		axios.put(`/api/seller/updatePapelera/${id}`)
		.then((r) => {
			M.toast({html: 'Producto Restaurado'})
			setPapelera(papelera.filter(p =>p.id !==id))

			axios.get('/api/seller/catalogo')
			.then(r=>{
				setCatalogo(r.data)
			})
			.catch(r=>alert(r))
		})
		.catch(r=>alert(r))
	}
	/*
	   *
	   * Catalogo obj
	   */
	const _updateCatalogo = (obj,checked) =>{
		const newCatalogo = catalogo.map(c=>c.id===obj.id?{...obj,visible:checked}:c)
		setCatalogo(newCatalogo)
		axios.post('/api/seller/updateVisible',{id:obj.id,visible:checked})
		.then((r) => {
			M.toast({html:'Actualizado!'})
		})
		.catch(r=>alert(r))	
	}

	/**
	  * añade stock
	  * @var formDataStockAdd obj {folio:'', item_no:'', quantity:'', note:''})
	  */
	const _addStock = () => {
		if(formDataStockAdd.folio==='' || formDataStockAdd.quantity==='' || formDataStockAdd.note===''){
			alert('Complet you data')
		}else{
			const order_date = document.querySelector('#order-date').value
			axios.post('/api/reseller/add_stock', {...formDataStockAdd, order_date, item_no: productoStockSelect.item_no??productoStockSelect.codigo})
			.then(r=>{
				M.toast({html:'Stock updated!!'})
				setFormDataStockAdd({folio:'', item_no:'', quantity:'', note:''})
				document.querySelector('#order-date').value=''
			})
			.catch(r=>alert(r))
		}
	}
	const _getOrdersHistory = (id) =>{
		axios.get(`/api/reseller/get_my_orders`)
		.then((r) => {
			setOrdersHistory(r.data)
		})
		.catch(r=>alert(r))
	}

	/**
	  * añade stock
	  * @var formDataStockAdd obj {folio:'', item_no:'', quantity:'', note:''})
	  */
	const _showOnMarketplace = (c, checked) => {

			axios.post('/api/reseller/on_marketplace', {...c, checked})
			.then(r=>{
				M.toast({html:'Updated!!'})
				setCatalogo(catalogo.map((cata)=>cata.codigo===r.data.codigo?{...cata, on_marketplace:r.data.on_marketplace}:cata))
			})
			.catch(r=>alert(r))
	}

	return (
		<>
		<div id="add-stock" className="modal">
			<div className="modal-content">
				<h4>Stock Add {productoStockSelect.item_no??productoStockSelect.codigo}</h4>
				<div className="row">
					<form className="col s12">
						<div className="row">
							<div className="input-field col s12">
							  	<input id='order-date' type="text" className="datepicker" />
								<label htmlFor="order-date">OrderDate</label>
							</div>
							<div className="input-field col s12">
								<input id="folio" type="text" value={formDataStockAdd.folio} onChange={(e)=>setFormDataStockAdd({...formDataStockAdd, folio:e.target.value})} />
								<label htmlFor="folio">OrderFolio</label>
							</div>
							<div className="input-field col s12">
								<input id="quantity" type="number" value={formDataStockAdd.quantity} onChange={(e)=>setFormDataStockAdd({...formDataStockAdd, quantity:e.target.value})} />
								<label htmlFor="quantity">Quantity</label>
							</div>
							<div className="input-field col s12">
								<input id="note" type="text" value={formDataStockAdd.note} onChange={(e)=>setFormDataStockAdd({...formDataStockAdd, note:e.target.value})} />
								<label htmlFor="note">Item Note</label>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div className="modal-footer">
				<a href="#!" className="modal-close waves-effect waves-light btn-flat black white-text" style={{marginRight:20}}>Close</a>
				<a href="#!" className="modal-close waves-effect waves-light btn-flat black white-text" onClick={()=>_addStock()}>save</a>
			</div>
		</div>
		<div className="col s12" style={{marginTop:"50px"}}>
			<Link to="/admin/altaproducto" className="btn waves-effect waves-light right black" style={{display:'flex', alignItems:'center'}}><i className="material-icons">flash_on</i>New product</Link>
			<ul id="tabs-swipe-demo" className="tabs ul-tabs">
				<li className="tab col s2"><a className="active" href="#catalogo-tab">Catalog</a></li>
				<li className="tab col s2"><a href="#papelera-tab">Archived</a></li>
				<li className="tab col s3"><a href="#productos-otros-tab">Products without department</a></li>
				<li className="tab col s3" onClick={()=>_getOrdersHistory()}><a href="#orders-history-tab">Orders History</a></li>
			</ul>
			<div id="catalogo-tab" className='card-panel'>
				<div className="row m-p-0">
				{!view &&
					<div className="col m12 m-p-0">  
						<i className="small material-icons right" 
						onClick={_changeView}
						style={{cursor:'pointer'}}>tock</i> 
					</div>
				}
				{view &&
					<div className="col m12 m-p-0"> 
						
						<i className="small material-icons right" 
						onClick={_changeView}
						style={{cursor:'pointer'}}>apps</i> 
					</div>
				}
				</div>
				
				<h4>Catalog</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field white row">
								<div className='col s1 m1' style={{margin:0, padding:0, textAlign:'center'}}> 
									<label className="label-icon" htmlFor="search"><i className="material-icons" style={{color: 'black',}}>search</i></label>
								</div>
								<div className='col s10 m10' style={{margin:0, padding:0,}}> 
									<input value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" required placeholder='Code, Category, Departament, Name'/>
								</div>
								<div className='col s1 m1' style={{margin:0, padding:0, textAlign:'center',}}> <i className="material-icons">close</i> </div>
							</div>
						</form>
					</div>
				</nav>
			{!view?
				<div className="row">
							<table id="table-catalogo">
								<thead>
								<tr>
									<th>Picture</th>
									<th>Code</th>
									<th>Options</th>
									<th>Name</th>
									<th>Stock</th>
									<th>Visible Marketplace</th>
									<th>Visible My Ecommerce</th>
									<th>Departament</th>
									<th>Category</th>
									<th>Description</th>
									<th>Variant</th>
									<th>Edit</th>
								</tr>
								</thead>

								<tbody>
								{catalogo.filter(c=>c.nombre.toLowerCase().includes(search.toLowerCase()) || c.codigo.includes(search)||c.categoria.toLowerCase().includes(search.toLowerCase()) || c.departamento.toLowerCase().includes(search.toLowerCase())).slice(0,50).map(c=>
								
									<tr key={c.id}>
										<td style={{width:'100px'}}>
											<ImageGallery 
												useBrowserFullscreen={true} 
												items={_buildImageToGallery(c.imagenes)} 
												lazyLoad={true} autoPlay={true} 
												showPlayButton={false} 
												showThumbnails={false} 
												showNav={false}
												showFullscreenButton={false}
												style={{border:'solid purple'}} />
										</td>
										<td> {c.item_no??c.codigo} </td>
										<td>
											<a className="waves-effect waves-light btn modal-trigger black" href="#add-stock" onClick={()=>{setProductoStockSelect(c)}}>Add Stock</a>
										</td>
										<td> {c.nombre} </td>
										<td> {c.stock} </td>
										<td>
											<div className="switch">
												<label>
													<input type="checkbox" checked={c.on_marketplace} onChange={(e)=>_showOnMarketplace(c,e.target.checked)}/>
													<span className="lever"></span>
												</label>
											</div> 
										</td>
										<td>
											<div className="switch">
												<label>
													<input type="checkbox" checked={c.visible} onChange={(e)=>_updateCatalogo(c,e.target.checked)}/>
													<span className="lever"></span>
												</label>
											</div> 
										</td>
										<td> {c.departamento} </td>
										<td> {c.categoria} </td>
										<td> {c.descripcion} </td>
										<td> {c.attribute_type}: {c.attribute_value}</td>
										<td>
											<Link to={`/admin/catalogo/producto/${c.id}`} className='black-text'>
												<i className="material-icons">edit</i>
											</Link>
										</td>
									</tr>
								)}
								</tbody>
							</table>
				</div>
				:
				<div className="row">
				{catalogo.filter(c=>c.nombre.toLowerCase().includes(search.toLowerCase()) || c.codigo.includes(search)||c.categoria.toLowerCase().includes(search.toLowerCase()) || c.departamento.toLowerCase().includes(search.toLowerCase())).slice(0,50).map((c,i)=>
					<div key={i} className="col m6">
						<div className="card-panel" style={{position:'relative'}}>
							<Link to={`/admin/catalogo/producto/${c.id}`} className='black-text' style={{position:'absolute', right:10, top:10}}>
								<i className="material-icons">edit</i>
							</Link>
						<div className='row'>
							<div className="col m5" id="slider2">
								<ImageGallery useBrowserFullscreen={true} 
								items={_buildImageToGallery(c.imagenes)} 
								lazyLoad={true} autoPlay={true} 
								showPlayButton={false}
								showThumbnails={false} 
								showNav={false}
							/>
							</div>

							<div className="col m7">
								<div style={{height:200, overflowY:'auto'}}>
									<div className='black-text'>{c.item_no??c.codigo}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Name:</b> {c.nombre}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Description:</b> {c.descripcion}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Category:</b> {c.categoria}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Departament:</b> {c.departamento}</div>
								</div>
							</div>
						</div>
						</div>
					</div>
				)}
			
				</div>
			}
			</div>

			<div id="papelera-tab" className='card-panel'>
				<h4>Archived</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field white">
								<input value={searchArchivados} onChange={e=>setSearchArchivados(e.target.value)} id="searchArch" type="search" required placeholder='Code, Category, Departament, Name'/>
								<label className="label-icon" htmlFor="searchArch"><i className="material-icons" style={{color: 'black'}}>search</i></label>
								<i className="material-icons">close</i>
							</div>
						</form>
					</div>
				</nav>
				<div className="row">
				
							<table id="table-catalogo">
								<thead>
								<tr>
									<th></th>
									<th style={{textAlign:"left"}}>Code</th>
									<th style={{textAlign:"left"}}>Name</th>
									<th style={{textAlign:"left"}}>Departament</th>
									<th style={{textAlign:"left"}}>Category</th>
									<th></th>
								
								</tr>
								</thead>

								<tbody>
								{papelera.filter(c=>c.nombre.toLowerCase().includes(searchArchivados.toLowerCase()) || c.codigo.includes(searchArchivados)||c.categoria.toLowerCase().includes(searchArchivados.toLowerCase()) || c.departamento.toLowerCase().includes(searchArchivados.toLowerCase())).slice(0,50).map((c,i)=>
									<tr key={i}>
										<td style={{width:'100px'}}>
											<ImageGallery
												useBrowserFullscreen={true}
												items={_buildImageToGallery(c.imagenes)}
												lazyLoad={true}
												autoPlay={true}
												showPlayButton={false}
												showThumbnails={false}
												showFullscreenButton={false}
												showNav={false}/>
										</td>
										<td> {c.item_no??c.codigo} </td>
										<td> {c.nombre} </td>
										<td> {c.departamento} </td>
										<td> {c.categoria} </td>
										<td>
											<div onClick={()=>_restaurar(c.id)} className='black-text pointer tooltipped' data-position="top" data-tooltip="Restaurar">
												<i className="material-icons">unarchive</i>
											</div>
										</td>
									</tr>
								)}
								</tbody>
							</table>
				</div>
			</div>
			<div id="productos-otros-tab" className='card-panel'>
				<h4>Products without departament</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field white">
								<input value={searchSinCat} onChange={e=>setSearchSinCat(e.target.value)} id="searchCat" type="search" required placeholder='Code, Category, Departament, Name'/>
								<label className="label-icon" htmlFor="search"><i className="material-icons" style={{color: 'black'}}>search</i></label>
								<i className="material-icons">close</i>
							</div>
						</form>
					</div>
				</nav>
				<div className="row">
							<table id="table-catalogo">
								<thead>
								<tr>
									<th></th>
									<th>Code</th>
									<th>Name</th>
									<th>Visible</th>
									<th>Departament</th>
									<th>Category</th>
									<th>Description</th>
									<th>Price</th>
									<th></th>
								</tr>
								</thead>

								<tbody>
								{catalogo.filter(c=>(c.nombre.toLowerCase().includes(searchSinCat.toLowerCase()) || c.codigo.includes(searchSinCat)||c.categoria.toLowerCase().includes(searchSinCat.toLowerCase()))&&c.departamento.toLowerCase().includes('otros')).slice(0,50).map((c,i)=>
									<tr key={i}>
										<td style={{width:'100px'}}>
											<ImageGallery 
												useBrowserFullscreen={true} 
												items={_buildImageToGallery(c.imagenes)} 
												lazyLoad={true} autoPlay={true} 
												showPlayButton={false} 
												showThumbnails={false} 
												showNav={false}
												showFullscreenButton={false}
												style={{border:'solid purple'}} />
										</td>
										<td> {c.item_no??c.codigo} </td>
										<td> {c.nombre} </td>
										<td>
											<div className="switch">
												<label>
													<input type="checkbox" checked={c.visible} onChange={(e)=>_updateCatalogo(c,e.target.checked)}/>
													<span className="lever"></span>
												</label>
											</div> 
										</td>
										<td> {c.departamento} </td>
										<td> {c.categoria} </td>
										<td> {c.descripcion} </td>
										<td> 
											<p className="m-p-0" style={{textAlign:'center'}}>{c.precio}</p>
											{c.stock===0&&
											<p className="m-p-0" style={{color:'red', fontWeight:'bold', fontSize:'12px',textAlign:'center'}}>Agotado</p>
											} 
										</td>
										<td>
											<Link to={`/admin/catalogo/producto/${c.id}`} className='black-text'>
												<i className="material-icons">edit</i>
											</Link>
										</td>
									</tr>
								)}
								</tbody>
							</table>
				</div>
			</div>

			<div id="orders-history-tab" className='card-panel'>
				<h4>Orders History</h4>
				<table>
					<thead>
						<tr>
							<th>Order Folio</th>
							<th>Order Date</th>
							<th>Item No</th>
							<th>Quantity</th>
							<th>Note</th>
						</tr>
					</thead>

					<tbody>
						{orderHistory.map((order, i)=>
						<tr>
							<td>{order.folio}</td>
							<td>{order.order_date}</td>
							<td>{order.item_no}</td>
							<td>{order.quantity}</td>
							<td>{order.note}</td>
						</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
		</>
	)
}

export default Catalogo
