"use client"

import React, {useState, useEffect, useContext} from 'react';
import axios from '../../infrastructure/axios'
import ProductCard from '../../components/ProductCard';
import { DataContext } from '../../contexts/DataContext'
import Informacion1 from './Informacion1';

const Productos = () => {

	const [nuevos, setNuevos] = useState([])
	const [vendidos, setVendidos] = useState([])
	const { user, vistos, setVistos, config } = useContext(DataContext)

	useEffect(()=>{
		/**
		  *
		  * Vistos
		  */
		if(user.name){
			/* Regresa los vistos */
			axios.get('/api/visto')
			.then(r=>{
				setVistos(r.data);
			})
			.catch(r=>alert(r))
		}
	},[user.name])

	useEffect(() => {
		/**
		  *
		  * Nuevos
		  */
		axios.get('/api/reseller/catalogonuevos')
		.then(r=>{
			setNuevos(r.data);
		})
		.catch(r=>alert(r))

		/**
		  *
		  * Mas vendidos
		  */
		axios.get('/api/reseller/catalogomasvendidos')
		.then(r=>{
			setVendidos(r.data.filter(item=>item.id));
		})
		.catch(r=>alert(r))


	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	return (
			<>
				
	<div className="row" style={{margin:0, marginBottom:15}}>
		<div className='row' style={{margin:'0 auto', padding:0}}>
			{/*
			  *
			  * Mas vendidos
			  **/}
			{vendidos.length>0&&
			<div className="col s12 m12 container-prod">
				<div className="container-blanco"> 
					<div className="source-sans nombre-clasificacion"> Lo más vendido </div>

					<div className='row m-p-0' style={{margin:'0!important', padding:'0!important',}}> 
						<div style={{display:'flex', overflowY:'auto'}}>
							{vendidos.map((p,i)=>
							<div key={i} className='' style={{position:'relative', display:'inline-block'}}>
								<ProductCard producto={p}/>
							</div>
							)}
						</div>
					</div>  
				</div>
			</div>
			}

			{/*
			  *
			  * Nuevos
			  **/}
			<div className="col s12 m12 container-prod">
				<div className="container-blanco"> 
					<div className="source-sans nombre-clasificacion"> Nuevo </div>
					<div className='row m-p-0' style={{margin:'0!important', padding:'0!important',}}> 
						<div style={{display:'flex', overflowY:'auto'}}>
							{nuevos.map((p,i)=>
							<div key={i} className='' style={{position:'relative', display:'inline-block'}}>
								<ProductCard producto={p}/>
							</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
			<Informacion1 img_promo={config.img_promo1} visible={config.visible_promo1}/>

			{/*
			  *
			  * Vistos
			  **/}
	<div className="row" style={{margin:0, marginBottom:15}}>
		<div className='row' style={{margin:'0 auto', padding:0}}>
			<div className="col s12 m12 container-prod">
				<div className="container-blanco"> 
					<div className="source-sans nombre-clasificacion"> Vistos recientemente </div>
					<div className='row m-p-0' style={{margin:'0!important', padding:'0!important',}}> 
						<div style={{display:'flex', overflowY:'auto'}}>
							{vistos.map((p,i)=>
							<div key={i} className='' style={{position:'relative', display:'inline-block'}}>
								<ProductCard producto={p}/>
							</div>
							)}
						</div>

					</div> 
				</div>
			</div>
		</div>
	</div>
	</>
	);
};

export default Productos;
