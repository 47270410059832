import React, { useEffect, useState } from 'react';
import { axios } from '../../infrastructure/axios'
import { Link } from "react-router-dom"
import M from 'materialize-css'
import ImageNotFound from '../../../Utils/Assets/not-found.png'

const Index = () => {

	const [catalogo, setCatalogo] = useState([])
	const [search, setSearch] = useState('')
	const [moqs, setMoqs] = useState([{rango:' - ', leadTimeDays:' - ', price:''}, {rango:' - ', leadTimeDays:' - ', price:''},{rango:' - ', leadTimeDays:' - ', price:''},{rango:' - ', leadTimeDays:' - ', price:''}])
	const [productSelected, setProductSelected] = useState({})
	const [originalMoqs, setOriginalMoqs] = useState([{rango:' - ', leadTimeDays:' - ', price:''}, {rango:' - ', leadTimeDays:' - ', price:''},{rango:' - ', leadTimeDays:' - ', price:''},{rango:' - ', leadTimeDays:' - ', price:''}])

	useEffect(()=>{
		M.Modal.init(document.querySelectorAll('.modal'), {});
		axios.get('/api/reseller/my_drop_shipping')
		.then(r=>{
			setCatalogo(r.data)
		})
		.catch(r=>alert(r))
	},[])

	/*
	   *
	   * Catalogo obj
	   */
	const _updateCatalogo = (obj,checked) =>{
		const newCatalogo = catalogo.map(c=>c.id===obj.id?{...obj,visible:checked}:c)
		setCatalogo(newCatalogo)
		axios.post('/api/reseller/update_visible_dropshipping',{codigo:obj.codigo, visible:checked})
		.then((r) => {
			M.toast({html:'Updated!'})
		})
		.catch(r=>alert(r))	
	}

	const _edit = (product) => {
		setMoqs(product.minimum_order_quantity) //el propio
		setOriginalMoqs(product.purchase_info.minimum_order_quantity)// el original del dueño
		setProductSelected(product)
	}

	const _update_moqs = () =>{
		 if(/\d/.test(originalMoqs[0].price)){
			 if(parseFloat(moqs[0].price)<parseFloat(originalMoqs[0].price)){
				 alert('Price error')
				return 0
			 }
		 }

		 if(/\d/.test(originalMoqs[1].price)){
			 if(parseFloat(moqs[1].price)<parseFloat(originalMoqs[1].price)){
				 alert('Price error')
				return 0
			 }
		 }

		 if(/\d/.test(originalMoqs[2].price)){
			 if(parseFloat(moqs[2].price)<parseFloat(originalMoqs[2].price)){
				 alert('Price error')
				return 0
			 }
		 }

		 if(/\d/.test(originalMoqs[3].price)){
			 if(parseFloat(moqs[3].price)<parseFloat(originalMoqs[3].price)){
				 alert('Price error')
				return 0
			 }
		 }

		axios.post('/api/reseller/update_moqs_dropshipping',{codigo:productSelected.codigo, moqs})
		.then((r) => {
			setCatalogo(catalogo.map(c=>c.codigo===productSelected.codigo?{...c, minimum_order_quantity:moqs}:c))
			M.toast({html:'Updated!'})
		})
		.catch(r=>alert(r))	
	}


	return(
			<>
			<div id="modal-edit" className="modal">
				<div className="modal-content">
							<div className='row'>
								<div className='bold'>Minimum Order Quantity For {productSelected.codigo} {productSelected.nombre}</div>
								{/*moqs siempre tienen 4 objetos y todos rango puede tener valor: ' - ' y significara que no fue configurado por lo que es necesario saber si fue o no configurado*/}
								{ /\d/.test(moqs[0].rango) &&
								<div className='col m3' style={{padding:0}}>
									<div className='card-panel'>
										moq1
										<div className='row'>
											<div className='input-field col s6'>
												<input value={moqs[0].rango.split('-')[0]} id="moq1_rango_min" type="text" placeholder='100 - 200' disabled/>
												<label htmlFor="moq1_rango_min">Rango min</label>
											</div>
											<div className='input-field col s6'>
												<input value={moqs[0].rango.split('-')[1]} id="moq1_rango_max" type="text" placeholder='100 - 200' disabled/>
												<label htmlFor="moq1_rango_max">Rango max</label>
											</div>
										</div>
										<div className='input-field'>
											<input value={originalMoqs[0].price} id="moq1_price_original" type="number" placeholder='$' disabled/>
											<label htmlFor="moq1_price">Original Price/Unit(usd)</label>
										</div>
										<div className='input-field'>
											<input value={moqs[0].price} id="moq1_price_original" onChange={(e)=>setMoqs(moqs.map((m, i)=>i===0?{...m, price:e.target.value}:m))} type="number" placeholder='$' onWheel={(e)=>e.target.blur()} disabled={(!/\d/.test(moqs[0].rango))}/>
											<label htmlFor="moq1_price">My Price/Unit(usd)</label>
										</div>
									</div>
								</div>
								}
								{ /\d/.test(moqs[1].rango) &&
								<div className='col m3' style={{padding:0}}>
									<div className='card-panel'>
										moq2
										<div className='row'>
											<div className='input-field col s6'>
												<input value={moqs[1].rango.split('-')[0]} id="moq2_rango_min" type="text" placeholder='100 - 200' disabled/>
												<label htmlFor="moq2_rango_min">Rango min</label>
											</div>
											<div className='input-field col s6'>
												<input value={moqs[1].rango.split('-')[1]} id="moq2_rango_max" type="text" placeholder='100 - 200' disabled/>
												<label htmlFor="moq2_rango_max">Rango max</label>
											</div>
										</div>



										<div className='input-field'>
											<input value={originalMoqs[1].price} id="moq2_price_original" type="number" placeholder='$' disabled/>
											<label htmlFor="moq2_price">Original Price/Unit(usd)</label>
										</div>
										<div className='input-field'>
											<input value={moqs[1].price}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===1?{...m, price:e.target.value}:m))} id="moq2_price" type="number" placeholder='$' onWheel={(e)=>e.target.blur()} disabled={(!/\d/.test(moqs[1].rango))}/>
											<label htmlFor="moq2_price">Price/Unit(usd)</label>
										</div>
									</div>
								</div>
								}
								{ /\d/.test(moqs[2].rango) &&
								<div className='col m3' style={{padding:0}}>
									<div className='card-panel'>
										moq3
										<div className='row'>
											<div className='input-field col s6'>
												<input value={moqs[2].rango.split('-')[0]} id="moq3_rango_min" type="text" placeholder='100 - 200' disabled/>
												<label htmlFor="moq3_rango_min">Rango min</label>
											</div>
											<div className='input-field col s6'>
												<input value={moqs[2].rango.split('-')[1]} id="moq3_rango_max" type="text" placeholder='100 - 200' disabled/>
												<label htmlFor="moq3_rango_max">Rango max</label>
											</div>
										</div>
										<div className='input-field'>
											<input value={originalMoqs[2].price} id="moq3_price_original" type="number" placeholder='$' disabled/>
											<label htmlFor="moq3_price">Original Price/Unit(usd)</label>
										</div>
										<div className='input-field'>
											<input value={moqs[2].price}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===2?{...m, price:e.target.value}:m))} id="moq3_price" type="number" placeholder='$' onWheel={(e)=>e.target.blur()} disabled={(!/\d/.test(moqs[2].rango))}/>
											<label htmlFor="moq3_price">Price/Unit(usd)</label>
										</div>
									</div>
								</div>
								}
								{ /\d/.test(moqs[3].rango) &&
								<div className='col m3' style={{padding:0}}>
									<div className='card-panel'>
										moq4
										<div className='row'>
											<div className='input-field col s6'>
												<input value={moqs[3].rango.split('-')[0]} id="moq4_rango_min" type="text" placeholder='100 - 200' disabled/>
												<label htmlFor="moq4_rango_min">Rango min</label>
											</div>
											<div className='input-field col s6'>
												<input value={moqs[3].rango.split('-')[1]} id="moq4_rango_max" type="text" placeholder='100 - 200' disabled/>
												<label htmlFor="moq4_rango_max">Rango max</label>
											</div>
										</div>
										<div className='input-field'>
											<input value={originalMoqs[3].price} id="moq4_price_original" type="number" placeholder='$' disabled/>
											<label htmlFor="moq4_price">Original Price/Unit(usd)</label>
										</div>
										<div className='input-field'>
											<input value={moqs[3].price}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===3?{...m, price:e.target.value}:m))} id="moq4_price" type="number" placeholder='$' onWheel={(e)=>e.target.blur()} disabled={(!/\d/.test(moqs[3].rango))}/>
											<label htmlFor="moq4_price">Price/Unit(usd)</label>
										</div>
									</div>
								</div>
								}
							</div>

				</div>
				<div className="modal-footer">
					<a href="#!" className="modal-close waves-effect waves-green btn-flat red white-text" style={{marginRight:10}}>Close</a>
					<a href="#!" className="modal-close waves-effect waves-green btn-flat black white-text" onClick={()=>_update_moqs()}>Update</a>
				</div>
			</div>
				
		<div className="col s12" style={{marginTop:"50px"}}>
			<Link to="/admin/catalogoglobal" className="btn waves-effect waves-light right black" style={{display:'flex', alignItems:'center'}}><i className="material-icons">flash_on</i>Catalogo Global</Link>
			<div className='card-panel'>
				<div className="row m-p-0">
					<div className="col m12 m-p-0">  
						<i className="small material-icons right" style={{cursor:'pointer'}}>tock</i> 
					</div>
				</div>
				
				<h4>My DropShipping</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field white row">
								<div className='col s1 m1' style={{margin:0, padding:0, textAlign:'center'}}> 
									<label className="label-icon" htmlFor="search"><i className="material-icons" style={{color: 'black',}}>search</i></label>
								</div>
								<div className='col s10 m10' style={{margin:0, padding:0,}}> 
									<input value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" required placeholder='Code, Category, Departament, Name'/>
								</div>
								<div className='col s1 m1' style={{margin:0, padding:0, textAlign:'center',}}> <i className="material-icons">close</i> </div>
							</div>
						</form>
					</div>
				</nav>
				<div className="row">
							<table id="table-catalogo">
								<thead>
								<tr>
									<th>Picture</th>
									<th>Code</th>
									<th>Name</th>
									<th>Visible My Ecommerce</th>
									<th>Departament</th>
									<th>Category</th>
									<th>Description</th>
									<th>Variant</th>
									<th>Edit</th>
								</tr>
								</thead>

								<tbody>
								{catalogo.filter(c=>c.nombre.toLowerCase().includes(search.toLowerCase()) || c.codigo.includes(search)||c.categoria.toLowerCase().includes(search.toLowerCase()) || c.departamento.toLowerCase().includes(search.toLowerCase())).slice(0,50).map(c=>
								
									<tr key={c.id}>
										<td style={{}}>
											<img alt='img' src={c.imagenes.length>0?c.imagenes[0].url:ImageNotFound} style={{height:100, objectFit:'contain'}}/>
										</td>
										<td> {c.codigo} </td>
										<td> {c.nombre} </td>
										<td>
											<div className="switch">
												<label>
													<input type="checkbox" checked={c.visible} onChange={(e)=>_updateCatalogo(c,e.target.checked)}/>
													<span className="lever"></span>
												</label>
											</div> 
										</td>
										<td> {c.departamento} </td>
										<td> {c.categoria} </td>
										<td> {c.descripcion} </td>
										<td>
											{c.atributos.length>0&&
											<div className='card-panel'>
												{c.attribute_type}'s:
												{c.atributos.map((a)=>
												<div key={a.item_no} style={{background:a.stock<1?'#FF626F':'#F3F3F3', padding:5, marginBottom:5}}>
													<p>
														{c.attribute_type}: {a.attribute_value} <br />
														Item_no: {a.item_no} <br />
														Stock: {a.stock} <br />
													</p>
															
												</div>
												)}
											</div>
											}
										</td>
										<td>
											<a href='#modal-edit' className='btn waves-effect waves-light black white-text modal-trigger' onClick={()=>_edit(c)}>PRICES</a>
										</td>
									</tr>
								)}
								</tbody>
							</table>
				</div>
			</div>

		</div>
			</>
	)
	
}

export default Index
