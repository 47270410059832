import AccountSuspendedImg from '../../../Utils/Assets/account_suspended.png'

const Index = () => {
	return(
	<div style={{width: '100vw', height: '100vh', background:'black', display:'flex', alignItems:'center', justifyContent:'center'}}>
		<div>
			<img src={AccountSuspendedImg} />
			<div style={{color:'white', textAlign:'center', fontSize:40, fontWeight:'bold'}}>Account Suspended</div>
		</div>
	</div>
	)
	
}

export default Index
