import Nav from '../../components/Nav' 
import Categoria from './Components/Categoria'
import Footer from '../../components/Footer'  
//import { useSearchParams } from 'next/navigation'
import { useLocation } from 'react-router-dom'; //reactjs

const Index = () => {

	/**
	  * #ticket routing actualmente se usa /cat?categoria_id={id}
	  * cambiar a /cat/{categoria_id}
	  * se desarrollo usando /cat?categoria_id={id} debido a bug en nextjs
	  * consultar y resolver este issue dependiendo si ya se soluciono el bug:
	  * https://github.com/vercel/next.js/issues/48022
	  *
	  */

	//nextjs
	//const searchParams = useSearchParams()
	//const categoria_id = searchParams.get('categoria_id')

	 //reactjs
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search);
	const categoria_id = searchParams.get('categoria_id');
	const departamento_id = searchParams.get('departamento_id');

	return (
		<>
			<Nav />
			<Categoria categoria_id={categoria_id} departamento_id={departamento_id}/>
			<Footer/>
		</>
	)
}
export default Index
