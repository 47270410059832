import React, { useState, useContext, useEffect } from 'react';
import { DataContext } from '../../../../../contexts/DataContext'
import { useNavigate } from 'react-router-dom'
import axios from '../../../../../infrastructure/axios';
import Loader from '../../../../../components/Loader';

const NuevoPass = ({token}) => {

	const {setUser} = useContext(DataContext)
	const [data, setData] = useState({email:'',token:'',password:'',password_confirmation:''})
	const [loader, setLoader] = useState(true)
	const navigate = useNavigate();

	useEffect(()=>{
		axios.get(`/api/password/find/${token}`)
		.then(r=>{
			setData({email:r.data.email, token:r.data.token, password:'',password_confirmation:''})
			setLoader(false)
		})
		.catch(r=>setLoader(false))
	},[token])

	const _reset = (event) =>{
		setLoader(true)
		event.preventDefault()
		axios.post('/api/password/reseller/reset',data)
		.then(r => {
			setUser(r.data.user)
			localStorage.setItem('access_token', 'Bearer '+r.data.access_token);
			axios.defaults.headers.common['Authorization'] = 'Bearer '+r.data.access_token
			navigate('/')
			setLoader(false)
		}).catch(err=>console.log(err))	 
	}

	if(loader) return (
		<Loader />
	)

	return (
	<div style={{minHeight:'80vh'}}>
		{data.email!==''?
		<div className='row center-y-x ptb-80'>
			<div className="col s12 m5 m-p-0 center-y-x">
				<div className="card-panel">
					<div className="s12 center-y-x">
						<p>Ingresa la nueva contraseña para {data.email}</p>
					</div>
					<div className="col s12 pt-10">
						<form onSubmit={_reset}>
							<div className='input-field col s12 p-0'>
								<input onChange={(e)=>setData({...data, password:e.target.value})} id='password' type='password' className='input-height35 validate' required/>
								<label htmlFor='password'>Contraseña*</label>
							</div>
							<div className='input-field col s12 p-0'>
								<input onChange={(e)=>setData({...data, password_confirmation:e.target.value})} id='password_confirmation' type='password' className='input-height35 validate' required/>
								<label htmlFor='password_confirmation'>Confirmar contraseña*</label>
								{data.password!==data.password_confirmation&&
								<span className='helper-text' data-error='error' style={{color:'red'}}>Los passwords no coinciden</span>
								}
							</div>
							<button  className="btn btn primarycolor white-text waves-effect waves-light col s12" >Enviar</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		:
		<div className='row'>
			<div className='col m12 center-y-x'>
				<div className="card-panel">
					<h4>Link expirado</h4>
				</div>
			</div>
		</div>
		}
	</div>
	);
};

export default NuevoPass;
