import React from 'react'
import ResultSearch from './Components/ResultSearch'
import Footer from '../../../components/Footer'
import Nav from '../../../components/Nav';
import ModalContactNow from '../../../components/Modals/ModalContactNow'
//import { useSearchParams } from 'next/navigation' //nextjs
import { useLocation } from 'react-router-dom'; //reactjs


const Index = () => {

	/**
	  * #ticket-004 routing actualmente se usa /search/keyword?key={id}
	  * cambiar a /search/{keyword}
	  * se desarrollo usando /search/keyword?key={id} debido a bug en nextjs
	  * consultar y resolver este issue dependiendo si ya se soluciono el bug:
	  * https://github.com/vercel/next.js/issues/48022
	  */

	//nextjs
	//const searchParams = useSearchParams()
	//const keyword = searchParams.get('key')

	//reactjs
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const keyword = searchParams.get('key');


	return(
		<>
			<Nav />
			<ModalContactNow />
			<ResultSearch keyword={keyword} />
			<Footer />
		</>
	)
	
}

export default Index
