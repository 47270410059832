import Nav from '../../components/Nav'
import Footer2 from '../../components/Footer/Footer2'
import Somos from './Components/Somos'

const Index = () => {
	return(
		<>
			<Nav />
			<Somos />
			<Footer2 />
		</>
	)
	
}

export default Index
