import Footer from '../../../components/Footer'
import Nav from '../../../components/Nav'
import Pedido from './Components/Pedido'
//import { useSearchParams } from 'next/navigation'
import { useLocation } from 'react-router-dom'; //reactjs

const Index = () => {

	/**
	  * #ticket-002 routing actualmente se usa /pedidos/pedido?pedido_id={id}
	  * cambiar a /pedidos/{pedido_id}
	  * se desarrollo usando /pedidos/pedido?pedido_id={id} debido a bug en nextjs
	  * consultar y resolver este issue dependiendo si ya se soluciono el bug:
	  * https://github.com/vercel/next.js/issues/48022
	  *
	  */
	//nextjs
	//const searchParams = useSearchParams()
	//const pedido_id = searchParams.get('pedido_id')

	//reactjs
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const pedido_id = searchParams.get('pedido_id')

	return(
		<>
			<Nav />
			<Pedido pedido_id={pedido_id}/>
			<Footer />
		</>
	)
	
}

export default Index
