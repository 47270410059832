import Footer from '../../../components/Footer'
import Nav from '../../../components/Nav'
import Envio from './Components/Envio'

const Index = () => {
	return(
		<>
			<Nav />
			<Envio />
			<Footer />
		</>
	)
	
}

export default Index
