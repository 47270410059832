import Nav from '../../../components/Nav'
import NuevoPass from './Components/NuevoPass'
import Footer2 from '../../../components/Footer/Footer2'
//import { useSearchParams } from 'next/navigation' //nextjs
import { useLocation } from 'react-router-dom'; //reactjs

const Index = () => {

	/**
	  * #ticket-003 routing actualmente se usa /recuperar/token?token={id}
	  * cambiar a /cat/{categoria_id}
	  * se desarrollo usando /cat?categoria_id={id} debido a bug en nextjs
	  * consultar y resolver este issue dependiendo si ya se soluciono el bug:
	  * https://github.com/vercel/next.js/issues/48022

	  * #ticket se debe actualizar el generador que envia el correo de recuperacion ya que alli se envia el link al usuario
	  * se debe actualizar el link
	  *
	  */
	//nextjs
	//const searchParams = useSearchParams()
	//const token = searchParams.get('token')

	 //reactjs
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');

	return (
		<>
			<Nav />
			<NuevoPass token={token} />
			<Footer2 />
		</>
	)
	
}

export default Index
