import React, { useEffect, useState } from 'react'
import { axios } from '../../../infrastructure/axios'
import Loader from '../../../components/loading'

const BalanceSummaryPageIndex = () => {

	const [saldos, setSaldos] = useState({})	
	const [loader, setLoader] = useState(true)

	useEffect(()=>{
		axios.get('/api/reseller/saldos')
		.then((r)=>{
			setSaldos(r.data)
			setLoader(false)
		})
		.catch(e=>alert(e))
	}, [])

	return(
		<div className='card-panel'>
			<h4>Totales</h4>
			<div className="row">
				<div className="col s12 m4">
					<div className="card blue-grey darken-1">
						<div className="card-content white-text">
							<span className="card-title">Saldo Total</span>
							<h5>{saldos.saldo_total}</h5>
						</div>
						<div className="card-action white-text">
							<span className="card-title white-text">Pedidos Totales</span>
							<div>{saldos.pedidos_totales}</div>
						</div>
					</div>
				</div>
				<div className="col s12 m4">
					<div className="card blue-grey darken-1">
						<div className="card-content white-text">
							<span className="card-title">Saldo listo</span>
							<h5>{saldos.saldo_listo}</h5>
						</div>
						<div className="card-action white-text">
							<span className="card-title white-text">Pedidos Entregados</span>
							<div>{saldos.pedidos_entregados}</div>
						</div>
					</div>
				</div>
				<div className="col s12 m4">
					<div className="card blue-grey darken-1">
						<div className="card-content white-text">
							<span className="card-title">Saldo Pediente</span>
							<h5>{saldos.saldo_pendiente}</h5>
						</div>
						<div className="card-action white-text">
							<span className="card-title white-text">Pedidos Pendientes</span>
							<div>{saldos.pedidos_pendientes}</div>
						</div>
					</div>
				</div>
			</div>
			{loader&&<Loader />}
		</div>
	)

}

export default BalanceSummaryPageIndex
