import React, { useEffect } from 'react'
import Nav from '../../components/Nav'
import Search from './Components/Search'
import Footer from '../../components/Footer'

const Index = () => {

	useEffect(()=>{
		setTimeout(() => {
			document.getElementById("searchI").focus()
		}, 0);
	},[])

	return(
		<>
			<Nav />
			<Search />
			<Footer />
		</>
	)
	
}

export default Index
