import React, { useState, useEffect } from 'react';
import axios from '../../../../infrastructure/axios'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import useWindowSize from './useWindowSize.js'
import './departamento.css'
import Loader from '../../../../components/Loader';

const Departament = () => {

	const [departamentos, setDepartamentos] = useState([])
	const [idActive, setIdActive] = useState(null)
	const [loader, setLoader] = useState(true)
	const size = useWindowSize()
	const location = useLocation()
	const navigate = useNavigate();

	useEffect(()=>{
		setLoader(true)
		axios.get('/api/departamentos')
		.then(r=>{
			setDepartamentos(r.data)
			setLoader(false)
		})
		.catch((r)=>alert(r))
	},[])

	const _alturaCalc = (alto) => {
		if(size.width > 993){
			if(alto < 20 && alto > 0 ){ alto = 350;}
			else if(alto < 40 && alto > 20 ){ alto = 400;}
			else if(alto < 60 && alto > 40 ){ alto = 500;}
			else if(alto < 80 && alto > 60 ){ alto = 600;}
			else if(alto < 100 && alto > 80 ){alto = 700;}
			else if(alto < 120 && alto > 80 ){alto = 800;}
			else if(alto < 140 && alto > 80 ){alto =1000;}
		}
		else if(size.width < 993 && size.width > 640){
			if(alto < 20 && alto > 0 ){ alto = 390;}
			else if(alto < 40 && alto > 20 ){ alto = 490;}
			else if(alto < 60 && alto > 40 ){ alto = 590;}
			else if(alto < 80 && alto > 60 ){ alto = 690;}
			else if(alto < 100 && alto > 80 ){ alto = 790;}
			else if(alto < 120 && alto > 100 ){alto = 890;}
			else if(alto < 140 && alto > 120 ){alto = 990;}
		}
		else if(size.width < 640){
			if(alto < 10 && alto > 0 ){ alto = 350;}
			else if(alto < 20 && alto > 10 ){ alto = 500;}
			else if(alto < 30 && alto > 20 ){ alto = 600;}
			else if(alto < 40 && alto > 30 ){ alto = 700;}
			else if(alto < 50 && alto > 40 ){ alto = 800;}
			else if(alto < 60 && alto > 50 ){ alto = 900;}
			else if(alto < 70 && alto > 60 ){ alto = 1000;}
			else if(alto < 80 && alto > 70 ){ alto = 1100;}
			else if(alto < 90 && alto > 80 ){ alto = 1200;}
			else if(alto < 100 && alto > 90 ){ alto =1300;}
			else if(alto < 110 && alto > 100 ){ alto=1400;}
			else if(alto < 120 && alto > 110 ){ alto=1500;}
			else if(alto < 130 && alto > 120 ){ alto=1600;}
			else if(alto < 140 && alto > 130 ){ alto=1670;}
		}
		return alto
	}
	
	const _showActive = (idItem , departamentoId) => {
		if(location.pathname === "/"){
			// #ticket-001
			navigate("/deps/departamento?departamento_id="+departamentoId);
		}
		else{
			setIdActive(idItem)
		}
	}

	return(
		<div style={{backgroundColor:'#F5F5F5', minHeight:'80vh'}}>
		
		{loader?
			<Loader />
			:
			<div className="bodyHeight" style={{backgroundColor:'#F5F5F5'}}>
				{(location.pathname === "/")&&
					<div className="center-y-x" >
						<div className="section-title-parent">
							<div className="section-title-child primarycolor"></div>
							<div className='nombre-seccion-principal section-tittle'></div>
							<div className="section-title-child primarycolor"></div>
						</div>
					</div>
				}
				{departamentos.map((d,i)=>
					<div className="cont-categoria" key={d.id}> 
						<div className='cat-img' style={{background:`url("${d.img?? 'https://picsum.photos/400/300?random=1&seed='+d.departamento}") center`}} onClick={e => _showActive(i ,d.id)}>
							<div className='cat-hover-container hover-color'></div>
							<div className='cat-hover'> 
								<div className="m-0 primarycolor" style={{paddingTop:'2px'}}>
									<div className="cat-hover-categoria" style={{backgroundColor:'#ffffff', marginTop:'1px'}}> 
										<div className='cat-hover-categoria-titulo source-sans'>
											{d.departamento}
										</div>
										{d.descripcion} 
									</div>
								</div>
							</div>
						</div>
						
					{(idActive === i ) &&
					<div className='cat-active' style={{minHeight:_alturaCalc(d.categorias.length),}}>
					
						<div className='desplegable'>
							<h4 style={{textAlign:'center'}}>{d.departamento}</h4> 
							<h5>{d.descripcion}</h5>
							<div className='cat-name'>
								<div className='listado-categorias'>
									{d.categorias.map((c,i)=>
									<div key={i} className='nombre'>
										<Link className="" to={`/cat?categoria_id=${c.id}&departamento_id=${d.id}`}> {c.categoria}</Link>
									</div>
									)}
								</div>
							</div>
						</div>
					</div>
					}
				</div>
				)} 
			</div>
		}
		</div>
	)
}


export default Departament
