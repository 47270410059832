import React, { useEffect, useState } from 'react';
import axios from '../../infrastructure/axios'
import M from 'materialize-css'
import { countries, cities } from "country-cities"

const ModalCotizar = ({producto}) => {

	const [data, setData] = useState({country:'', city:'', cp:'', fleteInter:'', seguro:'', despachoAduanal:'', entregaDestino:'', asesoria:''})
	const [ciudades, setCiudades] = useState([])
	const [loader, setLoader] = useState(false)
	const paises = countries.all()

	useEffect(()=>{
		M.Modal.init(document.querySelectorAll('.modal'), {})
	},[])

	useEffect(()=>{
		if(data.country!==''){
			const country = paises.find((p)=>p.name===data.country)
			const citis = cities.getByCountry(country.isoCode)
			const ciudades = citis.reduce((accumulator, current) => {
				if (!accumulator.find(item => item.name === current.name)) {
					accumulator.push(current);
				}
				return accumulator;
			}, []);

			setCiudades(ciudades)
			console.log(ciudades)

			setTimeout(()=>{
				M.FormSelect.init(document.querySelectorAll('select'), {});
			}, 1000)

		}
	},[data.country])

	/**
	  * @const _cotizar -
	  * @return {void}
	  */
	const _cotizar = (e) => {

		e.preventDefault()

		setLoader(true)
		axios.post('/api/cotizacion', {cotizacion:data, producto})
		.then(r=>{
			setLoader(false)
			M.toast({html:'Quote sent'})
			let instance = M.Modal.getInstance(document.querySelector('.modal'));
			instance.close();

			/**
			  * Redirect
			  */
			let dominioActual = window.location.origin;
			let nuevaURL = `${dominioActual}/pedidos#quotes-swipe-3`;
			window.open(nuevaURL, '_blank');
		})
		.catch(r=>console.log(r))

	}

	return(
			<div id="modal-quote" className="modal">
				<div className="modal-content">
					<h4>Destination of the merchandise</h4>
					<div className="row">
						<form onSubmit={_cotizar} className="col s12">
							<div className="row m-0">
								<div className="input-field col s4">
									<select value={data.country} onChange={(e)=>setData({...data, country:e.target.value})} >
										<option value="" disabled>Choose your country</option>
										{paises.map((pais, i)=>
										<option key={i} value={pais.name}>{pais.name}</option>
										)}
									</select>
									<label>Country</label>
									{data.country===''&& <input style={{opacity:0, width:10, height:0}} required />}
								</div>
								<div className="input-field col s4">
									<select value={data.city} onChange={(e)=>setData({...data, city:e.target.value})} >
										<option value="" disabled>Choose your city</option>
										{ciudades.map((city, i)=>
										<option key={i} value={city.name}>{city.name}</option>
										)}
									</select>
									<label>City</label>
									{data.city===''&& <input style={{opacity:0, width:10, height:0}} required />}
								</div>
								<div className="input-field col s4">
									<input id="quote_cp" value={data.cp} onChange={(e)=>setData({...data, cp:e.target.value})} type="text" className="validate" />
									<label htmlFor="quote_cp">CP</label>
								</div>
							</div>
							<div className="row">
								<div className='title-1'>Select the services to quote</div>
								<div style={{display:'flex'}}>
									<p style={{marginRight:10}}>
										<label>
											<input type="checkbox" checked={data.fleteInter} onChange={(e)=>setData({...data, fleteInter:e.target.checked})} />
											<span className='black-text'>Yes</span>
										</label>
									</p>
									<p style={{marginRight:30}}>
										<label>
											<input type="checkbox" checked={data.fleteInter===''?false:!data.fleteInter} onChange={(e)=>setData({...data, fleteInter:!e.target.checked})} />
											<span className='black-text'>Not</span>
										</label>
									</p>
									{data.fleteInter===''&& <input style={{opacity:0, width:10}} required />}
									<p style={{display:'flex', alignItems:'center'}}>International cargo</p>
										
								</div>
								<div style={{display:'flex'}}>
									<p style={{marginRight:10}}>
										<label>
											<input type="checkbox" checked={data.seguro} onChange={(e)=>setData({...data, seguro:e.target.checked})} />
											<span className='black-text'>Yes</span>
										</label>
									</p>
									<p style={{marginRight:30}}>
										<label>
											<input type="checkbox" checked={data.seguro===''?false:!data.seguro} onChange={(e)=>setData({...data, seguro:!e.target.checked})} />
											<span className='black-text'>Not</span>
										</label>
									</p>
									{data.seguro===''&& <input style={{opacity:0, width:10}} required />}
									<p style={{display:'flex', alignItems:'center'}}>Insurance</p>
								</div>
								<div style={{display:'flex'}}>
									<p style={{marginRight:10}}>
										<label>
											<input type="checkbox" checked={data.despachoAduanal} onChange={(e)=>setData({...data, despachoAduanal:e.target.checked})} />
											<span className='black-text'>Yes</span>
										</label>
									</p>
									<p style={{marginRight:30}}>
										<label>
											<input type="checkbox" checked={data.despachoAduanal===''?false:!data.despachoAduanal} onChange={(e)=>setData({...data, despachoAduanal:!e.target.checked})} />
											<span className='black-text'>Not</span>
										</label>
									</p>
									{data.despachoAduanal===''&& <input style={{opacity:0, width:10}} required />}
									<p style={{display:'flex', alignItems:'center'}}>Customs clearance</p>
								</div>
								<div style={{display:'flex'}}>
									<p style={{marginRight:10}}>
										<label>
											<input type="checkbox" checked={data.entregaDestino} onChange={(e)=>setData({...data, entregaDestino:e.target.checked})} />
											<span className='black-text'>Yes</span>
										</label>
									</p>
									<p style={{marginRight:30}}>
										<label>
											<input type="checkbox" checked={data.entregaDestino===''?false:!data.entregaDestino} onChange={(e)=>setData({...data, entregaDestino:!e.target.checked})} />
											<span className='black-text'>Not</span>
										</label>
									</p>
									{data.entregaDestino===''&& <input style={{opacity:0, width:10}} required />}
									<p style={{display:'flex', alignItems:'center'}}>Destination delivery</p>
								</div>
								<div style={{display:'flex'}}>
									<p style={{marginRight:10}}>
										<label>
											<input type="checkbox" checked={data.asesoria} onChange={(e)=>setData({...data, asesoria:e.target.checked})} />
											<span className='black-text'>Yes</span>
										</label>
									</p>
									<p style={{marginRight:30}}>
										<label>
											<input type="checkbox" checked={data.asesoria===''?false:!data.asesoria} onChange={(e)=>setData({...data, asesoria:!e.target.checked})} />
											<span className='black-text'>Not</span>
										</label>
									</p>
									{data.asesoria===''&& <input style={{opacity:0, width:10}} required />}
									<p style={{display:'flex', alignItems:'center'}}>Advisory</p>
								</div>
							</div>
							{loader?
							<div className='right'>Loading ...</div>:
							<button className='btn waves-effect waves-light black right'>Send Quote</button>
							}
						</form>
					</div>
				</div>
			</div>
	)
	
}

export default ModalCotizar
