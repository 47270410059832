import React, { useEffect, useState } from 'react';
import { axios } from '../../infrastructure/axios'
import ImageNotFound from '../../../Utils/Assets/not-found.png'
import { Link } from 'react-router-dom'

const CatalogoGlobalPageIndex = () => {

	const [catalogo, setCatalogo] = useState([])

	useEffect(()=>{
		axios.get('/api/reseller/catalogo_global')
		.then((r)=>{
			setCatalogo(r.data)
		})
		.catch((e)=>alert(e))

	},[])

	const _dropShipingOnMyStore = (item_no, checked) => {
		if(!checked){
			// eslint-disable-next-line no-restricted-globals
			var respuesta = confirm("You will have to add your data again as well as your prices, if you want to import again");
			if (respuesta) {
				axios.post('/api/reseller/remove_on_my_store', {codigo: item_no})
				.then((r)=>{
					setCatalogo(catalogo.map(c=>c.codigo===item_no?{...c, on_my_store: 0}:c))
					alert("Dropshiping deleted!");
				})
			}
		}else{
			axios.post('/api/reseller/on_my_store', {codigo: item_no})
			.then((r)=>{
				setCatalogo(catalogo.map(c=>c.codigo===item_no?{...c, on_my_store: 1}:c))
			})
		}
	}

	return (
		<div className='card-panel'>
		<Link to='/admin/dropshiping'><i className="material-icons medium">arrow_back</i></Link>
			<h4>Catalogo Global</h4>
			<table>
				<thead>
					<tr>
						<th>No</th>
						<th>Item no</th>
						<th>Name</th>
						<th>Description</th>
						<th>Category</th>
						<th>Departament</th>
						<th>Supplier/Seller</th>
						<th>Picture</th>
						<th style={{background:'#EEEEEC'}}>On My Store</th>
					</tr>
				</thead>
				<tbody>
					{catalogo.map((c,i)=>
					<tr key={i}>
						<td>{i+1}</td>
						<td>{c.codigo}</td>
						<td style={{whiteSpace:'normal', wordWrap:'break-word'}}>{c.nombre}</td>
						<td style={{whiteSpace:'normal', wordWrap:'break-word'}}>
							{c.descripcion}
						</td>
						<td>{c.categoria}</td>
						<td style={{whiteSpace:'normal', wordWrap:'break-word'}}>{c.departamento}</td>
						<td style={{whiteSpace:'normal', wordWrap:'break-word'}}>{c.company_name}</td>
						<td><img alt='picture product' src={c.imagenes[0]?.url??ImageNotFound} style={{objectFit:'contain', height:100}}/></td>
						<td style={{background:'#EEEEEC'}}>
							<div className="switch">
								<label>
								Off
								<input type="checkbox" onChange={(e)=>_dropShipingOnMyStore(c.codigo, e.target.checked)} checked={c.on_my_store?true:false}/>
								<span className="lever"></span>
								On
								</label>
							</div>
						</td>
					</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default CatalogoGlobalPageIndex
