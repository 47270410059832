import Nav from '../../components/Nav'
import Login from './Components/Login'
import Footer2 from '../../components/Footer/Footer2'

const Index = () => {
	return (
		<>
			<Nav />
			<Login />
			<Footer2 />
		</>
	)
	
}

export default Index
