import React, { useEffect } from 'react'
import axios from '../../../../../../infrastructure/axios'
import { useNavigate } from 'react-router-dom'

const SantanderPay = ({pedido, setMetodoPago}) => {

	const navigate = useNavigate();

	useEffect(()=>{

		const interval = setInterval(()=>{
			axios.get(`/api/statuspedido/${pedido.id}`)
			.then((r)=>{
				if(r.data.status==='Pagado'){
					navigate('/micarrito/finaliza')
				}
			})
			.catch((r)=>alert(r))
			console.log('#ticket get status on Pago/metodos/santander.js is necesari?')
		}, 5000)

		return () => clearInterval(interval)
	},[])


	return(
		<>
			<a href='#!' onClick={()=>setMetodoPago('')}>volver</a>
			<h4>Agregar Tarjeta</h4>
			<iframe title='pay' frameBorder='0' src={pedido.pay.link} scrolling='no' seamless='seamless' style={{width:'100%', minHeight:'600px'}}/>
		</>
	)
}

export default SantanderPay
