import React, { useEffect, useState } from 'react';
import { axios } from '../../infrastructure/axios'

const MiBanca = () => {

	const [data, setData] = useState({banco:'', numero_cuenta:'', numero_tarjeta:'', clabe:''})

	useEffect(()=>{
		axios.get('/api/reseller/my_bank_data')
		.then((r)=>{
			setData(r.data)
		})
		.catch(e=>alert(e))

	},[])

	const _updateAccountBank = (e) => {
		e.preventDefault();
		axios.post('/api/reseller/update_my_bank_data', data)
		.then((r)=>{
			setData(r.data)
			alert('Update Data')
		})
		.catch(e=>alert(e))

	}

	return(
		<div className='card-panel'>
			<h4>My Account Bank</h4>
			<form onSubmit={_updateAccountBank} className="row">
				<div className="col s6">
					<div className="row">
						<div className="input-field col s12">
							<input value={data.banco} onChange={(e)=>setData({...data, banco:e.target.value})} id="bank" type="text" />
							<label htmlFor="bank">Bank</label>
						</div>
						<div className="input-field col s12">
							<input value={data.numero_cuenta} onChange={(e)=>setData({...data, numero_cuenta:e.target.value})} id="account_number" type="text" />
							<label htmlFor="account_number">Account Number</label>
						</div>
						<div className="input-field col s12">
							<input value={data.numero_tarjeta} onChange={(e)=>setData({...data, numero_tarjeta:e.target.value})} id="card_number" type="text" />
							<label htmlFor="card_number">Card Number</label>
						</div>
						<div className="input-field col s12">
							<input value={data.clabe} onChange={(e)=>setData({...data, clabe:e.target.value})} id="clabe" type="text" />
							<label htmlFor="clabe">CLABE</label>
						</div>
						<button className='btn waves-effect waves-light black right'>Update</button>
					</div>
				</div>
			</form>
		</div>
	)
	
}

export default MiBanca
