import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import M from 'materialize-css'
import './nav.css'
import { DataContext } from '../../contexts/DataContext'
import axios from '../../infrastructure/axios';

const Nav = () => {

	const { user, carrito, setUser, config, key, setKey } = useContext(DataContext)
	const navigate = useNavigate();

	const [menuIndustriaSelect, setMenuIndustriaSelect] = useState({departamentos:[]})
	const [industrias, setIndustrias] = useState([])
	const [conversations, setConversations] = useState([])
	const [categorias, setCategorias] = useState([])
	
	useEffect(()=>{
		//domain
		const domain = window.location.hostname;
		//css inject to dom
		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = axios.defaults.baseURL+'/dist.css?domain='+domain.replace('www.','');
		document.head.appendChild(link);


		M.Sidenav.init(document.querySelector('#slide-out'), {});
		M.FormSelect.init(document.querySelectorAll('select'), {});

		/**
		  * Menu display cats
		  */
		axios.get('/api/reseller/get_all_industry_departaments')
		.then(r=>{
			setIndustrias(r.data)

			/**
			  * extraer todas las categorias, para el subnav principal menu Products
			  */
			const todasLasCategorias = r.data.flatMap(dato => dato.departamentos.flatMap(departamento => departamento.categorias));
			setCategorias(todasLasCategorias)

		}).catch(e=>alert(e))

		axios.get('/api/user').then(r=>{
			setUser(r.data)

			/**
			  * Mensajes
			  */
			axios.get('/api/message/my_messages')
			.then((r)=>{
				setConversations(r.data.conversations)
			})
			.catch(r=>console.log(r))

		}).catch(r=>console.log(r))

		
	},[]);

	const _search = (e) =>{
		e.preventDefault()
		navigate(`/search/keyword?key=${key}`)
	}

	const _push = (e) => {
		e.preventDefault()
		setTimeout(() => {
			document.getElementById("searchI").focus()
		}, 0);
		navigate(`/search`)
	}

	const _pushMovil = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setKey(e.target.value)
		navigate(`/search`)
	}

	const _pull = (e) => {
		e.preventDefault()
		navigate(`/`)
	}
	
	const logout = () =>{
		axios.delete('/api/logout')
		.then(res => {
			setUser({})
			navigate('/')
		}).catch(e=>alert(e))
	}


	return(
		<>
		<div className='navbar-fixed'>
			<nav className='navbackground' style={{height:'auto'}}>
				<div className='nav-wrapper container-menu es-nav' style={{width:'100%!important'}}>
					<ul id='nav-mobile' className='left hide-on-med-and-down cont-ul'>
						<li className="logo">
							<Link to='/' className=''>{config.logo?
								<img className='logo-img'  alt='' style={{verticalAlign:'middle', objectFit:'fill', height:50}} /> : <p> &nbsp; </p>}
							</Link>
						</li>
						<li className="" style={{margin:'0 10px 0 10px', width:'230px'}}>
							<Link to='/deps' className='link-a center-x-y navtext' id='menu-industria' style={{textAlign:'center'}}>
							{/*deprecated*/}
							{/*
								<div className={location.pathname==="/deps"||location.pathname.includes('product')||location.pathname.includes('cat')||location.pathname.includes('search')?'item-name-active':''}><div className='nombre-seccion-principal'></div></div>
							*/}
								<div><div className='nombre-seccion-principal'></div></div>
							</Link>

							<div style={{position:'absolute'}} id='sub-menu-industria'>
								<div className='nombre-seccion-principal' style={{position:'absolute', background:'white', top:-35, width:140, height:35,color:'black', padding:0, lineHeight:'36px', textAlign:'center'}}></div>
								<div style={{background:'white', height:600, width:400, color:'black', padding:30, overflow:'auto'}}>
									{industrias.length===0&&
										<div>No Products Yet</div>
									}
									{industrias.map((i,ii)=>
									<div key={ii} onMouseOver={()=>setMenuIndustriaSelect(i)} style={{cursor:'pointer', position:'relative'}}>
										{i.industria}
										<div style={{position:'absolute', right:'10px', display:'inline-block'}}>
											<i className="material-icons">keyboard_arrow_right</i>
										</div>
									</div>
									)}
								</div>
								<div className='row' style={{background:'white', height:600, width:600, overflow:'auto'}}>
									{menuIndustriaSelect.departamentos.filter(d=>d.categorias.length>0).map((d,i)=>
										<div key={i} className='col s6'>
											{/*<Link to={`/deps/departamento?departamento_id=${d.id}`} style={{color:'#2192D9'}}>{d.departamento}</Link>*/}
											<div style={{color:'#2192D9'}}>{d.departamento}</div>
											{d.categorias.map((c,i)=>
												<Link key={i} to={`/cat?categoria_id=${c.id}&departamento_id=${d.id}`} style={{color:'grey',lineHeight:'20px'}}>{c.categoria}</Link>
											)}
										</div>
									)}
								</div>
							</div>
						</li>
						<li className="buscar">
							<form onSubmit={_search}>
								<div style={{display:'flex', alignItems:'center', height:35, padding:0, margin:0}}>
									<input placeholder='Search' className="border-input" id="searchI" type='search' value={key} onChange={(e)=>setKey(e.target.value)} onFocus={_push} onBlur={(e)=> { if(e.relatedTarget === null) _pull(e) }} style={{border:'none', margin:0, boxSizing:'none', borderRadius:'30px 0px 0px 30px', paddingLeft:10}} />
									<button className="buscar-a primarycolor" style={{border:0, cursor:'pointer'}}>
										<i className='material-icons white-text' style={{height:35, lineHeight:'35px'}}>search</i>
									</button>
								</div>
							</form>
						</li>
						<li className="buy">
							{user.name?
							<Link  className='link-a navtext' to='/pedidos'> 
								<div style={{display:'block'}}>
									{/*deprecated
										<div className={location.pathname==="/pedidos"?'item-name-active':''}>My Shopping</div>
									*/}
									{user.notifications.cotizaciones>0 &&
									<label style={{background:'red', borderRadius:5, padding:5, color:'white', fontWeight:'bold', position:'absolute', top:-5, right:-5, lineHeight:'normal', fontSize:9}}>{user.notifications.cotizaciones}</label>
									}
									<div>My Shopping</div>
								</div>
							</Link>:
							<div style={{marginLeft:10}}>
								<div id='menu-login'>
									<div style={{textAlign:'center'}}>
										<i className="material-icons navtext" style={{lineHeight:'20px', height:'auto'}}>person_outline</i>
									</div>
									<div className='navtext' style={{textAlign:'center'}}>
										<Link to='/login' className='navtext' style={{display:'inline-block', padding:0}}>Sign in</Link>/
										<Link to='/registro' className='navtext' style={{display:'inline-block', padding:0}}>Join</Link>
									</div>
								</div>
								<div style={{position:'absolute', right:0}} id='sub-menu-login'>
								<div style={{position:'absolute', right:10, top:-10}}>
									<svg width="20" height="10">
										<polygon points="0,10 8,0 16,10 " style={{fill:'white'}} />
									</svg>
								</div>
									<div style={{background:'white', height:150, width:300, color:'black', padding:10, boxShadow: '0px 0px 15px 1px rgba(0, 0, 0, 0.2)' }}>
										<Link to='/login' style={{background:'#e64545', textAlign:'center', padding:10, borderRadius:200, margin:10}}>Sign in</Link>
										<Link to='/registro' style={{color:'#e64545', textAlign:'center', padding:10, borderRadius:200, border:'1px #e64545 solid', margin:10}}>Join free</Link>
									</div>
								</div>
							</div>
							}
						</li>
						{user.name&&
						<li className="login">
							<Link className='link-a' to='/miperfil'>
								<i className='material-icons navtext' style={{lineHeight:'normal', height:'auto'}}>account_circle</i>
									<div style={{width:'100%', textAlign:'left'}}>
										<div className='navtext' style={{fontSize:'10px', fontWeight:'500', width:'100%', lineHeight:'1'}}>HELLO</div>
										<div className='navtext' style={{fontSize:'14px', width:'100%', lineHeight:'1'}}>
											{(user.name.indexOf(' ') >= 0) ? user.name.substr(0,user.name.indexOf(' ')) : user.name }
										</div>
									</div>
							</Link>
						</li>
						}
						<li>
							<Link to='/messages'>
								<div style={{textAlign:'center', position:'relative'}}>
									<i className="material-icons navtext" style={{lineHeight:'20px', height:'auto'}}>mail_outline</i>
									{conversations.flatMap(c=>c.messages).filter((m)=>!m.visto).reduce((a,b)=>a+=b.sender_name===user.name?0:1, 0)>0&&
									<label style={{background:'red', borderRadius:5, padding:5, color:'white', fontWeight:'bold', position:'absolute', top:0, right:0, lineHeight:'normal', fontSize:9}}>{conversations.flatMap(c=>c.messages).filter((m)=>!m.visto).reduce((a,b)=>a+=b.sender_name===user.name?0:1, 0)}</label>
									}
								</div>
								<div className='navtext' style={{textAlign:'center', lineHeight:'20px'}}>
									Messages
								</div>
							</Link>
						</li>
						<li>
							<Link to='/micarrito'>
								<div style={{textAlign:'center'}}>
									{carrito.length>0&&
									<span className="badge badge-car item-name-active-color articulos number-badge" style={{position:'absolute'}}>
										{(carrito.reduce((a,b) => a+parseInt(b.cantidad),0) > 9) ? "9+" : carrito.reduce((a,b) => a+parseInt(b.cantidad),0 )}
									</span>
									}
									<i className="material-icons navtext" style={{lineHeight:'20px', height:'auto'}}>shopping_cart</i>
								</div>
								<div className='navtext' style={{textAlign:'center', lineHeight:'20px', width:103}}>
								{/*deprecated
									<div className={location.pathname==="/micarrito"?'item-name-active':''} style={{display:'inline-block', padding:0, position:''}}>Inquiery Basket</div>
									*/}
									<div style={{display:'inline-block', padding:0, position:''}}>Inquiery Basket</div>
								</div>
							</Link>
						</li>
					</ul>
				</div>

				<div className='nav-wrapper container-menu es-movil'>
					<ul id='nav-mobile' className='left hide-on-med-and-down cont-ul'>
						<li className="log">
							<a href="#!" data-target="slide-out" className="sidenav-trigger" style={{display:'flex', alignItems:'center'}}>
								<i className="material-icons navtext">menu</i>
								<img className='logo-img' style={{verticalAlign:'middle', height:50}} alt=''/>
							</a>
						</li>

						<li className="buscar">
							<form onSubmit={_search}>
								<div style={{display:'flex', alignItems:'center', height:35, padding:0, margin:0}}>
									<input placeholder='Search' className="border-input" id="searchI" type='search' value={key} onChange={(e)=>setKey(e.target.value)} onFocus={_push} onBlur={(e)=> { if(e.relatedTarget === null) _pull(e) }} style={{border:'none', margin:0, boxSizing:'none', borderRadius:'30px 0px 0px 30px', paddingLeft:10}} />
									<button className="buscar-a primarycolor" style={{border:0, cursor:'pointer'}}>
										<i className='material-icons white-text' style={{height:35, lineHeight:'35px'}}>search</i>
									</button>
								</div>
							</form>
						</li>
					
						<li className="buy">
						{/*deprecated
							<Link href='/micarrito' className={`link-a ${location.pathname==="/micarrito"?'activar-carrito':''}`} >
								{carrito.length>0?
								<div style={{position:'relative'}}>
									<i className={`material-icons left car ${location.pathname==="/micarrito"?'activar-carrito':''}`} >shopping_cart</i>
									<span className="badge primarycolor white-text articulos center-y-x number-badge">
									{(carrito.reduce((a,b) => a+parseInt(b.cantidad),0) > 9) ? "9+" :
										carrito.reduce((a,b) => a+parseInt(b.cantidad),0 )
									}
									</span>
								</div>:
								<i className={`material-icons left car ${location.pathname==="/micarrito"?'activar-carrito':''}`} style={{marginTop: '12px',}}>shopping_cart</i>
								}
								<span className={location.pathname==="/micarrito"?'activar-carrito':''}></span>
							</Link>
							*/}
							<Link to='/micarrito' className={`link-a`} >
								{carrito.length>0?
								<div style={{position:'relative'}}>
									<i className={`material-icons left car`} >shopping_cart</i>
									<span className="badge primarycolor white-text articulos center-y-x number-badge">
									{(carrito.reduce((a,b) => a+parseInt(b.cantidad),0) > 9) ? "9+" :
										carrito.reduce((a,b) => a+parseInt(b.cantidad),0 )
									}
									</span>
								</div>:
								<i className={`material-icons left car`}>shopping_cart</i>
								}
								<span></span>
							</Link>
						</li>
					</ul>
				</div>
			</nav>
		</div>
		<nav id='proveedor-nav-home' style={{marginBottom:10}}>
				<ul>
					<li className='menu-item active'>
						<Link to={`/`}>Home</Link>
					</li>
					<li className='menu-item' id='menu' style={{position:'relative', cursor:'pointer'}}>
						Products
						<div id='sub-menu'>
							<ul className='list-cat-proveedor'>
							{categorias.map((c,i)=>
								<li key={i}><Link to={`/cat?categoria_id=${c.id}&departamento_id=${c.departamento_id}`}>{c.categoria}</Link></li>
							)}
							</ul>
						</div>
					</li>
					<li className='menu-item'>
						<Link to={`/aboutus`}>About us</Link>
					</li>
				</ul>
		</nav>


		<ul id="slide-out" className="sidenav">
			<li className="itemi center-y-x" style={{height:'150px', padding:'20px'}}>
				<div>
					<Link to='/' className='link-a black-text'> 
						<img className='logo-img' style={{verticalAlign:'middle', height:'80px', width:'80px'}} alt=''/>
					</Link>
				</div>
			</li>
			<li className="item">
				<div className="buscar">
					<form onSubmit={_pushMovil} id="nav-buscar">
						<label className="label-search">
							<input name="key2" id="searchinput2" className="border-input" type='search' value={key} onChange={(event)=> setKey(event.target.value)} />
							<Link to={`/search/keyword?key=${key}`} className="buscar-a btn-buscar-movil primarycolor">
								<i className='material-icons primarycolor white-text'>search</i>
							</Link>
						</label>
					</form>
				</div>
			</li>
			{(user.name)?	
			<li className="item">
				<Link className='link-a black-text' to='/miperfil'>
					<i className='material-icons' style={{color:'#000000',}}>account_circle</i>
					<div style={{width:'100%', textAlign:'left',paddingTop:'10px'}}>
						<div style={{color:'#000000', fontSize:'10px', fontWeight:'500', width:'100%',marginBottom:'2px', lineHeight:'1'}}>HOLA</div>
						<div style={{color:'#000000', fontSize:'14px', width:'100%', lineHeight:'1'}}>{user.name}</div>
					</div>
				</Link> 
			</li>:
			<>
			<li className="item">
				<Link to='/registro' className='link-a black-text'>
					<i className="material-icons">person</i>
					REGISTRATE
				</Link>
			</li>
			<li className="item">
				<Link to='/login' className='link-a black-text'>
					<i className="material-icons">center_focus_strong</i>
					IDENTIFICATE
				</Link>
			</li>
			</>
			}
			<li className="item">
				<Link to='/deps' className='link-a black-text'><i className="material-icons">grid_on</i><span className='nombre-seccion-principal'></span></Link>
			</li>
			<li className="item">
				<Link to='/micarrito' className='link-a black-text'>
					<i className='material-icons left car'>shopping_cart</i> CARRITO
					<span className="badge primarycolor white-text"
					style={{borderRadius:'100%',minHeight:'20px',minWidth:'20px', fontSize:'10px'}}>{(carrito)?carrito.length:0}</span>
				</Link>
			</li>
			<li className="item">
				<Link to={(user.name) ? '/pedidos':'/login'} className='link-a black-text'> <i className="material-icons">attach_money</i> MIS COMPRAS </Link>
			</li>
			{user.name &&
			<li className="item">
				<a onClick={logout} href='#!' className="col s12 semi-bold" style={{padding:'0 30px'}} >
					<i className='material-icons left car'>exit_to_app</i>
					CERRAR SESIÓN
				</a> 
			</li>
			}
		</ul>
		</>
	)
}

export default Nav
