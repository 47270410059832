import React, { useState, useEffect } from 'react';
import axios from '../../infrastructure/axios';
import M from 'materialize-css'

const ModalAdress = ({setAgregarDireccion, _newDireccion,_editDireccion, direccion, setDirSelected}) => {

    const [data, setData] = useState({nombre:'',calle:'',numExt:'',numInt:'',cp:'', instrucciones_entrega:'', telefono:'', estado:'', ciudad:'', colonia:''})
    const [cpInfo, setCPInfo] = useState({})
	const [cpLoader, setCPLoader] = useState(false)

    useEffect(() => {
		M.Modal.init(document.querySelectorAll('.modal'), { 
            inDuration: 250,
            outDuration: 250,
            opacity: 0.5,
            dismissible: true,
            startingTop: "10%",
            endingTop: "10%"});
	},[])
    

    useEffect(() => {
        if(direccion) setData(direccion)
        else setData({nombre:'',calle:'',numExt:'',numInt:'',cp:'', instrucciones_entrega:'', telefono:'', estado:'', ciudad:'', colonia:''})
        

	},[direccion])

    useEffect(()=>{
        M.FormSelect.init(document.querySelectorAll('select'), {});
    },[cpLoader])

    /**
	  * @param {String} cp - codigo postal
	  */
	const _cpValidator = (cp) => {
		setData({...data, cp})

		if(/^\d{5}$/.test(cp)){
			setCPLoader(true)
			axios.get(`/api/cp/${cp}`)
			.then(r=>{
				setCPInfo(r.data)
				setData({...data, cp, estado:r.data.estado, ciudad:r.data.ciudad})
				setCPLoader(false)
			})
			.catch(r=>alert(r))
		}
	}

    /**
	  * @param {Object} e - event
	  */
	const _pushData = (e) => {
		e.preventDefault()
        if(direccion.id){
            _editDireccion(data)
        }else{
		_newDireccion(data)
        }
		//setAgregarDireccion(false)
		//window.scrollTo(0, 0)
	}

    return (
        <div className="col s12">
            <button onClick={()=>setDirSelected(false)} data-target="modal" className="btn primarycolor waves-effect waves-light modal-trigger btn-modal right">Agregar dirección</button>
            <div id="modal" className="modal">
                <div className="modal-content padding-20">
                    <h5>
                        Dirección
                        <button className="btn-small modal-close right primarycolor"><i className="material-icons">close</i></button>
                    </h5>
                 
                  
                     <div className="divider"></div>
                    <form onSubmit={_pushData}>
                        <div className='input-field'>
                            Nombre Completo
                            <input value={data.nombre} onChange={(e)=>setData({...data, nombre:e.target.value})} style={{height:37, border:'1px solid grey'}} type='text' required/>
                        </div>
                        <div className='input-field'>
                            Dirección
                            <input value={data.calle} onChange={(e)=>setData({...data, calle:e.target.value})} style={{height:37, border:'1px solid grey'}} type='text'required/>
                        </div>
                        <div className='row' style={{marginBottom:0}}>
                            <div className='col s6 m6'>
                                <div className='input-field'>
                                    Número exterior
                                    <input value={data.numExt} onChange={(e)=>setData({...data, numExt:e.target.value})} style={{height:37, border:'1px solid grey'}} type='text' required/>
                                </div>
                            </div>
                            <div className='col s6 m6'>
                                <div className='input-field'>
                                    Número interior
                                    <input value={data.numInt||''} onChange={(e)=>setData({...data, numInt:e.target.value})} style={{height:37, border:'1px solid grey'}} type='text' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col m6'>
                                <div className='input-field'>
                                    Código postal
                                    <input value={data.cp} onChange={(e)=>_cpValidator(e.target.value)} style={{height:37, border:'1px solid grey'}}  type='number' className='validate' required/>
                                </div>
                            </div>
                            <div className='col m6' style={{paddingTop:35}}>
                            </div>
                        </div>
                        {cpLoader&&
                            <div className='progress'>
                                <div className='indeterminate primarycolor'></div>
                            </div>
                        }
                        {(cpInfo.estado&&!cpLoader&&data.cp.length===5)&&
                            <div>
                                <div>
                                    <span className='bold'>Estado / Provincia: </span>{cpInfo.estado}
                                </div>
                                <div>
                                    <span className='bold'>Ciudad: </span>{cpInfo.ciudad}
                                </div>
                                <div className='input-field'>
                               
                                    <select defaultValue='' onChange={(e)=>setData({...data, colonia:e.target.value})}>
                                        <option value='' disabled>Selecciona tu colonia</option>
                                        {cpInfo.asentamientos.map((c,i)=>
                                            <option key={i} value={c.asentamiento}>{c.asentamiento}</option>
                                        )}
                                    </select>
                                    <label className='black-text  margin-top-10' style={{fontSize:14}}> <span className="bold">Colonia: </span> {data.colonia}</label>
                                </div>
                            </div>
                        }
                        <div className='input-field'>
                            Instrucciones de entrega
                            <textarea value={data.instrucciones_entrega||''} onChange={(e)=>setData({...data, instrucciones_entrega:e.target.value})} style={{border:'1px solid grey', height:86}} className='materialize-textarea' />
                        </div>
                        <div className='input-field'>
                            Teléfono
                            <input value={data.telefono} onChange={(e)=>setData({...data, telefono:e.target.value})} style={{height:37, border:'1px solid grey'}} type='text' required/>
                        </div>
                        <div className="modal-footer">
                            <button  className="btn primarycolor waves-effect waves-light modal-close right">Guardar</button>
                        </div>
                    </form>
                </div>
             
            </div>
        </div>
    );
};

export default ModalAdress;
