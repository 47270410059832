import Nav from '../../components/Nav'
import Footer from '../../components/Footer'
import Carrito from './Components/Carrito'

const Index = () => {

	return(
		<>
			<Nav />
			<Carrito />
			<Footer />
		</>
	)
	
}

export default Index
