import Footer from '../../../components/Footer'
import Nav from '../../../components/Nav'
import Finalizar from './Components/Finalizar'
const Index = () => {

	return(
		<>
			<Nav />
			<Finalizar />
			<Footer />
		</>
	)
	
}

export default Index
