import React, { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import Progressnav from './progressNav/ProgressNav';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';
import StepFour from './steps/StepFour';
import StepFive from './steps/StepFive';
import StepSix from './steps/StepSix';

const Wizard = ({configs}) => {

	const { user }=useContext(UserContext);
   
	const _renderSwitch = ()=>{
		//console.log("mocos..")configs.config_store.wizard_config.status
		switch(configs.config_store.wizard_config.status){
			case 'step_one':
				return <StepOne />;
			case 'step_two':
				return <StepTwo />
			case 'step_three':
				return <StepThree />
			case 'step_four':
				return <StepFour />
			case 'step_five':
				return <StepFive />
			case 'step_six':
				return <StepSix />
			default:
				break;
		}
	}

	return (
		<div className='s12 hero'>
			<div className='container-medium'>
				<div className='row container-ab'
				style={{display:'flex', flexwrap:'wrap',  margin:0, padding:0, width:'100%',}}>
					
					<div className='col s3' id='container-a' style={{background:'#FFFFFF', display:'flex', alignItems:'center', flexwrap:'wrap', justifyContent:'center', margin:0, padding:'15px',}}>
						<div style={{display:'flex', alignItems:'center',}}> 
							<div style={{textAlign:'center'}}> 
								<b style={{fontWeight:700,fontSize:'16px'}}>BIENVENIDO</b> <br></br>
								<i className="material-icons">account_circle</i><br></br>
								<b style={{fontWeight:900,fontSize:'24px', color:'#000000'}}> {user.name.toUpperCase()} </b>
							</div>
						</div>
					</div>
					<div className='col s9' id='container-b' style={{background:'#F8F8F8', display:'flex', alignItems:'center', flexwrap:'wrap', justifyContent:'center', margin:0, padding:'15px',}}>
						<b style={{fontWeight:900,fontSize:'18px', color:'#000000'}}>
							Todo puede ser modificado más adelante. <br></br> Esto sólo es la configuración inicial de tu tienda.
						</b>
					</div>
				</div>
				<div className='row container-ab'>
					<div className='col s3' id='container-a'  style={{background:'#FFFFFF',minHeight:600}}>
						<Progressnav  estatus = {configs.config_store.wizard_config.status}/>
					</div>
					<div className='col s9' id='container-b' style={{background:'#F8F8F8',minHeight:600}}>
						{configs.config_store.wizard_config.status?
						_renderSwitch():
						<div className="progress">
							<div className="indeterminate"></div>
						</div>}
					</div>
				</div> 
			</div>
		</div>
	   
	);
};

export default Wizard;
