import React, {useState, useContext } from 'react';
import './login.css';
import { Link, useNavigate } from 'react-router-dom'
import axios from '../../../../infrastructure/axios';
import { DataContext } from '../../../../contexts/DataContext';
import userFig from './userFig.png'

const Login = () => {

	const [passwordShown, setPasswordShown] = useState(false);
	const {setUser} = useContext(DataContext)
	const [data, setData] = useState({email:'',password:''})
	const navigate = useNavigate();

	const login = (e) => {
		e.preventDefault()
		axios.post('/api/login',{...data, domain: window.location.hostname})
		.then(r=>{
			setUser(r.data.user)
			localStorage.setItem('access_token', 'Bearer '+r.data.access_token);
			axios.defaults.headers.common['Authorization'] = 'Bearer '+r.data.access_token
			navigate('/')
		})
		.catch((r)=>alert('Las credenciales no coinciden con nuestras bases de datos intentelo más tarde'))
	}

	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true);
	};

	return (
	<>
		<div className='row center-y-x ptb-80'>
			<div className='col s12 m9 m-p-0 center-y-x'>
				<div className='card-panel'>
					<div className='center-y-x'>
						<div className='cont-user'>
							<img src={userFig} width='80' height='80' alt=''/>
						</div>
					</div>
					<div className='s12 center-y-x'>
						<p>
							Crea una cuenta y disfruta de los Beneficios de <label className='store-name' style={{fontSize:14, color:'black'}}></label>
						</p>
					</div>
					<div className='col s12 pt-10'>
						<form onSubmit={login}>
							<div className='input-fieldi col s12 p-0'>
								<label className='black-text' htmlFor='email'>Email*</label>
								<input onChange={(e)=>setData({...data, email: e.target.value})} id='email' type='email' style={{border:'1px solid #D6D6D6', borderRadius:5}} />
							</div>
							<div className='input-fieldi col s12 p-0'>
								<label className='black-text' htmlFor='password'>Contraseña*</label>
								
								<span style={{display:'flex', position:'relative', alignItems: 'center'}}>
								<input onChange={(e)=>setData({...data, password: e.target.value})} id='password' type={passwordShown ? "text" : "password"} style={{border:'1px solid #D6D6D6', borderRadius:5}} />
								<i className="material-icons style-ojoi" style={{position:'absolute', right:10, cursor:'pointer'}} onClick={togglePasswordVisiblity}>remove_red_eye</i>
								</span>
							</div>
							<button className='btn primarycolor white-text waves-effect waves-light col s12' >Entrar</button>
						</form>
					</div>
					<div className='col s12 pt-40'>
						<div className='font-black col s12 p-0'>
							¿No tienes una cuenta? <Link className='font-red infocolor-text' to='/registro'>Registrate aquí</Link>
							<br/>
							<Link className='font-red infocolor-text' to='/recuperar'>Olvidé mi Contraseña</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>	 
	  
	);
};

export default Login;
