import React, { useState, useEffect, useContext } from 'react';
import axios from '../../../../../infrastructure/axios'
import M from 'materialize-css'
import { DataContext } from '../../../../../contexts/DataContext'

const NewDirection = ({setAgregarDireccion, _newDireccion}) => {

	const [data, setData] = useState({nombre:'',correo:'',calle:'',numExt:'',numInt:'',cp:'', instrucciones_entrega:'', telefono:'', estado:'', ciudad:'', colonia:''})
	const [cpInfo, setCPInfo] = useState({})
	const [cpLoader, setCPLoader] = useState(false)
	const { user } = useContext(DataContext)

	useEffect(() => {
		M.FormSelect.init(document.querySelectorAll('select'), {});

	},[cpLoader])

	/**
	  * @param {String} cp - codigo postal
	  */
	const _cpValidator = (cp) => {
		setData({...data, cp})

		if(/^\d{5}$/.test(cp)){
			setCPLoader(true)
			axios.get(`/api/cp/${cp}`)
			.then(r=>{
				setCPInfo(r.data)
				setData({...data, cp, estado:r.data.estado, ciudad:r.data.ciudad})
				setCPLoader(false)
			})
			.catch(r=>alert(r))
		}
	}

	/**
	  * @param {Object} e - event
	  */
	const _pushData = (e) => {
		
		e.preventDefault()

		if(data.colonia===''){
			alert('seleccione una colonia')
			return 0
		}
		if(data.telefono.length === 10)
		{
			_newDireccion(data)
			setAgregarDireccion(false)
		}
		else{alert('telefono incorrecto. Ej.3345709318')}
		window.scrollTo(0, 0)
		
	}

	return(
		<div className='card-panel' style={{border: '2px solid #FBB19A',boxShadow: '2px 2px 12px 3px rgba(255, 228, 219, 0.97)'}}>
			<form onSubmit={_pushData}>
			{/*
				<div className='input-field'>
					País
					<input style={{height:37, border:'1px solid grey'}} id='first_name' type='text' className='validate' />
				</div>
			*/}
					<div className='input-field'>
						Full Name
						<input className="card-panel-input" value={data.nombre} onChange={(e)=>setData({...data, nombre:e.target.value.replace(/[^A-z ]/g, '')})}  type='text' required/>
					</div>
					{!user.name&&
					<div className='input-field'>
						Email
						<input className="card-panel-input" value={data.correo} onChange={(e)=>setData({...data, correo:e.target.value})}  type='text' required/>
					</div>
					}
					<div className='input-field'>
						Street
						<input className="card-panel-input" value={data.calle} onChange={(e)=>setData({...data, calle:e.target.value.length<30?e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, ""):data.calle})}  type='text'required/>
					</div>
					<div className='row' style={{marginBottom:0}}>
						<div className='col m6'>
							<div className='input-field'>
								Street Number
								<input className="card-panel-input" value={data.numExt} onChange={(e)=>setData({...data, numExt:e.target.value})}  type='text' required/>
							</div>
						</div>
						<div className='col m6'>
							<div className='input-field'>
								Apartment Number(optional)
								<input className="card-panel-input" value={data.numInt} onChange={(e)=>setData({...data, numInt:e.target.value})}  type='text' />
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col m6'>
							<div className='input-field'>
								Postal Code
								<input  value={data.cp} onChange={(e)=>_cpValidator(e.target.value)}  id='first_name' type='number' className='validate card-panel-input' required/>
							</div>
						</div>
						<div className='col m6' style={{paddingTop:35}}> </div>
					</div>
					{cpLoader&&
					<div className='progress'>
						<div className='indeterminate primarycolor'></div>
					</div>
					}

				{(cpInfo.estado&&!cpLoader&&data.cp.length===5)&&
				<div>
					<div style={{}}>
						<span className='bold'>State/Province: </span>{cpInfo.estado}
					</div>
					<div style={{height:'36px'}}>
						<span className='bold'>City: </span>{cpInfo.ciudad}
					</div>
					<div className='input-field' style={{margin:0, padding:0,}} >
						<select name='colonia' defaultValue='' onChange={(e)=>setData({...data, colonia:e.target.value})}>
							<option value='' disabled style={{}}>Select your district</option>
							{cpInfo.asentamientos.map((c,i)=>
								<option key={i} value={c.asentamiento}>{c.asentamiento}</option>
							)}
						</select>
						<label className='black-text bold input-field' style={{fontSize:14,padding:'10px0', lineHeight:'1.2',}}>District:</label>
					</div>
				</div>
				}
				<div className='input-field'>
					Delivery instructions
					<textarea value={data.instrucciones_entrega} onChange={(e)=>setData({...data, instrucciones_entrega:e.target.value})} style={{border:'1px solid #D6D6D6', height:86}} className='materialize-textarea' />
				</div>
				<div className='input-field'>
					Phone Number
					<input className="card-panel-input" value={data.telefono} onChange={(e)=>setData({...data, telefono:e.target.value.replace(/[^0-9]/g, '') })} type="text" maxLength="10" pattern="[0-9]+" required/>
				</div>
				<div className='row'>
					<div className='col s12 m6 m-p-0'>
						<a href='#!' onClick={()=>setAgregarDireccion(false)} className='btn black btn-movil'>Cancel</a>
					</div>
					<div className='col s12 m6 m-p-0'>
						<button className='btn primarycolor btn-movil' style={{marginLeft:'auto', display:'block'}}>Save</button>
					</div>
				</div>
			</form>
		</div>
	)
}

export default NewDirection
