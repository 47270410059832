import React, { useEffect, useState } from 'react';
import { axios } from '../../../infrastructure/axios'
import M from 'materialize-css'

const Step5ExportInfo = ({percent_calc}) => {

	const [data, setData] = useState({export_percentage:'',export_regions:[], nearest_port:'', export_license_number:'', export_company_name:'', export_license_number_picture:''})
	const export_regions = ['North America', 'Southeast Asia', 'Mid East', 'Central America', 'South Asia', 'South America', 'Africa', 'Eastern Asia', 'Northern Europe', 'Domestic Market', 'Eastern Europe', 'Oceania', 'Western Europe', 'Southern Europe'].sort()

	useEffect(()=>{
		axios.get('/api/seller/get_seller')
		.then(r=>{
			setData(r.data)
			percent_calc(r.data.states)
			M.FormSelect.init(document.querySelectorAll('select'), {});
		})
	},[])

	const _send = (e) => {
		e.preventDefault()
		console.log(data)
		/*
		   * get images
		   */
		let export_license_number_picture = document.getElementById('export_license_number_picture')?.files[0]

		const formData = new FormData();
		formData.append('data', JSON.stringify(data))
		formData.append('export_license_number_picture', export_license_number_picture)

		axios.post('/api/seller/seller_export_info', formData)
		.then(r=>{
			setData(r.data)
			percent_calc(r.data.states)
			M.toast({html:'Updated!'})
			/**
			  * Next tab
			  */
			const tabs = document.querySelector(".tabs")
			const instance = M.Tabs.getInstance(tabs)
			instance.select('step6')
			window.scrollTo(0, 0)
		})
	}

	const _update_export_regions = (e, value) =>{
		const aux = [...data.export_regions]
		if(e.target.checked){
			setData({...data, export_regions:aux.includes(value)?aux:[...aux, value].sort()})
		}else{
			aux.splice(aux.indexOf(value), 1)
			const newData = {...data, export_regions:aux}
			setData(newData)
		}
	}
	return(
		<form onSubmit={_send}>
			<div className="row">
				<div className="input-field col s12">
					<select value={data.export_percentage||''} onChange={(e)=>setData({...data, export_percentage:e.target.value})}>
						<option value="" disabled>Choose your option</option>
						{['1% - 10%', '11% - 20%', '21% - 30%', '31% - 40%', '41% - 50%', '51% - 60%', '61% - 70%', '71% - 80%', '81% - 90%', '91% - 100%'].map((v,i) => (
						<option key={i} value={v}>{v}</option>
						))}
					</select>
					<label>Export Percentage</label>
				</div>
				{export_regions.map((business, i)=>
				<div className="input-field col">
					<p key={i}>
						<label>
							<input type="checkbox" checked={data.export_regions.includes(business)} onChange={(e)=>_update_export_regions(e, business)}/>
							<span>{business}</span>
						</label>
					</p>
				</div>
				)}
			</div>

			<div className="row">
				<div className="input-field col s12">
					<input value={data.nearest_port||''} onChange={(e)=>setData({...data, nearest_port:e.target.value})} id="nearest_port" type="text" className="validate" />
					<label htmlFor="nearest_port">Nearest Port</label>
				</div>
			</div>

			<div className="row">
				<div className="input-field col s6">
					<input value={data.export_license_number||''} onChange={(e)=>setData({...data, export_license_number:e.target.value})} id="export_license_number" type="text" className="validate" />
					<label htmlFor="export_license_number">Export License Number</label>
				</div>
				{(data.export_license_number_picture === null|| data.export_license_number_picture ==='')&&
				<div className="file-field input-field col s6">
					<div className="btn">
						<span>Export License</span>
						<input type="file" id='export_license_number_picture'/>
					</div>
					<div className="file-path-wrapper">
						<input className="file-path validate" type="text" />
					</div>
				</div>
				}
				{(data.export_license_number_picture && data.export_license_number_picture !=='')&&
				<div className="input-field col s6" style={{margin:'0px 0px 0px 0px'}}>
					<a href={data.export_license_number_picture} style={{marginTop:20, position:'absolute'}} target='_blank' rel="noreferrer">{data.export_license_number_picture}</a>
					<i className="material-icons" style={{position:'absolute', right:0, cursor:'pointer'}} onClick={()=>setData({...data,export_license_number_picture:''})}>delete</i>
				</div>
				}
			</div>

			<div className="row">
				<div className="input-field col s12">
					<input value={data.export_company_name||''} onChange={(e)=>setData({...data, export_company_name:e.target.value})} id="export_company_name" type="text" className="validate" />
					<label htmlFor="export_company_name">Export Company Name</label>
				</div>
			</div>
			<button className='btn waves-effect waves-light right black'>Save</button>
		</form>
	)
	
}

export default Step5ExportInfo
