import React, { useState, useEffect } from 'react';
import { axios } from '../../../infrastructure/axios'
import M from 'materialize-css'

const Step2ProductDetails = ({percent_calc}) => {

	const [data, setData] = useState({product_details_main_industrie:'', product_details_main_products:['','','','','',''], product_details_other_products:['','','','','','']})
	const [industrias, setIndustrias] = useState([])

	useEffect(()=>{
		axios.get('/api/seller/seller_product_details')
		.then(r=>{
			setData(r.data)
		})

		axios.get('/api/seller/get_industry')
		.then(r=>{
			setIndustrias(r.data)
			M.FormSelect.init(document.querySelectorAll('select'), {});
		})
	},[])

	const _send = (e) => {
		e.preventDefault()
		axios.post('/api/seller/seller_product_details', data)
		.then(r=>{
			setData(r.data)
			percent_calc(r.data.states)
			M.toast({html:'Updated!'})
			/**
			  * Next tab
			  */
			const tabs = document.querySelector(".tabs")
			const instance = M.Tabs.getInstance(tabs)
			instance.select('step3')
			window.scrollTo(0, 0)
		})
	}

	return (
		<form onSubmit={_send}>
			<div className="row">
				<div className="input-field col s12">
					<select value={data.product_details_main_industrie||''} onChange={(e)=>setData({...data, product_details_main_industrie:e.target.value})}>
						<option value="" disabled>Choose your option</option>
						{industrias.map((i,j)=>
						<option key={j} value={i.industria}>{i.industria}</option>
						)}
					</select>
					<label>Main Industrie</label>
				</div>
			</div>
			<div className='row'>
				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Main Products(6 principal products)</div>
				</div>
				<div className="input-field col s6">
					<input value={data.product_details_main_products[0]||''} onChange={(e)=>setData({...data, product_details_main_products:data.product_details_main_products.map((v, i)=>i===0?e.target.value:v)})} id="principal_product1" type="text" className="validate" />
					<label htmlFor="principal_product1">Product 1</label>
				</div>
				<div className="input-field col s6">
					<input value={data.product_details_main_products[1]||''} onChange={(e)=>setData({...data, product_details_main_products:data.product_details_main_products.map((v, i)=>i===1?e.target.value:v)})} id="principal_product2" type="text" className="validate" />
					<label htmlFor="principal_product2">Product 2</label>
				</div>
				<div className="input-field col s6">
					<input value={data.product_details_main_products[2]||''} onChange={(e)=>setData({...data, product_details_main_products:data.product_details_main_products.map((v, i)=>i===2?e.target.value:v)})} id="principal_product3" type="text" className="validate" />
					<label htmlFor="principal_product3">Product 3</label>
				</div>
				<div className="input-field col s6">
					<input value={data.product_details_main_products[3]||''} onChange={(e)=>setData({...data, product_details_main_products:data.product_details_main_products.map((v, i)=>i===3?e.target.value:v)})} id="principal_product4" type="text" className="validate" />
					<label htmlFor="principal_product4">Product 4</label>
				</div>
				<div className="input-field col s6">
					<input value={data.product_details_main_products[4]||''} onChange={(e)=>setData({...data, product_details_main_products:data.product_details_main_products.map((v, i)=>i===4?e.target.value:v)})} id="principal_product5" type="text" className="validate" />
					<label htmlFor="principal_product5">Product 5</label>
				</div>
				<div className="input-field col s6">
					<input value={data.product_details_main_products[5]||''} onChange={(e)=>setData({...data, product_details_main_products:data.product_details_main_products.map((v, i)=>i===5?e.target.value:v)})} id="principal_product6" type="text" className="validate" />
					<label htmlFor="principal_product6">Product 6</label>
				</div>
			</div>
			<div className='row'>
				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Other Products You Sell</div>
				</div>
				<div className="input-field col s6">
					<input value={data.product_details_other_products[0]||''} onChange={(e)=>setData({...data, product_details_other_products:data.product_details_other_products.map((v, i)=>i===0?e.target.value:v)})} id="principal_product1" type="text" className="validate" />
					<label htmlFor="other_product1">Product 1</label>
				</div>
				<div className="input-field col s6">
					<input value={data.product_details_other_products[1]||''} onChange={(e)=>setData({...data, product_details_other_products:data.product_details_other_products.map((v, i)=>i===1?e.target.value:v)})} id="principal_product1" type="text" className="validate" />
					<label htmlFor="other_product2">Product 2</label>
				</div>
				<div className="input-field col s6">
					<input value={data.product_details_other_products[2]||''} onChange={(e)=>setData({...data, product_details_other_products:data.product_details_other_products.map((v, i)=>i===2?e.target.value:v)})} id="principal_product1" type="text" className="validate" />
					<label htmlFor="other_product3">Product 3</label>
				</div>
				<div className="input-field col s6">
					<input value={data.product_details_other_products[3]||''} onChange={(e)=>setData({...data, product_details_other_products:data.product_details_other_products.map((v, i)=>i===3?e.target.value:v)})} id="principal_product1" type="text" className="validate" />
					<label htmlFor="other_product4">Product 4</label>
				</div>
				<div className="input-field col s6">
					<input value={data.product_details_other_products[4]||''} onChange={(e)=>setData({...data, product_details_other_products:data.product_details_other_products.map((v, i)=>i===4?e.target.value:v)})} id="principal_product1" type="text" className="validate" />
					<label htmlFor="other_product5">Product 5</label>
				</div>
				<div className="input-field col s6">
					<input value={data.product_details_other_products[5]||''} onChange={(e)=>setData({...data, product_details_other_products:data.product_details_other_products.map((v, i)=>i===5?e.target.value:v)})} id="principal_product1" type="text" className="validate" />
					<label htmlFor="other_product6">Product 6</label>
				</div>
			</div>
			<button className='btn waves-effect waves-light right black'>Save</button>
		</form>
	)
	
}

export default Step2ProductDetails
